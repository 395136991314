export interface SystemState {
  loggedIn: boolean;
  session: string;
  userName: string;
}

export enum ACTION {
  UPDATE_SESSION = "UPDATE_SESSION",
  GLOBAL_CHANGE_URL = "GLOBAL_CHANGE_URL",
  
}

interface UpdateSessionAction {
  type: ACTION.UPDATE_SESSION;
  payload: SystemState;
}

interface UpdateUrlAction {
  type:  ACTION.GLOBAL_CHANGE_URL;
  payload: string;
}



export type SystemActionTypes = UpdateSessionAction | UpdateUrlAction;
