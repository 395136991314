import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

import {
  ICalculateResponse,
  IClientDetails,
  IEuroPostOffice,
  IOrderContentEditor,
  IOrderEditor,
} from "../../api";
import { AppDispatch, RootState } from "../../store";
import { OrderForm } from "./components/orderForm";
import { Loading } from "../../components/common/loading/Loading";
import {
  deleteOrderContent,
  updateOrderContent,
  fetchEditableOrder,
  fetchOrderCalculate,
  createOrder,
  updateOrder,
  clearOrderEditor,
  getEuroOffices,
} from "../../store/order/actions";

import { SessionStorageService } from "../../services/session-storage";
import { FORM_ORDER_EDITOR_KEY } from "../../constants/storage";
import { Routes } from "../../router/helper";

interface PageQueryParams {
  orderId?: string;
}

interface PageQuery extends RouteComponentProps<PageQueryParams> {}

interface IPageProps {
  isLoading: boolean;
  isSending: boolean;
  orderEditor: IOrderEditor & ICalculateResponse;
  orderEditorContent: IOrderContentEditor[];
  client: IClientDetails | null;
  sumDiscount: number;
  sumDelivery: number;
  sumTotal: number;
  redirect: string | null;
  euroPostOffices: IEuroPostOffice[];

  fetchEditableOrder: (orderId: number) => void;
  fetchOrderCalculate: (
    content: IOrderContentEditor[],
    typeDelivery: string
  ) => void;
  updateOrderContent: (content: IOrderContentEditor) => void;
  deleteOrderContent: (content: IOrderContentEditor) => void;
  createOrder: (order: IOrderEditor) => void;
  updateOrder: (order: IOrderEditor) => void;
  clearOrderEditor: () => void;
  getEuroOffices: () => void;
}

const OrderEditorPage: React.FC<IPageProps & PageQuery> = (props) => {
  const orderId = props.match.params.orderId || null;
  const {
    isLoading,
    isSending,
    orderEditor,
    client,
    redirect,
    euroPostOffices,
    fetchEditableOrder,
    fetchOrderCalculate,
    updateOrderContent,
    deleteOrderContent,
    createOrder,
    updateOrder,
    clearOrderEditor,
    getEuroOffices,
  } = props;

  let history = useHistory();

  const cacheData = (data: IOrderEditor | null, url?: string) => {
    SessionStorageService.setItem(FORM_ORDER_EDITOR_KEY, data);
    if (url) {
      history.push(url);
    }
  };

  if (redirect) {
    if (redirect === "back") {
      history.goBack();
    } else {
      history.push(Routes.orders);
    }

    cacheData(null);
  }

  useEffect(() => {
    getEuroOffices();
  }, []);

  useEffect(() => {
    if (orderId) {
      if (!(orderEditor && orderEditor.orderId === +orderId)) {
        fetchEditableOrder(+orderId);
      }
    }
  }, [orderId, fetchEditableOrder]);

  useEffect(() => {
    if (orderEditor.content.length) {
      fetchOrderCalculate(orderEditor.content, orderEditor.typeDelivery);
    }
  }, [orderEditor.content]);

  const getCachedData = (): IOrderEditor =>
    SessionStorageService.getItem(FORM_ORDER_EDITOR_KEY) as IOrderEditor;

  if (isLoading) return <Loading />;

  return (
    <OrderForm
      orderEditor={orderEditor}
      client={client}
      isSending={isSending}
      euroPostOffices={euroPostOffices}
      updateOrderContent={updateOrderContent}
      deleteOrderContent={deleteOrderContent}
      cacheData={cacheData}
      getCachedData={getCachedData}
      createOrder={createOrder}
      updateOrder={updateOrder}
      clearOrderEditor={clearOrderEditor}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.order,
  ...state.phone,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      fetchEditableOrder,
      fetchOrderCalculate,
      updateOrderContent,
      deleteOrderContent,
      createOrder,
      updateOrder,
      clearOrderEditor,
      getEuroOffices,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderEditorPage);
