import {
  IProductCategories,
  IProductListRequest,
  IProductPage,
} from "./product.types";
import { APIService } from "../../services/api-service";

export const getProducts = (request?: IProductListRequest) => {
  const url = "api/v1/product/search";
  return APIService.get<IProductListRequest, IProductPage>(url, request);
};

export const getCategories = () => {
  const url = `api/v1/product/categories/`;
  return APIService.get<IProductCategories>(url);
};
