import {
  CountStatusType,
  IBlockedStatus,
  ICalculateResponse,
  IOrderEditor,
} from "../../api";
import { DeliveryType } from "../../constants";
import { ACTION, IOrderActionTypes, IOrdersState } from "./types";

const emptyOrderEditor: IOrderEditor & ICalculateResponse = {
  phone: "",
  firstName: "",
  lastName: "",
  patronymic: "",
  country: "",
  zipCode: "",
  area: "",
  region: "",
  city: "",
  street: "",
  house: "",
  building: "",
  apartment: "",
  typeDelivery: DeliveryType.post,
  dateDelivery: "",
  content: [],
  sumDiscount: 0,
  sumDelivery: 0,
  sumTotal: 0,
  comment: "",
  euroPostId: null,
};

const initialState: IOrdersState = {
  isLoading: false,
  isSending: false,
  error: "",
  orders: [],
  countStatus: [],
  blockedStatus: { countAlert: 0, countWarning: 0 },
  pagination: null,
  orderEditor: emptyOrderEditor,
  redirect: null,
  lastEditedOrderId: null,
  isLoadingCallsToday: false,
  callsToday: [],
  euroPostOffices: [],
};

export const reducer = (
  state = initialState,
  action: IOrderActionTypes
): IOrdersState => {
  switch (action.type) {
    case ACTION.ORDERS_FETCH:
      return {
        ...state,
        isLoading: true,
        redirect: null,
      };
    case ACTION.ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: action.payload.data,
        countStatus: action.payload.countStatus as CountStatusType[],
        blockedStatus: action.payload.countBlocked as IBlockedStatus,
        pagination: action.payload.pagination,
      };
    case ACTION.ORDERS_BLOCKED_SUCCESS:
      return {
        ...state,
        blockedStatus: action.payload,
      };
    case ACTION.ORDER_EDITOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderEditor: action.payload,
        lastEditedOrderId: action.payload.orderId as number,
      };

    case ACTION.ORDER_CLEAR_EDITOR:
      return {
        ...state,
        orderEditor: emptyOrderEditor,
      };
    case ACTION.ORDER_ADD_CONTENT:
      return {
        ...state,
        orderEditor: {
          ...state.orderEditor,
          content: [
            ...state.orderEditor.content,
            {
              ...action.payload,
              contentId: `#${state.orderEditor.content.length}`,
            },
          ],
        },
      };
    case ACTION.ORDER_UPDATE_CONTENT:
      return {
        ...state,
        orderEditor: {
          ...state.orderEditor,
          content: [
            ...state.orderEditor.content.map((content) =>
              content.contentId === action.payload.contentId
                ? action.payload
                : content
            ),
          ],
        },
      };
    case ACTION.ORDER_DELETE_CONTENT:
      return {
        ...state,
        orderEditor: {
          ...state.orderEditor,
          content: [
            ...state.orderEditor.content.filter((content) =>
              action.payload.productSetUnique
                ? content.contentId !== action.payload.contentId &&
                  content.productSetUnique !== action.payload.productSetUnique
                : content.contentId !== action.payload.contentId
            ),
          ],
        },
      };

    case ACTION.ORDER_ADD_PACKET:
      const Unique = Math.round(Math.random() * 1000);
      return {
        ...state,
        orderEditor: {
          ...state.orderEditor,
          content: [
            ...state.orderEditor.content,
            ...action.payload.content.map((productSet, poz) => ({
              contentId: `#${Unique}-${poz}`,
              article: productSet.article,
              productName: productSet.productName,
              productId: productSet.productId,
              count: productSet.count,
              //  price: packet.isMain ? action.payload.price : 0,
              price: poz === 0 ? action.payload.price : 0,
              productSetId: action.payload.productSetId,
              productSetUnique: Unique,
            })),
          ],
        },
      };

    case ACTION.ORDER_CALCULATE_SUCCESS:
      return {
        ...state,
        orderEditor: {
          ...state.orderEditor,
          ...action.payload,
        },
      };

    case ACTION.ORDER_CREATE_SUCCESS:
      return {
        ...state,
        isSending: false,
        lastEditedOrderId: action.payload,
        redirect: "/",
      };

    case ACTION.ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        isSending: false,
        lastEditedOrderId: action.payload,
        redirect: "back",
      };
    case ACTION.ORDER_SEND:
      return {
        ...state,
        isSending: true,
      };
    case ACTION.ORDER_SEND_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order.orderId === action.payload.orderId ? action.payload : order
        ),
        isSending: false,
      };
    case ACTION.ORDER_SEND_ERROR:
      return {
        ...state,
        isSending: false,
      };
    case ACTION.ORDER_CALLS_FETCH:
      return {
        ...state,
        isLoadingCallsToday: true,
      };
    case ACTION.ORDER_CALLS_SUCCESS:
      return {
        ...state,
        callsToday: action.payload,
        isLoadingCallsToday: false,
      };
    case ACTION.ORDER_CALLS_ERROR:
      return {
        ...state,
        isLoadingCallsToday: false,
      };

    case ACTION.ORDERS_EURO_OFFICES_SUCCESS:
      return {
        ...state,
        euroPostOffices: action.payload,
      };

    default:
      return state;
  }
};
