import React from "react";
import "./stickyModal.scss";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: any;
  width?: string;
}

export const StickyModal = ({
  width = "750px",
  isOpen,
  children,
  onClose,
}: ModalProps) => {
  // if (!isOpen) return <></>;
  return (
    <div style={{ visibility: isOpen ? "visible" : "hidden" }}>
      <div className="sticky-modal__fg" style={{ width }}>
        {/* <div className="sticky-modal__close" onClick={onClose}>
          X
        </div> */}
        {children}
      </div>
      <div className="sticky-modal__bg" onClick={onClose} />
    </div>
  );
};
