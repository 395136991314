import React from "react";
import { IClientProduct } from "../../api";

import { differenceInDays } from "date-fns";
import { OrderStatuses } from "../../constants";
import {
  RiArrowGoBackFill,
  RiCheckboxCircleLine,
  RiDraftLine,
  RiMailSendLine,
} from "react-icons/ri";

interface Props {
  clientProducts: IClientProduct[];
}

const showIcon = (status: OrderStatuses) => {
  if (status === OrderStatuses.DRAFT_0) return <RiDraftLine />;
  if (status < OrderStatuses.SENDED_7) return "";
  if (status < OrderStatuses.COMPLETED_10) return <RiMailSendLine />;
  if (status === OrderStatuses.COMPLETED_10)
    return <RiCheckboxCircleLine className="text-success" />;
  if (status === OrderStatuses.RETURN_14)
    return <RiArrowGoBackFill className="text-danger" />;
  return "";
};
export const ClientProductList: React.FC<Props> = ({ clientProducts }) => {
  const dateNow = new Date();

  return (
    <div className="scrolled">
      <table className="table table-sm">
        <thead>
          <tr>
            <th scope="col text-center"></th>
            <th scope="col">Товар</th>
            <th scope="col text-center">Дней назад</th>
          </tr>
        </thead>
        <tbody>
          {clientProducts.map((product, i) => (
            <tr key={i}>
              <td className="text-center w-200">{showIcon(product.status)}</td>
              <td>{product.productName}</td>
              <td className="text-center">
                {product.dateConfirm &&
                  differenceInDays(dateNow, new Date(product.dateConfirm))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
