import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import {
  FaTimes,
  FaRegCalendarAlt,
  FaShippingFast,
  FaCommentMedical,
  FaCheck,
} from "react-icons/fa";
import { IoIosSquare } from "react-icons/io";
import { BsGeoAlt, BsLightningFill } from "react-icons/bs";
import { RiNotificationBadgeFill, RiUserFill } from "react-icons/ri";
import { FaRegCommentAlt } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { BiArrowFromBottom } from "react-icons/bi";
import { IoHeadsetOutline } from "react-icons/io5";
import { VscHistory } from "react-icons/vsc";

import "../Orders.scss";

import { IOrder } from "../../../api";
import { generateOrderEditPath } from "../../../router/helper";
import {
  OrderStatuses,
  OrderStatusesRu,
} from "../../../constants/order-statuses";
import { DeliveryType } from "../../../constants";
import { ImWarning } from "react-icons/im";
import { OrderOperations } from "../OrdersTypes";

interface Props {
  order: IOrder;
  lastEditedOrderId: number | null;
  currentUserId?: number;
  onOpenClient: (orderId: number) => void;
  onOpenOrder: (order: IOrder) => void;
  onEditComment: (order: IOrder) => void;
  onOpenDraftOff: (order: IOrder) => void;
  onOpenConfirm: (order: IOrder) => void;
  onUnBlock: (order: IOrder) => void;
  handleOpenModal: (order: IOrder, operation: OrderOperations) => void;
}

const isOrderCanBeEdited = (status: OrderStatuses): boolean =>
  [
    OrderStatuses.DRAFT_0,
    OrderStatuses.WAIT_DATE_RESERVE_1,
    OrderStatuses.WAIT_RESERVE_2,
    OrderStatuses.WAIT_CONFIRM_3,
    OrderStatuses.ON_CHECK_5,
  ].includes(status);

const getOrderStyle = (status: OrderStatuses, lastEdited: boolean) => {
  let orderStyle = "card-order";
  switch (status) {
    case OrderStatuses.DRAFT_0:
      orderStyle += " order-draft";
      break;
    case OrderStatuses.WAIT_DATE_RESERVE_1:
      orderStyle += " order-wait-date-reserve";
      break;
    case OrderStatuses.WAIT_RESERVE_2:
      orderStyle += " order-wait-reserve";
      break;
    case OrderStatuses.WAIT_CONFIRM_3:
      orderStyle += " order-wait";
      break;
    case OrderStatuses.SENDED_7:
      orderStyle += " order-send";
      break;
    case OrderStatuses.CANCEL_11:
      orderStyle += " order-cancel";
      break;
    case OrderStatuses.COMPLETED_10:
      orderStyle += " order-completed";
      break;
    case OrderStatuses.RETURN_14:
      orderStyle += " order-return";
      break;
    case OrderStatuses.AT_POST_8:
      orderStyle += " order-post";
      break;
  }
  if (lastEdited) {
    orderStyle += " last-edited";
  }
  return orderStyle;
};

const showDelivery = (delivery: DeliveryType) => {
  switch (delivery) {
    case DeliveryType.post:
      return "БЕЛПОЧТА";
    case DeliveryType.c_rb:
      return "Курьер РБ";
    case DeliveryType.c_mi:
      return "Курьер Минск";
    case DeliveryType.self:
      return "Самовывоз";
    case DeliveryType.serv:
      return "";
    case DeliveryType.eu_s:
      return "Европочта - ОПС";
    case DeliveryType.eu_d:
      return "Европочта - До двери";

    default:
      return delivery;
  }
};

export const Order: React.FunctionComponent<Props> = ({
  order,
  lastEditedOrderId,
  currentUserId,
  onOpenClient,
  onOpenOrder,

  onEditComment,
  //onCancel,

  onOpenDraftOff,
  onOpenConfirm,
  onUnBlock,
  handleOpenModal,
}) => {
  return (
    <>
      <div
        className={getOrderStyle(
          order.state,
          order.orderId === lastEditedOrderId
        )}
      >
        {currentUserId !== order.managerId && (
          <div className="order__user">
            <button className="btn btn-link">{order.userName}</button>
          </div>
        )}
        <div className="row">
          <div className="col col-md-12 col-lg-12 col-xl-7">
            <div className="d-flex justify-content-between">
              <div className="col text-muted">
                <span className="order-status">
                  {OrderStatusesRu[order.state]}
                </span>
              </div>
              <div className="col-md-auto text-muted mr-1 ">
                {` №${order.orderId} от ${format(
                  new Date(order.dateCreate),
                  "dd MMMM yyyy",
                  {
                    locale: ru,
                  }
                )}`}
              </div>
            </div>
            <div className="mt-3">
              <table className="table table-sm">
                <tbody>
                  {order.content.map((content) => (
                    <tr key={content.contentId}>
                      <td>
                        <IoIosSquare
                          className={content.reserved ? "booked" : "un-booked"}
                        />
                      </td>
                      <td scope="row">
                        {content.productName}
                        <span className="text-muted">
                          (<i>арт. {content.article}</i>)
                        </span>
                      </td>

                      <td className="text-right">{content.price}руб.</td>
                      <td className="text-center">{content.count}шт.</td>
                      {isOrderCanBeEdited(order.state) && (
                        <td>
                          <div
                            className={
                              content.countAvailable < content.count
                                ? "text-center order-content-unavailable"
                                : "text-center"
                            }
                          >
                            {content.countAvailable === 20
                              ? `>${content.countAvailable}`
                              : content.countAvailable}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="row">
                <div className="col text-muted mr-1">
                  <button
                    className="btn btn-link "
                    //onClick={() => onOpenHistory(order)}
                    onClick={() =>
                      handleOpenModal(order, OrderOperations.HISTORY)
                    }
                  >
                    <VscHistory /> История
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={() =>
                      handleOpenModal(order, OrderOperations.LISTEN)
                    }
                  >
                    <IoHeadsetOutline /> Поиск звонков
                  </button>
                </div>

                <div className="col-md-auto mt-1 ml-3">
                  Итого: {order.sumTotal} руб.
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md-12 col-lg-12 col-xl-5 order-params">
            <div className="d-flex justify-content-between">
              <div className="col">
                <FaShippingFast /> {showDelivery(order.typeDelivery)}{" "}
                {order.belpostBarcode && [
                  <>
                    (
                    <a
                      href={`https://belpost.by/Otsleditotpravleniye?number=${order.belpostBarcode}`}
                      target="_blank"
                    >
                      {order.belpostBarcode}
                    </a>
                    )
                  </>,
                ]}
              </div>

              <div className="col-md-auto mr-3 ">
                <FaRegCalendarAlt />
                {` к ${format(new Date(order.dateDelivery), "dd MMMM yyyy", {
                  locale: ru,
                })}`}
              </div>
            </div>

            <div className="mt-2">
              <RiUserFill />
              <a
                href="#"
                className="ml-1"
                onClick={(e) => {
                  e.preventDefault();
                  onOpenClient(order.orderId);
                }}
              >
                {order.clientName || "нет имени"}
              </a>
            </div>
            <div className="mt-2">
              <BsGeoAlt />
              {order.clientAddress}
            </div>
            <div className="mt-2">
              {order.comment ? (
                <div className=" pt-1 pb-1" role="alert">
                  <FaRegCommentAlt className="mr-2" />
                  {order.comment}
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      onEditComment(order);
                    }}
                  >
                    изменить
                  </button>
                </div>
              ) : (
                <>
                  <FaCommentMedical />
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      onEditComment(order);
                    }}
                  >
                    добавить комментарий
                  </button>
                </>
              )}
            </div>

            <div className="mt-3">
              {isOrderCanBeEdited(order.state) && (
                <>
                  <Link
                    to={generateOrderEditPath(order.orderId)}
                    className="btn btn-light mt-1 mr-2"
                  >
                    <GrEdit />
                  </Link>
                  {order.state !== OrderStatuses.CANCEL_11 && (
                    <button
                      type="button"
                      className="btn btn-light mt-1 mr-1"
                      onClick={() =>
                        handleOpenModal(order, OrderOperations.CANCEL)
                      }
                    >
                      <FaTimes />
                    </button>
                  )}
                  {order.state !== OrderStatuses.DRAFT_0 ? (
                    <button
                      type="button"
                      className="btn btn-light mt-1 mr-1"
                      onClick={() =>
                        handleOpenModal(order, OrderOperations.DRAFT)
                      }
                    >
                      <RiNotificationBadgeFill />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-light mt-1 mr-1"
                      onClick={() => onOpenDraftOff(order)}
                    >
                      <BiArrowFromBottom /> Вывести из черновика
                    </button>
                  )}
                  {order.state === OrderStatuses.WAIT_CONFIRM_3 && (
                    <button
                      type="button"
                      className="btn btn-success mt-1 mr-1"
                      onClick={() => onOpenConfirm(order)}
                    >
                      <FaCheck /> Подтвердить отправку
                    </button>
                  )}
                  {/* <button
                      type="button"
                      className="btn btn-light order-action1"
                      onClick={() =>
                        setOrderStatus(order.orderId, OrderStatuses.DRAFT)
                      }
                    >
                      book/U
                    </button>
                    <button
                      type="button"
                      className="btn btn-light order-action1"
                      onClick={() =>
                        setOrderStatus(order.orderId, OrderStatuses.DRAFT)
                      }
                    >
                      Unblock
                    </button> */}
                </>
              )}
            </div>
          </div>
        </div>
        {order.blocked && (
          <div className="mt-3 mb-0 alert alert-danger " role="alert">
            <div className="d-flex justify-content-between m-2">
              <div>
                {order.blocked.time > 0 ? (
                  <BsLightningFill className="pr-2 icon-l" />
                ) : (
                  <ImWarning className="pr-2 icon-l" />
                )}
                {order.blocked.message}
              </div>

              <button
                className="btn btn-danger"
                onClick={() => onUnBlock(order)}
              >
                {order.blocked.time > 0 ? "Разблокировать" : "Понятно"}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
