export class SessionStorageService {
    public static setItem<T>(key: string, value: T) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public static getItem<T>(key: string): T | null {
        const sessionStorageValue = sessionStorage.getItem(key);
        if (sessionStorageValue) {
            return JSON.parse(sessionStorageValue);
        }
        return null;
    }

    public static updateItem<T>(
        key: string,
        updatedField: string | number,
        value: T
    ) {
        const sessionStorageValue = SessionStorageService.getItem<T>(key);
        sessionStorage.setItem(
            key,
            JSON.stringify({ ...sessionStorageValue, [updatedField]: value })
        );
    }
}
