import { IBonus, IInvoice } from "./statistics.types";
import { APIService } from "../../services/api-service";

export const getInvoice = () => {
  const url = `api/v1/statistics/invoice/`;
  return APIService.get<IInvoice[]>(url);
};

export const getBonus = () => {
  const url = `api/v1/statistics/bonus/`;
  return APIService.get<IBonus>(url);
};
