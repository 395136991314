import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IMailing,
  ISmsClientBase,
  ISmsHistory,
  ISmsIncomePage,
  ISmsLine,
  ISmsOutcomePage,
  ISmsStatistics,
} from "../../api/";
import { ISmsState } from "./types";

const initialState: ISmsState = {
  isLoading: false,
  isCalculating: false,
  isPending: false,
  selectedMailingId: null,
  clientBases: [],
  mailingList: [],
  totalCalculate: 0,
  lines: [],
  income: {
    sms: [],
    pagination: null,
    isLoading: false,
  },
  outcome: {
    sms: [],
    pagination: null,
    isLoading: false,
  },
  history: {
    events: [],
    isLoading: false,
  },
  sendSms: {
    isPending: false,
  },
  searchPhone: {
    isLoading: false,
    isRemoving: false,
    mailing: [],
  },
};

export const smsSlice = createSlice({
  name: "sms",
  initialState,
  reducers: {
    loading: (state: ISmsState) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    // setSelectedMailing: (state: ISmsState, action: PayloadAction<number>) => {
    //   return {
    //     ...state,
    //     selectedMailingIndex: action.payload,
    //   };
    // },
    calculating: (state: ISmsState) => {
      return {
        ...state,
        totalCalculate: 0,
        isCalculating: true,
      };
    },
    successClientBases: (
      state: ISmsState,
      action: PayloadAction<ISmsClientBase[]>
    ) => {
      return {
        ...state,
        clientBases: action.payload,
      };
    },
    successLines: (state: ISmsState, action: PayloadAction<ISmsLine[]>) => {
      return {
        ...state,
        lines: action.payload || [],
      };
    },
    successMailing: (state: ISmsState, action: PayloadAction<IMailing[]>) => {
      return {
        ...state,
        isLoading: false,
        mailingList: action.payload,
      };
    },
    successCalculating: (state: ISmsState, action: PayloadAction<number>) => {
      return {
        ...state,
        isCalculating: false,
        totalCalculate: action.payload,
      };
    },
    successCreating: (state: ISmsState, action: PayloadAction<number>) => {
      return {
        ...state,
        isPending: false,
        totalCalculate: 0,
        selectedMailingId: action.payload,
      };
    },
    error: (state: ISmsState) => {
      return {
        ...state,
        isLoading: false,
        isCalculating: false,
        isPending: false,
      };
    },
    setRemovedMailing: (state: ISmsState, action: PayloadAction<number>) => {
      return {
        ...state,
        mailingList: state.mailingList.filter(
          (item) => item.id !== action.payload
        ),
        selectedMailingId: null,
        isPending: false,
      };
    },

    successUpdate: (state: ISmsState, action: PayloadAction<IMailing>) => {
      return {
        ...state,
        mailingList: state.mailingList.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        selectedMailingId: action.payload.id,
        isPending: false,
      };
    },

    setOperationStatus: (state: ISmsState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isPending: action.payload,
      };
    },
    setStarted: (state: ISmsState, action: PayloadAction<number>) => {
      return {
        ...state,
        mailingList: state.mailingList.map((item) =>
          item.id === action.payload ? { ...item, status: 1 } : item
        ),
        selectedMailingId: action.payload,
        isPending: false,
      };
    },
    setStopped: (state: ISmsState, action: PayloadAction<number>) => {
      return {
        ...state,
        mailingList: state.mailingList.map((item) =>
          item.id === action.payload ? { ...item, status: 0 } : item
        ),
        selectedMailingId: action.payload,
        isPending: false,
      };
    },
    setReset: (state: ISmsState, action: PayloadAction<number>) => {
      return {
        ...state,
        mailingList: state.mailingList.map((item) =>
          item.id === action.payload ? { ...item, sent: 0, status: 0 } : item
        ),
        selectedMailingId: action.payload,
        isPending: false,
      };
    },

    setStatistics: (
      state: ISmsState,
      action: PayloadAction<ISmsStatistics[]>
    ) => {
      return {
        ...state,
        mailingList: state.mailingList.map((item) => {
          const update = action.payload.find((st) => st.id === item.id);
          return update
            ? {
                ...item,
                sent: update.count,
                status: update.status,
                isActive: update.isActive,
              }
            : item;
        }),
      };
    },

    // INCOME ------------

    incomeLoading: (state: ISmsState) => {
      return {
        ...state,
        income: {
          ...state.income,
          isLoading: true,
          isError: false,
        },
      };
    },
    incomeSuccess: (
      state: ISmsState,
      action: PayloadAction<ISmsIncomePage>
    ) => {
      return {
        ...state,
        income: {
          ...state.income,
          isLoading: false,
          sms: action.payload.data,
          pagination: action.payload.pagination,
        },
      };
    },
    incomeError: (state: ISmsState) => {
      return {
        ...state,
        income: {
          sms: [],
          pagination: null,
          isLoading: false,
          isError: true,
        },
      };
    },

    // OUTCOME ------------

    outcomeLoading: (state: ISmsState) => {
      return {
        ...state,
        outcome: {
          ...state.outcome,
          isLoading: true,
        },
      };
    },
    outcomeSuccess: (
      state: ISmsState,
      action: PayloadAction<ISmsOutcomePage>
    ) => {
      return {
        ...state,
        outcome: {
          ...state.outcome,
          isLoading: false,
          sms: action.payload.data,
          pagination: action.payload.pagination,
        },
      };
    },
    outcomeError: (state: ISmsState) => {
      return {
        ...state,
        outcome: {
          sms: [],
          pagination: null,
          isLoading: false,
        },
      };
    },

    // HISTORY ------------

    historyLoading: (state: ISmsState) => {
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: true,
          isError: false,
        },
      };
    },
    historySuccess: (
      state: ISmsState,
      action: PayloadAction<ISmsHistory[]>
    ) => {
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: false,
          events: action.payload,
        },
      };
    },
    historyError: (state: ISmsState) => {
      return {
        ...state,
        history: {
          events: [],
          isLoading: false,
          isError: true,
        },
      };
    },
    /// sms
    pendingSms: (state: ISmsState) => {
      return {
        ...state,
        sendSms: {
          isPending: true,
        },
      };
    },
    successSms: (state: ISmsState) => {
      return {
        ...state,
        sendSms: {
          isPending: false,
        },
      };
    },
    errorSms: (state: ISmsState) => {
      return {
        ...state,
        sendSms: {
          isPending: false,
        },
      };
    },

    searchInMailingLoading: (state: ISmsState) => {
      return {
        ...state,
        searchPhone: {
          isLoading: true,
          isRemoving: false,
          mailing: [],
        },
      };
    },

    searchInMailingSuccess: (
      state: ISmsState,
      action: PayloadAction<string[]>
    ) => {
      return {
        ...state,
        searchPhone: {
          isLoading: false,
          isRemoving: false,
          mailing: action.payload,
        },
      };
    },
    searchRemoving: (state: ISmsState) => {
      return {
        ...state,
        searchPhone: {
          isLoading: false,
          isRemoving: true,
          mailing: [],
        },
      };
    },

    searchClearState: (state: ISmsState) => {
      return {
        ...state,
        searchPhone: {
          isLoading: false,
          isRemoving: false,
          mailing: [],
        },
      };
    },
  },
});

export const { actions, reducer } = smsSlice;
//export default smsSlice.reducer;
