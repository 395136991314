import React from "react";
import { RouteComponentProps } from "react-router";
import { AuthAPI } from "../api";
import { Login } from "../components/login/login";

interface PageProps extends RouteComponentProps {}

interface PageState {
  email: string;
  password: string;
  errorMessage: string;
  isSending: boolean;
}

export class LoginPage extends React.Component<PageProps, PageState> {
  public constructor(props: PageProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      isSending: false,
    };
  }

  public handleChangeEmail = (email: string) => {
    this.setState({ ...this.state, email });
  };

  public handleChangePassword = (password: string) => {
    this.setState({ ...this.state, password });
  };

  public handleLogin = async () => {
    try {
      const { email, password } = this.state;
      this.setState({ isSending: true });
      const result = await AuthAPI.login({ email, password });
      if (result.success) {
        const token = result.message;
        localStorage.setItem("JWT", token);
        window.location.assign("/");
      } else {
        this.setState({
          isSending: false,
          errorMessage: result.message,
          password: "",
        });
      }
    } catch (error) {
      this.setState({
        isSending: false,
        errorMessage: "Ошибка авторизации",
        password: "",
      });
    }
  };

  componentWillMount() {
    // if(nextProps.auth.isAuthenticated) {
    //this.props.history.push("/");
  }

  public render() {
    const { email, password, errorMessage, isSending } = this.state;
    return (
      <>
        <Login
          email={email}
          password={password}
          errorMessage={errorMessage}
          isSending={isSending}
          onChangeEmail={this.handleChangeEmail}
          onChangePassword={this.handleChangePassword}
          onLogin={this.handleLogin}
        />
      </>
    );
  }
}

// https://bezkoder.com/react-jwt-auth/

/// https://appdividend.com/2018/07/18/react-redux-node-mongodb-jwt-authentication/
