import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { CdrType, IOrder } from "../../../api";
import { ImBlocked } from "react-icons/im";
import { BiCheckCircle } from "react-icons/bi";
import { FiPhoneIncoming, FiPhoneOutgoing } from "react-icons/fi";

interface Props {
  order: IOrder;
  isLoadingCallsToday: boolean;
  callsToday: CdrType[];
  isSending: boolean;
  getCallsToday: (orderId: number) => void;
  onConfirm: () => void;
}

const TIME_LIMIT = 15;

const getCallStatus = (calls: CdrType[]) => {
  if (calls.length === 0) return false;
  const hasCall = calls.find((call) => call.billsec >= TIME_LIMIT);
  return !!hasCall;
};

export const ModalConfirm: React.FunctionComponent<Props> = ({
  order,
  isLoadingCallsToday,
  callsToday,
  isSending,
  getCallsToday,
  onConfirm,
}) => {
  const [hasCall, setHasCall] = useState(false);

  useEffect(() => {
    getCallsToday(order.orderId);
  }, []);

  useEffect(() => {
    setHasCall(getCallStatus(callsToday));
  }, [callsToday]);

  return (
    <div className="">
      <h2>Подтверждение заказа</h2>
      <div>
        <div className="d-flex justify-content-between mb-2">
          <div className="col">
            <h5>Наличие звонка за сегодня (не менее {TIME_LIMIT} секунд)</h5>
          </div>
          <div className="col col-md-auto f-size-l">
            {!isLoadingCallsToday &&
              (hasCall ? (
                <BiCheckCircle className="text-success" />
              ) : (
                <ImBlocked className="text-danger" />
              ))}
          </div>
        </div>
        <div>
          {isLoadingCallsToday ? (
            "Поиск..."
          ) : (
            <table className="table table-sm">
              {callsToday.map((call) => (
                <tr>
                  <td>
                    {call.direction ? (
                      <div className="call-outgoing">
                        <FiPhoneOutgoing />
                        исходящий
                      </div>
                    ) : (
                      <div className="call-incoming">
                        <FiPhoneIncoming /> входящий
                      </div>
                    )}
                  </td>
                  <td>{format(new Date(call.start_stamp), "HH:mm")}</td>
                  <td>{call.billsec} сек.</td>
                </tr>
              ))}
            </table>
          )}
        </div>
      </div>
      <button
        className="btn btn-primary mt-3"
        onClick={onConfirm}
        disabled={!hasCall || isSending}
      >
        {isSending ? "Ожидайте..." : "Подтвердить отправку"}
      </button>
    </div>
  );
};
