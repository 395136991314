import React, { useEffect } from "react";
import "./modal.scss";

interface ModalProps {
  isModalOpen: boolean;
  className?: string;
  onClose: () => void;
  children: any;
}

export const Modal = ({
  isModalOpen,
  className,
  children,
  onClose,
}: ModalProps) => {
  // useEffect(() => {
  //   if (isModalOpen) {
  //     document.body.classList.add("overflow");
  //   } else {
  //     document.body.classList.remove("overflow");
  //   }
  // }, [isModalOpen]);
  if (!isModalOpen) return <></>;
  return (
    <div>
      <div className={`modal_fg ${className}`}>
        <div className="close" onClick={onClose}>
          X
        </div>
        {children}
      </div>
      <div className="modal__bg" onClick={onClose} />
    </div>
  );
};
