import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { IEvent } from "../../api";
import { AppDispatch, RootState } from "../../store";
import { fetchEvent, toggleEventsVisibility } from "../../store/events/actions";

import { StickyModal } from "../../components";
import { EventsList } from "../../components/common/eventList/eventList";

interface IPageProps {
  events: IEvent[];
  isLoading: boolean;
  isVisible: boolean;
  fetchEvent: () => void;
  toggleEventsVisibility: () => void;
}

const EventsHistory: React.FC<IPageProps> = ({
  isLoading,
  isVisible,
  events,
  fetchEvent,
  toggleEventsVisibility,
}) => {
  useEffect(() => {
    if (isVisible) fetchEvent();
  }, [isVisible]);

  return (
    <StickyModal
      isOpen={isVisible}
      onClose={toggleEventsVisibility}
      width={"75%"}
    >
      <EventsList isLoading={isLoading} events={events} />
    </StickyModal>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.events,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators({ fetchEvent, toggleEventsVisibility }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventsHistory);
