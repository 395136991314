import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppDispatch, RootState } from "../../store";
import {
  fetchScript,
  toggleScriptVisibility,
} from "../../store/script/actions";

import { StickyModal } from "../../components";

import { ScriptHistory } from "./components/ScriptHistory";
import { IScriptAnswer, IScriptStep, IScriptStepContent } from "../../api";

interface IPageProps {
  isLoading: boolean;
  isVisible: boolean;
  steps: IScriptStep[];
  content: IScriptStepContent[];
  answers: IScriptAnswer[];
  fetchScript: () => void;
  toggleScriptVisibility: () => void;
}

const Script: React.FC<IPageProps> = ({
  isLoading,
  isVisible,
  steps,
  content,
  answers,
  fetchScript,

  toggleScriptVisibility,
}) => {
  useEffect(() => {
    fetchScript();
  }, [fetchScript]);

  return (
    <StickyModal
      isOpen={isVisible}
      onClose={toggleScriptVisibility}
      width={"75%"}
    >
      {isLoading ? (
        "Загрузка"
      ) : (
        <ScriptHistory
          allSteps={steps}
          allContent={content}
          allAnswers={answers}
        />
      )}
    </StickyModal>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.script,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators({ toggleScriptVisibility, fetchScript }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Script);
