import React, { useState, useEffect } from "react";
import { VscInfo } from "react-icons/vsc";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Routes } from "../router/helper";
import { RootState } from "../store";

interface IPageProps {
  client: number;
  orderAlert: number;
  orderWarning: number;
  information: number;
  income: number;
}

const TimerNotification: React.FC<IPageProps> = ({
  client,
  orderAlert,
  orderWarning,
  information,
  income,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState(3000);

  const checkAlert = () => {
    if (client + orderAlert + orderWarning + information + income > 0) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      const timer = setTimeout(checkAlert, time);
      return () => clearTimeout(timer);
    }
  }, [isOpen, client, orderAlert, orderWarning, information, income]);

  const handleCloseModal = () => {
    setIsOpen(false);
    // 5 min
    setTime(300000);
  };

  if (!isOpen) return null;

  return (
    <div className="footer-notification">
      <div className="d-flex align-items-center">
        <div className="footer-notification__logo">
          <VscInfo />
        </div>
        <div className="d-flex">
          {orderAlert > 0 && (
            <Link to={Routes.orders} onClick={handleCloseModal}>
              Заказы, требующие звонка ({orderAlert})
            </Link>
          )}
          {orderWarning > 0 && (
            <Link to={Routes.orders} onClick={handleCloseModal}>
              Заказы, требующие действие ({orderWarning})
            </Link>
          )}
          {information > 0 && (
            <Link to={Routes.information} onClick={handleCloseModal}>
              Новая информация для ознакомления ({information})
            </Link>
          )}
          {client > 0 && (
            <Link to={Routes.clients} onClick={handleCloseModal}>
              Клиенты, ожидающие звонок ({client})
            </Link>
          )}
          {income > 0 && (
            <Link to={Routes.income} onClick={handleCloseModal}>
              Входящие звонки от клиентов ({income})
            </Link>
          )}
        </div>
      </div>
      <button className="btn btn-danger" onClick={handleCloseModal}>
        Отложить
      </button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  client: state.clients.callBackActiveCount,
  orderAlert: +state.order.blockedStatus?.countAlert || 0,
  orderWarning: +state.order.blockedStatus?.countWarning || 0,
  information: state.information.countNew,
  income: state.cdr.incomeCount,
});

export default connect(mapStateToProps)(TimerNotification);
