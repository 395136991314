import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, AppDispatch } from "./store";
import { ToastContainer } from "react-toastify";
import { Flip } from "react-toastify";
import "react-toastify/scss/main.scss";

import "bootstrap/dist/js/bootstrap.js";
import PhoneModal from "./containers/phone-modal";
import MenuContainer from "./containers/menu-container";
import EventsHistory from "./pages/eventsHistory/eventsHistory";
import Script from "./pages/script/Script";
import Chat from "./pages/chat/chatPage";
import TimerNotification from "./containers/TimerNotification";

import { store } from "./store";
import { IClientsListRequest, IUserProfile, UserType } from "./api";
import { checkCallBackList, fetchClients } from "./store/clients/actions";
import { ClientActiveType } from "./store/clients/types";
import { fetchBlockedOrders } from "./store/order/actions";
import { fetchNewInformation } from "./store/information/actions";
import { fetchCountIncome } from "./store/cdr/actions";

import { fetchMentee } from "./store/mentee/actions";
import { ACTION } from "./store/system/types";
import { fetchProfile } from "./store/user/action";

interface Props {
  current: IUserProfile | null;
  auth: UserType | null;
  isLoading: boolean;
  children: JSX.Element[] | JSX.Element;
  fetchProfile: () => void;
  checkCallBackList: () => void;
  fetchClients: (request: IClientsListRequest) => void;
  fetchBlockedOrders: () => void;
  fetchNewInformation: () => void;
  fetchCountIncome: () => void;
  fetchMentee: () => void;
}

const App: React.FC<Props> = ({
  current,
  auth,
  isLoading,
  children,
  fetchProfile,
  checkCallBackList,
  fetchClients,
  fetchBlockedOrders,
  fetchNewInformation,
  fetchCountIncome,
  fetchMentee,
}) => {
  const history = useHistory();
  const [loop, setLoop] = useState<any>(null);
  useEffect(
    () =>
      history.listen((location) => {
        store.dispatch({
          type: ACTION.GLOBAL_CHANGE_URL,
          payload: `${location}`,
        });
      }),
    [history]
  );

  const getAllWarnings = () => {
    fetchClients({ type: ClientActiveType.CALLBACK_LIST });
    fetchBlockedOrders();
    fetchNewInformation();
    fetchCountIncome();
    // if (current?.hasMentorRight) {
    //   fetchMentee();
    // }
  };

  useEffect(() => {
    if (auth) {
      fetchProfile();
      getAllWarnings();
      setLoop(
        setInterval(() => {
          checkCallBackList();
        }, 60000)
      );
    } else {
    }
    return () => {
      clearInterval(loop);
    };
  }, [auth]);

  return auth && current ? (
    <div className={auth.group === "manager-out" ? "wr-block" : ""}>
      <MenuContainer />
      <div className="content">{children}</div>
      <PhoneModal />
      <EventsHistory />
      <Script />
      <Chat />
      <ToastContainer transition={Flip} />
      {auth.group === "manager-out" && <TimerNotification />}
    </div>
  ) : isLoading ? null : (
    <div className="content">{children}</div>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      fetchProfile,
      checkCallBackList,
      fetchClients,
      fetchBlockedOrders,
      fetchNewInformation,
      fetchCountIncome,
      fetchMentee,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
