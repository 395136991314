import {
  IScriptAnswer,
  IScriptData,
  IScriptStep,
  IScriptStepContent,
} from "../../api";

export enum ACTION {
  SCRIPT_FETCH = "SCRIPT_FETCH",
  SCRIPT_SUCCESS = "SCRIPT_SUCCESS",
  SCRIPT_ERROR = "SCRIPT_ERROR",

  SCRIPT_TOGGLE_VISIBILITY = "SCRIPT_TOGGLE_VISIBILITY",
  GLOBAL_CHANGE_URL = "GLOBAL_CHANGE_URL",
}

interface IScriptFetchAction {
  type: ACTION.SCRIPT_FETCH;
}

interface IScriptErrorAction {
  type: ACTION.SCRIPT_ERROR;
}

interface IScriptToggleVisibilityAction {
  type: ACTION.SCRIPT_TOGGLE_VISIBILITY;
}

interface IScriptSuccessAction {
  type: ACTION.SCRIPT_SUCCESS;
  payload: IScriptData;
}

interface IChangeUrlAction {
  type: ACTION.GLOBAL_CHANGE_URL;
}

export type ScriptActionType =
  | IScriptToggleVisibilityAction
  | IScriptFetchAction
  | IScriptSuccessAction
  | IScriptErrorAction
  | IChangeUrlAction;

export interface IScriptState {
  isLoading: boolean;
  isVisible: boolean;
  error: string;
  steps: IScriptStep[];
  content: IScriptStepContent[];
  answers: IScriptAnswer[];
}
