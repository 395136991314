import { IChatMessage } from "../../api";
import { toastInfo } from "../../services/toast/toast";
import {
  IChatState,
  ChatActionType,
  ACTION,
  SocketMessageType,
  IWSUsersOnline,
  IWSUserRead,
} from "./types";

const initialState: IChatState = {
  isConnected: false,
  isUsersLoading: false,
  isMessagesLoading: false,
  isVisible: false,
  error: "",
  usersWithMessages: [],
  userIdsOnline: [],
  myId: null,
};

export const reducer = (
  state = initialState,
  action: ChatActionType
): IChatState => {
  switch (action.type) {
    case ACTION.GLOBAL_CHANGE_URL:
      return {
        ...state,
        isVisible: false,
      };
    case ACTION.CHAT_FETCH_USERS:
      return {
        ...state,
        isUsersLoading: true,
      };
    case ACTION.CHAT_FETCH_MESSAGES:
      return {
        ...state,
        isMessagesLoading: true,
      };

    case ACTION.CHAT_SUCCESS_USERS:
      return {
        ...state,
        isUsersLoading: false,
        usersWithMessages: [
          {
            userId: 0,
            userName: "ОБЩИЙ ЧАТ",
            countUnread: 0,
            messages: [],
            isActive: false,
          },
          ...action.payload.map((user) => ({
            ...user,
            messages: [],
            countUnread: user.countUnread || 0,
            isActive: false,
          })),
        ],
      };

    case ACTION.CHAT_SUCCESS_MESSAGES: {
      return {
        ...state,
        isMessagesLoading: false,
        usersWithMessages: state.usersWithMessages.map((userAndMessage) =>
          userAndMessage.userId === action.payload.userId
            ? {
                ...userAndMessage,
                messages: action.payload.messages.reverse(),
                countUnread: 0,
              }
            : userAndMessage
        ),
      };
    }

    case ACTION.CHAT_TOGGLE_VISIBILITY:
      return {
        ...state,
        isVisible: !state.isVisible,
      };

    case ACTION.WS_STATUS:
      return {
        ...state,
        isConnected: action.payload.status,
        myId: action.payload.myId,
      };

    case ACTION.WS_RECEIVE:
      switch (action.payload.type) {
        case SocketMessageType.message: {
          const data = action.payload.data as IChatMessage;

          if (data.user_from !== state.myId) {
            toastInfo("Новое сообщение");
          }

          return {
            ...state,
            usersWithMessages: state.usersWithMessages.map((userMessages) =>
              userMessages.userId === data.user_from &&
              data.user_from !== state.myId
                ? {
                    ...userMessages,
                    countUnread: userMessages.countUnread + 1,
                    messages: [...userMessages.messages, data],
                  }
                : userMessages.userId === data.user_to
                ? {
                    ...userMessages,
                    messages: [...userMessages.messages, data],
                  }
                : userMessages
            ),
          };
        }
        case SocketMessageType.usersOnline: {
          const data = action.payload.data as IWSUsersOnline;
          return {
            ...state,
            userIdsOnline: data.userIds,

            // usersWithMessages: state.usersWithMessages.map((userMessages) =>
            //   data.userIds.includes(userMessages.userId)
            //     ? { ...userMessages, isActive: true }
            //     : { ...userMessages, isActive: false }
            // ),
          };
        }
        case SocketMessageType.read: {
          const data = action.payload.data as IWSUserRead;

          return {
            ...state,
            usersWithMessages: state.usersWithMessages.map((userAndMessage) =>
              userAndMessage.userId === data.userFrom
                ? {
                    ...userAndMessage,
                    messages: userAndMessage.messages.map((message) =>
                      message.user_to === data.userFrom
                        ? { ...message, read: true }
                        : message
                    ),
                  }
                : userAndMessage
            ),
          };
        }
        default:
          return state;
      }
    default:
      return state;
  }
};
