import axios, { AxiosRequestConfig, AxiosError } from "axios";
import _ from "lodash";
import { HttpStatus } from "./http-status";
import authHeader from "./auth-header";
import { Routes } from "../../router/helper";
import { SERVER_URL } from "../../env";

const homeUrl = SERVER_URL;
//const homeUrl = "/";

export interface AxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
  request?: any;
}

export interface Request {
  (config: AxiosRequestConfig): Promise<any>;
}

axios.interceptors.response.use(undefined, (error: AxiosError) => {
  if (_.get(error, "response.status") === HttpStatus.UNAUTHORIZED) {
    window.location.assign(Routes.main);
  }
  const errorMessage = _.get(error, "response.data") || "";
  return Promise.reject(errorMessage);
});

export const request: Request = (params) =>
  axios({
    ...params,
    url: `${homeUrl}/${params.url}`,
    headers: authHeader(),
  });
