import { getUnixTime } from "date-fns";
import { AppThunk } from "..";
import {
  ClientAPI,
  IClientsListRequest,
  IClientUpdateStatus,
  IClientEditor,
} from "../../api";
import { toastError, toastSuccess } from "../../services/toast/toast";
import { ACTION, ClientActiveType, IClientActionTypes } from "./types";

export const fetchClients =
  (request: IClientsListRequest): AppThunk<IClientActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CLIENTS_FETCH,
        payload: request.type,
      });
      const response = await ClientAPI.getClients(request);
      dispatch({
        type: ACTION.CLIENTS_SUCCESS,
        payload: response,
        clientType: request.type,
      });
      if (request.type === ClientActiveType.CALLBACK_LIST) {
        dispatch(checkCallBackList());
      }
    } catch (error) {
      dispatch({
        type: ACTION.CLIENTS_ERROR,
      });
    }
  };

export const updateClientStatus =
  (request: IClientUpdateStatus): AppThunk<IClientActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CLIENTS_UPDATE_STATUS_FETCH,
      });
      const client = await ClientAPI.updateStatus(request);
      dispatch({
        type: ACTION.CLIENTS_UPDATE_STATUS_SUCCESS,
        payload: client,
      });
      toastSuccess("Статус клиента изменен");
      dispatch(checkCallBackList());
    } catch (error) {
      dispatch({
        type: ACTION.CLIENTS_UPDATE_STATUS_ERROR,
      });
      toastError("Ошибка");
    }
  };

export const addVipClient =
  (clientId: number): AppThunk<IClientActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CLIENTS_VIP_ADD_FETCH,
      });
      await ClientAPI.addVip(clientId);
      toastSuccess("Клиент добавлен в избранное");
      dispatch({
        type: ACTION.CLIENTS_VIP_ADD_SUCCESS,
        payload: clientId,
      });
    } catch (error) {
      toastError(`Ошибка: ${error}`);
      dispatch({
        type: ACTION.CLIENTS_VIP_ERROR,
      });
    }
  };

export const removeVipClient =
  (clientId: number): AppThunk<IClientActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CLIENTS_VIP_REMOVE_FETCH,
      });
      await ClientAPI.removeVip(clientId);
      toastSuccess("Клиент удален из избранных");
      dispatch({
        type: ACTION.CLIENTS_VIP_REMOVE_SUCCESS,
        payload: clientId,
      });
    } catch (error) {
      toastError("Ошибка");
      dispatch({
        type: ACTION.CLIENTS_VIP_ERROR,
      });
    }
  };

export const assignClients =
  (): AppThunk<IClientActionTypes> => async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CLIENTS_ASSIGN_FETCH,
      });
      await ClientAPI.assign();
      dispatch({
        type: ACTION.CLIENTS_ASSIGN_SUCCESS,
      });
      toastSuccess("Клиенты добавлены");
      dispatch(fetchClients({ type: ClientActiveType.CALL_LIST }));
    } catch (error) {
      toastError("Ошибка получения клиентов");
      dispatch({
        type: ACTION.CLIENTS_ASSIGN_ERROR,
      });
    }
  };

export const createNew =
  (request: IClientEditor): AppThunk<IClientActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CLIENTS_CREATE_FETCH,
      });
      const data = await ClientAPI.createNew(request);
      dispatch({
        type: ACTION.CLIENTS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      toastError("Ошибка создания клиента");
      dispatch({
        type: ACTION.CLIENTS_CREATE_ERROR,
      });
    }
  };

export const checkCallBackList =
  (): AppThunk<IClientActionTypes> => async (dispatch, getState) => {
    const currentUnixTime = getUnixTime(new Date());
    //const prevCount = getState().clients.callBackActiveCount;
    const newCount = getState().clients.callBackList.filter(
      (client) => client.dateCallback < currentUnixTime
    ).length;

    // if (newCount > prevCount && prevCount === 0) {
    //   toastWarning("Вам нужно перезонить клиенту");
    // }
    dispatch({
      type: ACTION.CHECK_CALLBACK_LIST,
      payload: newCount,
    });
  };

export const shiftTime =
  (periodId: number): AppThunk<IClientActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CLIENTS_SHIFT_TIME_SUCCESS,
      });
      await ClientAPI.shiftTime(periodId);
      toastSuccess("Операция выполенена");
      dispatch(fetchClients({ type: ClientActiveType.CALLBACK_LIST }));
    } catch (error) {
      toastError("Ошибка выполенения");
      dispatch({
        type: ACTION.CLIENTS_SHIFT_TIME_ERROR,
      });
    }
  };
