import React from "react";
import { IProductSet } from "../../../api";

interface Props {
  packet: IProductSet;
  showProduct: (packet: IProductSet, index: number) => void;
  addPacket: (packet: IProductSet) => void;
}

export const ProductSet: React.FunctionComponent<Props> = ({
  packet,
  addPacket,
  showProduct,
}) => {
  return (
    <div className="card mb-2">
      <div className="card-body d-flex justify-content-between">
        <div className="col">
          {packet.content.map((content, poz) => (
            <div>
              {poz + 1}. &nbsp;
              <a href="#" onClick={() => showProduct(packet, poz)}>
                {content.productName}
              </a>
              <span className="text-muted ml-2  ">
                ({content.article}) - {content.count} шт.
              </span>
            </div>
          ))}
        </div>
        <div className=" row">
          <div className="wi-150 text-center">{packet.price} руб.</div>
          <div className="wi-150 text-center">
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={() => addPacket(packet)}
            >
              + в заказ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
