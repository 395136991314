import React, { useState } from "react";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import ReactAudioPlayer from "react-audio-player";
import { FiPhoneIncoming, FiPhoneOutgoing, FiHeadphones } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";

import { showDuration } from "../../../helpers/showDuration";
import { CdrType } from "../../../api";
import { SERVER_URL } from "../../../env";

import "./cdr.scss";

interface Props {
  calls: CdrType[];
  hasMentorRight?: boolean;
  openClientFromCdr?: (cdrId: number) => void;
}

export const CallList: React.FunctionComponent<Props> = ({
  calls,
  hasMentorRight,
  openClientFromCdr,
}) => {
  const [src, setSrc] = useState("");
  const [activeId, setActive] = useState(0);

  const showAllColumns = !!openClientFromCdr;

  const handleListen = (call: CdrType) => {
    const path = `${SERVER_URL}/api/v1/record/listen/${call.start_stamp.substr(
      0,
      10
    )}/${call.uuid.trim()}/`;
    setSrc(path);
    setActive(call.id);
  };

  const mentee = 0;
  const handleMentee = (userId?: number) => {};

  return (
    <div className="p-3">
      {activeId !== 0 && <ReactAudioPlayer src={src} controls autoPlay />}

      {hasMentorRight && (
        <div className="card mt-2 bg-light">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="">
                {/* Количество учеников: {students.length} */}
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    checked={!mentee}
                    onClick={() => {
                      handleMentee(undefined);
                    }}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Мои заказы
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                    checked={mentee !== undefined}
                    onClick={() => {
                      handleMentee(0);
                    }}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Заказы моих учеников
                  </label>
                </div>
              </div>
              <div className=""></div>
            </div>
          </div>
        </div>
      )}

      <table className="table table-sm">
        <thead>
          <tr>
            <th scope="col">Дата </th>
            <th scope="col">Время </th>
            <th scope="col" className="text-center">
              Направление
            </th>
            {showAllColumns && (
              <>
                <th scope="col">Имя клиента</th>
                <th scope="col"></th>
              </>
            )}

            <th scope="col" className="text-center">
              Звонок
            </th>
            <th scope="col" className="text-center">
              Разговор
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {calls.map((call) => (
            <tr
              key={call.id}
              className={activeId === call.id ? "active-row" : ""}
            >
              <td>
                {format(new Date(call.end_stamp), "dd MMMM yyyy ", {
                  locale: ru,
                })}
              </td>
              <td>{format(new Date(call.end_stamp), "HH:mm ")}</td>
              <td className="text-center">
                {call.direction ? (
                  <div className="call-outgoing">
                    <FiPhoneOutgoing />
                    исходящий
                  </div>
                ) : (
                  <div className="call-incoming">
                    <FiPhoneIncoming /> входящий
                  </div>
                )}
              </td>
              {showAllColumns && (
                <>
                  <td>
                    {call.direction ? call.destination_name : call.userName}
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-link btn-sm"
                      onClick={() => openClientFromCdr(call.id)}
                    >
                      <AiOutlineUser />
                      Карточка
                    </button>
                  </td>
                </>
              )}
              <td className="text-center">{showDuration(call.duration)} </td>
              <td className="text-center">{showDuration(call.billsec)}</td>
              <td>
                {call.uuid && call.billsec > 0 && (
                  <button
                    type="button"
                    className="btn btn-link btn-sm cdr-listen"
                    onClick={() => handleListen(call)}
                  >
                    <FiHeadphones />
                    Прослушка
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
    </div>
  );
};
