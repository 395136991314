import { IScriptState, ScriptActionType, ACTION } from "./types";

const initialState: IScriptState = {
  isLoading: false,
  isVisible: false,
  error: "",
  steps: [],
  content: [],
  answers: [],
};

export const reducer = (
  state = initialState,
  action: ScriptActionType
): IScriptState => {
  switch (action.type) {
    case ACTION.SCRIPT_TOGGLE_VISIBILITY:
      return {
        ...state,
        isVisible: !state.isVisible,
      };
    case ACTION.GLOBAL_CHANGE_URL:
      return {
        ...state,
        isVisible: false,
      };
    case ACTION.SCRIPT_FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION.SCRIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        content: action.payload.content.map((item) => ({
          ...item,
          text: item.text.replace("&nbsp;", " ").replace(/(<([^>]+)>)/gi, ""),
        })),
      };
    case ACTION.SCRIPT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: "Error",
      };
    default:
      return state;
  }
};
