import { AppThunk } from "..";
import { EventAPI } from "../../api";
import { toastError } from "../../services/toast/toast";
import { ACTION, EventActionType } from "./types";

export const fetchEvent = (): AppThunk<EventActionType> => async (dispatch) => {
  try {
    dispatch({
      type: ACTION.EVENT_FETCH,
    });
    const response = await EventAPI.getByDate();
    dispatch({
      type: ACTION.EVENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ACTION.EVENT_ERROR,
    });
  }
};

export function toggleEventsVisibility(): EventActionType {
  return {
    type: ACTION.EVENT_TOGGLE_VISIBILITY,
  };
}

export const fetchOrderEvent = (
  orderId: number
): AppThunk<EventActionType> => async (dispatch) => {
  try {
    dispatch({
      type: ACTION.EVENT_ORDER_FETCH,
    });
    const response = await EventAPI.getByOrder(orderId);
    dispatch({
      type: ACTION.EVENT_ORDER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    toastError("Ошибка загрузки истории");
    dispatch({
      type: ACTION.EVENT_ORDER_ERROR,
    });
  }
};
