import { AppThunk } from "..";
import { InformationAPI, InformationListRequest } from "../../api";
import { toastSuccess } from "../../services/toast/toast";
import { ACTION, IInformationActionTypes } from "./types";

export const fetchInformation =
  (request?: InformationListRequest): AppThunk<IInformationActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.INFORMATION_FETCH,
      });
      const response = await InformationAPI.getAll(request);
      dispatch({
        type: ACTION.INFORMATION_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ACTION.INFORMATION_ERROR,
      });
    }
  };

export const fetchNewInformation =
  (): AppThunk<IInformationActionTypes> => async (dispatch) => {
    try {
      const response = await InformationAPI.getCountNew();
      dispatch({
        type: ACTION.INFORMATION_COUNT_NEW,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ACTION.INFORMATION_ERROR,
      });
    }
  };

export const confirmInformation =
  (confirmId: number): AppThunk<IInformationActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.INFORMATION_CONFIRM_SEND,
      });
      await InformationAPI.confirm(confirmId);
      dispatch({
        type: ACTION.INFORMATION_CONFIRM_SEND_SUCCESS,
        payload: confirmId,
      });
      toastSuccess("Вы подтвердили прочтение");
    } catch (error) {
      dispatch({
        type: ACTION.INFORMATION_CONFIRM_SEND_ERROR,
      });
    }
  };
