import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import jwt_decode from "jwt-decode";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import App from "./App";
import OrdersPage from "./pages/Orders";
import ProductsPage from "./pages/product/productPage";
import ProductSetPage from "./pages/productSet";
import OrderEditorPage from "./pages/orderEditor/orderEditorPage";

import { LoginPage } from "./pages/login-page";
import { setCurrentUser } from "./store/user/action";
import { Routes } from "./router/helper";
import { UserType } from "./api";

import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
// import "./core.scss";
// import "./def.scss";

const ClientsPage = React.lazy(() => import("./pages/clients/clientsPage"));
const StatisticsPage = React.lazy(() => import("./pages/statistics"));
const BonusPage = React.lazy(() => import("./pages/bonus"));
const AccountPage = React.lazy(() => import("./pages/account/accountPage"));
const CdrPage = React.lazy(() => import("./pages/Cdr"));
const IncomePage = React.lazy(() => import("./pages/Income"));
const InformationPage = React.lazy(
  () => import("./pages/information/InformationPage")
);
const ResetPasswordPage = React.lazy(
  () => import("./pages/resetPassword/resetPasswordPage")
);
const RegistrationPage = React.lazy(
  () => import("./pages/registration/registrationPage")
);
const SmsPage = React.lazy(() => import("./pages/Sms"));
const SmsCreatePage = React.lazy(() => import("./pages/SmsCreate"));
const SmsIncomePage = React.lazy(() => import("./pages/SmsIncome"));
const SmsOutcomePage = React.lazy(() => import("./pages/SmsOutcome"));
const SmsHistoryPage = React.lazy(() => import("./pages/SmsHistory"));

const HelpPage = React.lazy(() => import("./pages/help/helpPage"));
const MentoringPage = React.lazy(() => import("./pages/Mentoring"));
const ArticlesPage = React.lazy(() => import("./pages/Articles"));
const AdminVipPage = React.lazy(() => import("./pages/AdminVip"));

const NotFound = () => (
  <h1 className="text-center pt-5">Страница не найдена или в разработке</h1>
);

export const goToAuthorize = () => {
  if (window.location.pathname !== "/login") window.location.href = "/login";
};

const checkHttps = () => {
  if (
    window.location.protocol === "http:" &&
    window.location.hostname !== "localhost"
  )
    window.location.href = window.location.href.replace(/^http(?!s)/, "https");
};

checkHttps();
// TODO: refactor
let isAuth = false;

if (localStorage.JWT) {
  const decoded = jwt_decode(localStorage.JWT) as UserType;
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    goToAuthorize();
  } else {
    isAuth = true;
    store.dispatch(setCurrentUser(decoded));
  }
} else {
  goToAuthorize();
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <Redirect to={isAuth ? Routes.orders : Routes.login} />
              )}
            />
            <Route exact path={Routes.login} component={LoginPage} />
            <Route
              exact
              path={Routes.resetPassword}
              component={ResetPasswordPage}
            />
            <Route
              exact
              path={Routes.registration}
              component={RegistrationPage}
            />
            <Route path={Routes.account} component={AccountPage} />
            <Route path={Routes.orders} component={OrdersPage} />
            <Route path={Routes.clients} component={ClientsPage} />
            <Route path={Routes.information} component={InformationPage} />
            <Route path={Routes.cdr} component={CdrPage} />
            <Route path={Routes.income} component={IncomePage} />
            <Route path={Routes.product} component={ProductsPage} />
            <Route path={Routes.productSet} component={ProductSetPage} />
            <Route path={Routes.statistics} component={StatisticsPage} />
            <Route path={Routes.statistics_bonus} component={BonusPage} />
            <Route path={Routes.orderEdit} exact component={OrderEditorPage} />
            <Route
              path={Routes.orderCreate}
              exact
              component={OrderEditorPage}
            />
            <Route path={Routes.help} exact component={HelpPage} />
            <Route path={Routes.mentoring} exact component={MentoringPage} />
            <Route path={Routes.articles} exact component={ArticlesPage} />

            <Route path={Routes.sms} exact component={SmsPage} />
            <Route path={Routes.smsCreate} exact component={SmsCreatePage} />
            <Route path={Routes.smsIncome} exact component={SmsIncomePage} />
            <Route path={Routes.smsOutcome} exact component={SmsOutcomePage} />
            <Route path={Routes.smsHistory} exact component={SmsHistoryPage} />

            <Route path={Routes.adminVip} exact component={AdminVipPage} />

            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </App>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
