import {
  IClient,
  IClientCard,
  CdrType,
  IClientDetails,
  IClientProduct,
} from "../../api";

export interface PhoneState {
  isVisible: boolean;
  isKeyboard: boolean;
  isSearching: boolean;
  isClientAvailable: boolean;
  isCalling: boolean;
  isSipAvailable: boolean;
  search: string;
  client: IClientDetails | null;
  clientId: number | null;
  clientProducts: IClientProduct[];
  calls: CdrType[];
  errorMessage: string;
}

export enum ACTION {
  TOGGLE_VISIBLE = "TOGGLE_VISIBLE",
  CHANGE_SEARCH = "CHANGE_SEARCH",
  OPEN_CLIENT = "OPEN_CLIENT",
  SEARCH_CLIENT_DATA = "SEARCH_CLIENT_DATA",
  SET_CLIENT_DATA = "SET_CLIENT_DATA",
  GLOBAL_CHANGE_URL = "GLOBAL_CHANGE_URL",
}

interface ToggleVisibleAction {
  type: ACTION.TOGGLE_VISIBLE;
}
interface ChangeSearchAction {
  type: ACTION.CHANGE_SEARCH;
  payload: string;
}
interface OpenClientAction {
  type: ACTION.OPEN_CLIENT;
  payload: IClient;
}
interface SearchClientDataAction {
  type: ACTION.SEARCH_CLIENT_DATA;
}

interface SetClientDataAction {
  type: ACTION.SET_CLIENT_DATA;
  payload: IClientCard;
}

interface ChangeUrlAction {
  type: ACTION.GLOBAL_CHANGE_URL;
}

export type PhoneActionTypes =
  | ToggleVisibleAction
  | ChangeSearchAction
  | OpenClientAction
  | SearchClientDataAction
  | SetClientDataAction
  | ChangeUrlAction;
