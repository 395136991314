import {
  IChatMessage,
  IChatUser,
  IEvent,
  IIncome,
  InformationType,
} from "../../api";

export interface IChatUserWithMessages extends IChatUser {
  messages: IChatMessage[];
  countUnread: number;
  isActive: boolean;
}

export enum ACTION {
  CHAT_FETCH_USERS = "CHAT_FETCH_USERS",
  CHAT_SUCCESS_USERS = "CHAT_SUCCESS_USERS",
  CHAT_ERROR_USERS = "CHAT_ERROR_USERS",

  CHAT_FETCH_MESSAGES = "CHAT_FETCH_MESSAGES",
  CHAT_SUCCESS_MESSAGES = "CHAT_SUCCESS_MESSAGES",
  CHAT_ERROR_MESSAGES = "CHAT_ERROR_MESSAGES",

  CHAT_TOGGLE_VISIBILITY = "CHAT_TOGGLE_VISIBILITY",

  CHAT_FETCH_PREV_MESSAGES = "CHAT_FETCH_PREV_MESSAGES",
  CHAT_SUCCESS_PREV_MESSAGES = "CHAT_SUCCESS_PREV_MESSAGES",
  CHAT_ERROR_PREV_MESSAGES = "CHAT_ERROR_PREV_MESSAGES",

  WS_STATUS = "WS_STATUS",
  WS_SEND = "WS_SEND",
  WS_RECEIVE = "WS_RECEIVE",

  GLOBAL_CHANGE_URL = "GLOBAL_CHANGE_URL",

  NEW_INCOME_CALL = "NEW_INCOME_CALL",
  NEW_INFORMATION = "NEW_INFORMATION",
}

export enum SocketMessageType {
  message = "message",
  usersOnline = "usersOnline",
  read = "read",

  INFORMATION = "INFORMATION",
  INCOME_CALL = "INCOME_CALL",
  BLOCK = "BLOCK",
  EVENT = "EVENT",
}

export interface IWSUsersOnline {
  userIds: number[];
}

export interface IWSUserRead {
  userFrom: number;
}

interface IWSBlockOrder {
  // TODO: as important (duplicate Event)
  orderId: string;
}

interface IChatFetchAction {
  type: ACTION.CHAT_FETCH_USERS | ACTION.CHAT_FETCH_MESSAGES;
}

interface IChatToggleVisibilityAction {
  type: ACTION.CHAT_TOGGLE_VISIBILITY | ACTION.GLOBAL_CHANGE_URL;
}

interface IChatSuccessUsersAction {
  type: ACTION.CHAT_SUCCESS_USERS;
  payload: IChatUser[];
}

interface IChatSuccessMessagesAction {
  type: ACTION.CHAT_SUCCESS_MESSAGES | ACTION.CHAT_SUCCESS_PREV_MESSAGES;
  payload: {
    userId: number;
    messages: IChatMessage[];
  };
}

interface IWSStatusAction {
  type: ACTION.WS_STATUS;
  payload: { status: boolean; myId: number };
}

interface IWSReceiveAction {
  type: ACTION.WS_RECEIVE;
  payload: {
    type: SocketMessageType;
    data:
      | IChatMessage
      | IWSUsersOnline
      | IWSUserRead
      | IEvent
      | IIncome
      | IWSBlockOrder;
  };
}

interface IWSSendAction {
  type: ACTION.WS_SEND;
  payload: {
    userId: number;
    message: string;
  };
}

// WEBSOCKET OTHER ACTION
interface IIncomeCallAction {
  type: ACTION.NEW_INCOME_CALL;
}

interface IInformationAction {
  type: ACTION.NEW_INFORMATION;
  payload: InformationType;
}

export type ChatActionType =
  | IChatFetchAction
  | IChatSuccessUsersAction
  | IChatSuccessMessagesAction
  | IChatToggleVisibilityAction
  | IWSStatusAction
  | IWSReceiveAction
  | IWSSendAction
  | IIncomeCallAction
  | IInformationAction;

export interface IChatState {
  isConnected: boolean;
  isUsersLoading: boolean;
  isMessagesLoading: boolean;
  isVisible: boolean;
  error: string;
  usersWithMessages: IChatUserWithMessages[];
  userIdsOnline: number[];
  myId: number | null;
}
