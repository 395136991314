import { generatePath } from "react-router-dom";

export enum Routes {
  main = "/",
  product = "/products",
  productSet = "/sets",
  orders = "/orders",
  clients = "/clients",
  adminVip = "/admin-vip",
  cdr = "/cdr",
  income = "/income",
  statistics = "/statistics/balance",
  statistics_payments = "/statistics/payments",
  statistics_bonus = "/statistics/bonus",
  statistics_order_create = "/statistics-create",
  statistics_order_confirm = "/statistics-confirm",
  statistics_order_wait = "/statistics-wait",
  statistics_calls = "/statistics-calls",
  statistics_products = "/statistics-products",
  information = "/information",
  orderCreate = "/order/new",
  orderEdit = "/order/edit/:orderId",
  login = "/login",
  resetPassword = "/reset-password",
  registration = "/registration",
  account = "/account",
  settings = "/settings",
  help = "/help",
  schedule = "/schedule",
  competitions = "/competitions",
  mentoring = "/mentoring",
  articles = "/articles",
  sms = "/sms",
  smsCreate = "/sms-create",
  smsSettings = "/sms-settings",
  smsHistory = "/sms-history",
  smsIncome = "/sms-income",
  smsOutcome = "/sms-outcome",
}

export const generateOrderEditPath = (orderId: number | string): string =>
  generatePath(Routes.orderEdit, { orderId: orderId });
