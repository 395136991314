import { AppThunk } from "..";
import { ScriptAPI } from "../../api";
import { ACTION, ScriptActionType } from "./types";

export const fetchScript = (): AppThunk<ScriptActionType> => async (
  dispatch
) => {
  try {
    dispatch({
      type: ACTION.SCRIPT_FETCH,
    });
    const response = await ScriptAPI.getAll();
    dispatch({
      type: ACTION.SCRIPT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ACTION.SCRIPT_ERROR,
    });
  }
};

export function toggleScriptVisibility(): ScriptActionType {
  return {
    type: ACTION.SCRIPT_TOGGLE_VISIBILITY,
  };
}
