import { APIService } from "../../services/api-service";
import {
  IArticleRequest,
  IArticlePage,
  IArticle,
  IArticleDetailsRequest,
  IIntegrationRequest,
  IIntegrationForm,
} from "..";

export const search = (request?: IArticleRequest) => {
  const url = `api/v1/article/search/`;
  return APIService.get<IArticleRequest, IArticlePage>(url, request);
};
export const details = (request: IArticleDetailsRequest) => {
  const url = `api/v1/article/details/`;
  return APIService.get<IArticleDetailsRequest, IArticle>(url, request);
};

export const addIntegration = (request: IIntegrationRequest) => {
  const url = `api/v1/article/integration/`;
  return APIService.post<IArticleDetailsRequest, IArticle>(url, request);
};

export const updateIntegration = (request: IIntegrationForm) => {
  const url = `api/v1/article/integration/`;
  return APIService.patch<IIntegrationForm, IArticle>(url, request);
};

export const removeIntegration = (integrationId: number) => {
  const url = `api/v1/article/integration/${integrationId}`;
  return APIService.delete<number, IArticle>(url);
};
