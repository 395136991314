import { IUserProfile, UserType } from "../../api";

export interface UserState {
  auth: UserType | null;
  current: IUserProfile | null;
  isLoading: boolean;
  isSending: boolean;
  isError: boolean;
}

export enum ACTION {
  FETCH = "PROFILE_FETCH",
  SUCCESS = "PROFILE_SUCCESS",
  ERROR = "PROFILE_ERROR",
  USER_SET_CURRENT_USER_DATA = "USER_SET_CURRENT_USER_DATA",
  USER_CHANGE_FETCH = "USER_CHANGE_FETCH",
  USER_CHANGE_PASS_SUCCESS = "USER_CHANGE_PASS_SUCCESS",
  USER_CHANGE_SETTINGS_SUCCESS = "USER_CHANGE_SETTINGS_SUCCESS",
  USER_CHANGE_ERROR = "USER_CHANGE_ERROR",
}

interface IFetchAction {
  type: ACTION.FETCH;
}

interface IErrorAction {
  type: ACTION.ERROR;
}

interface ISuccessAction {
  type: ACTION.SUCCESS;
  payload: IUserProfile;
}

interface SetCurrentUserAction {
  type: typeof ACTION.USER_SET_CURRENT_USER_DATA;
  //payload: UserType;
  payload: UserType;
}

interface FetchUserAction {
  type: typeof ACTION.USER_CHANGE_FETCH;
}

interface ErrorUserAction {
  type: typeof ACTION.USER_CHANGE_ERROR;
}
export type UserActionTypes =
  | IFetchAction
  | IErrorAction
  | ISuccessAction
  | SetCurrentUserAction
  | FetchUserAction
  | ErrorUserAction;
