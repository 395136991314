import {
  IProductSetPage,
  IProductSetListRequest,
  IProductSetCategory,
} from "./productSet.types";
import { APIService } from "../../services/api-service";

export const getAll = (request?: IProductSetListRequest) => {
  const url = `api/v1/product-set/list/`;
  return APIService.get<IProductSetListRequest, IProductSetPage>(url, request);
};

export const getCategory = () => {
  const url = `api/v1/product-set/category/`;
  return APIService.get<IProductSetCategory[]>(url);
};
