import {
  IOrderPage,
  IOrder,
  IOrderListRequest,
  CountStatusType,
  IOrderEditor,
  ICalculateRequest,
  ICalculateResponse,
  IOrderIdRequest,
  IBlockedStatus,
} from "./order.types";
import { APIService } from "../../services/api-service";
import { IOrdersUpdateComment } from "../../store/order/types";
import { IOrderMenteeRequest } from "..";

export const getAll = (request: IOrderListRequest) => {
  const url = `api/v1/order/list/`;
  return APIService.get<IOrderListRequest, IOrderPage>(url, request);
};

export const getById = (id: number) => {
  const url = `api/v1/order/details/${id}`;
  return APIService.get<IOrder>(url);
};

export const getCountStatus = (request: IOrderMenteeRequest) => {
  const url = `api/v1/order/count-status`;
  return APIService.get<IOrderMenteeRequest, CountStatusType[]>(url, request);
};

export const getCountBlocked = (request: IOrderMenteeRequest) => {
  const url = `api/v1/order/count-blocked`;
  return APIService.get<IOrderMenteeRequest, IBlockedStatus>(url, request);
};

export const getEditableOrder = (id: number) => {
  const url = `api/v1/order/editor/${id}`;
  return APIService.get<IOrderEditor & ICalculateResponse>(url);
};

export const calculate = (request: ICalculateRequest) => {
  const url = `api/v1/order/calculate`;
  return APIService.post<ICalculateRequest, ICalculateResponse>(url, request);
};

export const update = (request: IOrderEditor) => {
  const url = `api/v1/order/update`;
  return APIService.post<IOrderEditor, IOrder>(url, request);
};

export const create = (request: IOrderEditor) => {
  const url = `api/v1/order/create`;
  return APIService.post<IOrderEditor, IOrder>(url, request);
};

export const setCancel = (orderId: number) => {
  const url = "api/v1/order/cancel";
  return APIService.post<IOrderIdRequest, IOrder>(url, { orderId });
};

export const setDraft = (orderId: number) => {
  const url = "api/v1/order/draft";
  return APIService.post<IOrderIdRequest, IOrder>(url, { orderId });
};

export const setFromDraft = (orderId: number) => {
  const url = "api/v1/order/from-draft";
  return APIService.post<IOrderIdRequest, IOrder>(url, { orderId });
};

export const setConfirm = (orderId: number) => {
  const url = "api/v1/order/confirm";
  return APIService.post<IOrderIdRequest, IOrder>(url, { orderId });
};

export const updateComment = (request: IOrdersUpdateComment) => {
  const url = "api/v1/order/update-comment";
  return APIService.post<IOrdersUpdateComment, IOrder>(url, request);
};

export const unBlock = (orderId: number) => {
  const url = "api/v1/order/un-block";
  return APIService.post<IOrderIdRequest, IOrder>(url, { orderId });
};
