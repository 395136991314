import { APIService } from "../../services/api-service";
import {
  IAdminVipPage,
  IAdminVipRequest,
  IFolderContentRequest,
  IFolderUpdateRequest,
  IVipFolder,
} from "./client.types";

export const getClients = (request: IAdminVipRequest) => {
  const url = "api/v1/admin-vip/search";
  return APIService.post<IAdminVipRequest, IAdminVipPage>(url, request);
};

export const getFolders = () => {
  const url = "api/v1/admin-vip/folders";
  return APIService.get<IVipFolder[]>(url);
};

export const createFolders = (name: string) => {
  const url = "api/v1/admin-vip/folder";
  return APIService.post<{ name: string }, string>(url, { name });
};

export const removeFolders = (id: number) => {
  const url = `api/v1/admin-vip/folder/${id}`;
  return APIService.delete<{ name: string }, string>(url);
};

export const addInFolder = (id: number, ids: number[]) => {
  const url = `api/v1/admin-vip/folder/add/${id}`;
  return APIService.post<{ ids: number[] }, string>(url, { ids });
};

export const getFolderContent = (request: IFolderContentRequest) => {
  const url = `api/v1/admin-vip/folder/content/${request.folderId}`;
  return APIService.post<IFolderContentRequest, IAdminVipPage>(url, request);
};

export const removeFromFolder = (id: number, ids: number[]) => {
  const url = `api/v1/admin-vip/folder/remove/${id}`;
  return APIService.post<{ ids: number[] }, string>(url, { ids });
};

export const clearFolder = (id: number) => {
  const url = `api/v1/admin-vip/folder/clear/${id}`;
  return APIService.delete<string>(url);
};

export const update = (request: IFolderUpdateRequest) => {
  const url = `api/v1/admin-vip/folder/${request.id}`;
  return APIService.put<IFolderUpdateRequest, IVipFolder>(url, request);
};

export const copy = (folderId: number, managerId: number) => {
  const url = `api/v1/admin-vip/folder/copy/${folderId}`;
  return APIService.post<{ managerId: number }, number>(url, { managerId });
};
