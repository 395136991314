import React, { useState } from "react";
import { IOrder } from "../../../api";
import { IOrdersUpdateComment } from "../../../store/order/types";

interface Props {
  order: IOrder;
  isSending: boolean;
  updateComment: (request: IOrdersUpdateComment) => void;
}

export const ModalEditComment: React.FunctionComponent<Props> = ({
  order,
  isSending,
  updateComment,
}) => {
  const [text, setText] = useState(order.comment);

  return (
    <div className="">
      <h2>Изменить комментарий</h2>
      <div>
        <textarea
          className="form-control mt-2"
          placeholder="Добавьте свой комментарий"
          rows={3}
          value={text}
          onChange={(event) => setText(event.target.value)}
        ></textarea>
      </div>
      <button
        className="btn btn-primary mt-3"
        onClick={() => updateComment({ orderId: order.orderId, comment: text })}
        disabled={isSending}
      >
        {isSending ? "Ожидайте..." : "Сохранить"}
      </button>
    </div>
  );
};
