import React, { useState } from "react";
import {
  IOrderContentEditor,
  IProduct,
  IProductCategory,
  IProductCategoryGroup,
} from "../../../api";

import { PaginationType } from "../../../api/common-types/pagination.types";
import { BsCollection } from "react-icons/bs";
import { GoSearch } from "react-icons/go";
import "./product.scss";
import { Pagination } from "../../../components/common/pagination/pagination";
import { ProductsBlock } from "./";
import { Loading } from "../../../components/common/loading/Loading";
import { Modal } from "../../../components/modal/modal";
import { ModalView } from "./modalView";
import { VscDebugBreakpointLogUnverified } from "react-icons/vsc";
import { ProductsRow } from "./productRow";

interface Props {
  isLoading: boolean;
  products: IProduct[];
  groups: IProductCategoryGroup[];
  categories: IProductCategory[];
  search: string;
  pagination: PaginationType | null;
  activeCategory: number | null;
  onChangeCategory: (category: number | null) => void;
  onChangePage: (page: number) => void;
  onSearch: (search: string) => void;
  addOrderContent: (content: IOrderContentEditor) => void;
}

enum ProductVisibilityType {
  BLOCK = "BLOCK",
  SCRIPT = "SCRIPT",
  TABLE = "TABLE",
}

//const dateNow = new Date();

export const ProductsList: React.FC<Props> = (props) => {
  const {
    isLoading,
    products,
    groups,
    categories,
    search,
    pagination,
    activeCategory,
    onChangeCategory,
    onChangePage,
    onSearch,
    addOrderContent,
  } = props;

  const [searchValue, setSearchValue] = useState(search);
  const [visibilityType, setVisibilityType] = useState(
    ProductVisibilityType.BLOCK
  );
  const [viewProduct, setViewProduct] = useState<IProduct | null>(null);
  const [activeGroup, setActiveGroup] = useState<number | null>(null);

  const handleNext = () => {
    const index = products.findIndex((product) => product === viewProduct);
    if (index < products.length - 1) {
      setViewProduct(products[index + 1]);
    }
  };

  const handlePrev = () => {
    const index = products.findIndex((product) => product === viewProduct);
    if (index > 0) {
      setViewProduct(products[index - 1]);
    }
  };

  const renderCategory = (category: IProductCategory) => {
    return (
      <li
        className={`nav-item ${
          activeCategory === category.id ? " item-active" : ""
        }`}
      >
        <div className="d-flex justify-content-between">
          <a
            className="nav-link"
            href="#"
            onClick={() => onChangeCategory(category.id)}
          >
            <span>
              <VscDebugBreakpointLogUnverified /> {category.name} (
              {category.total})
            </span>
          </a>
        </div>
      </li>
    );
  };

  const renderGroup = (group: IProductCategoryGroup) => {
    return (
      <>
        <li className="nav-group">
          <div className="d-flex justify-content-between">
            <a
              className="nav-link"
              href="#"
              onClick={() =>
                setActiveGroup(activeGroup === group.id ? null : group.id)
              }
            >
              <span>
                <BsCollection /> {group.name}
              </span>
            </a>
          </div>
        </li>

        {activeGroup === group.id &&
          group.categories.map((category) => renderCategory(category))}
      </>
    );
  };

  return (
    <div className="page-with-sidebar">
      <div className="row ">
        <div className="col-sidebar">
          <div className="sidebar-fixed sidebar-scroll">
            <div className="card-body">
              <ul className="nav flex-column">
                <li
                  className={`nav-item ${
                    activeCategory === null
                      ? " item-active nav-group"
                      : "nav-group"
                  }`}
                >
                  <div className="d-flex justify-content-between">
                    <a
                      className="nav-link"
                      href="#"
                      onClick={() => {
                        onChangeCategory(null);
                        setActiveGroup(null);
                      }}
                    >
                      <span>
                        <BsCollection /> ВСЕ КАТЕГОРИИ
                      </span>
                    </a>
                  </div>
                </li>
                {groups.map((group) => renderGroup(group))}
                <div className="dropdown-divider"></div>
                {categories.map((category) => renderCategory(category))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col ">
          <br />
          <div className="d-flex justify-content-between mb-3">
            <div className="input-group w-100">
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSearch(searchValue);
                  }
                }}
              />

              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => onSearch(searchValue)}
              >
                <i className="icon-user icon-white"></i>
                Поиск
              </button>
            </div>
            <div className="w-50">
              <ul className="nav nav-pills  justify-content-end">
                <li className="nav-item">
                  <a
                    className={
                      visibilityType === ProductVisibilityType.BLOCK
                        ? "nav-link active"
                        : "nav-link"
                    }
                    type="button"
                    onClick={() =>
                      setVisibilityType(ProductVisibilityType.BLOCK)
                    }
                  >
                    Блок
                  </a>
                </li>
                {/* <li className="nav-item">
            <a
              className={
                visibilityType === ProductVisibilityType.SCRIPT
                  ? "nav-link active"
                  : "nav-link"
              }
              type="button"
              onClick={() => setVisibilityType(ProductVisibilityType.SCRIPT)}
            >
              Скрипт
            </a>
          </li> */}
                <li className="nav-item">
                  <a
                    className={
                      visibilityType === ProductVisibilityType.TABLE
                        ? "nav-link active"
                        : "nav-link"
                    }
                    type="button"
                    onClick={() =>
                      setVisibilityType(ProductVisibilityType.TABLE)
                    }
                  >
                    Таблица
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div>
        Общий список / По категориям / Избранное / Популярное / По скрипту
      </div> */}
          {isLoading ? (
            <Loading />
          ) : products.length === 0 ? (
            <div className="text-center mt-5">
              <h2>
                <GoSearch />{" "}
                {search.length > 0
                  ? "По вашему запросу ничего не нашлось"
                  : "Введите название"}
              </h2>
            </div>
          ) : (
            <>
              {visibilityType === ProductVisibilityType.BLOCK && (
                <div className="row">
                  {products.map((product) => (
                    <ProductsRow
                      product={product}
                      onClick={() => setViewProduct(product)}
                      addOrderContent={addOrderContent}
                    />
                  ))}
                </div>
              )}
              {visibilityType === ProductVisibilityType.TABLE && (
                <div className="card p-3">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th className="wi-50 text-center"></th>
                        <th>Наименование</th>

                        <th>Количество</th>
                        <th className="wi-100 text-right">Стоимость</th>
                        <th className="wi-150 text-center">Корзина</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product) => (
                        <>
                          <tr key={product.id} className="name-row">
                            <td className="text-center">
                              <button className="btn btn-link btn-sm">
                                <BsCollection />
                              </button>
                            </td>
                            <td className="" colSpan={3}>
                              <a
                                href="#"
                                onClick={() => setViewProduct(product)}
                              >
                                {product.name}
                              </a>
                            </td>
                            <td></td>
                          </tr>
                          {product.articles.map((article) => (
                            <tr key={article.article}>
                              <td></td>
                              <td className="">{article.article}</td>
                              <td className="text-center">
                                {article.count > 0 ? (
                                  `${article.count} шт`
                                ) : (
                                  <span className="text-muted">
                                    нет в наличии
                                  </span>
                                )}
                              </td>
                              <td className="text-right">{article.price}</td>
                              <td className="text-center">
                                {(article.count > 0 || article.preOrder) && (
                                  <button
                                    type="button"
                                    className={
                                      article.count > 0
                                        ? "btn btn-outline-success btn-sm"
                                        : "btn btn-outline-warning btn-sm"
                                    }
                                    onClick={() =>
                                      addOrderContent({
                                        article: article.article,
                                        price: article.price,
                                        count: 1,
                                        productName: product.name,
                                        productId: product.id,
                                        contentId: "",
                                      })
                                    }
                                  >
                                    + в заказ
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}

          {pagination && +pagination.totalPages > 1 && (
            <Pagination pagination={pagination} onChangePage={onChangePage} />
          )}
          {viewProduct && (
            <Modal
              isModalOpen={!!viewProduct}
              onClose={() => setViewProduct(null)}
            >
              <ModalView
                product={viewProduct}
                addOrderContent={addOrderContent}
                onNext={handleNext}
                onPrev={handlePrev}
              />
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};
