import {
  IChangePassword,
  IMentee,
  IUserAllShort,
  IUserShort,
  UserType,
} from "./user.types";
import { APIService } from "../../services/api-service";
import { IMenteeRequest, IUserProfile } from "..";

export const login = () => {
  const url = `api/v1/user/active`;
  return APIService.get<UserType[]>(url);
};

export const getProfile = () => {
  const url = `api/v1/user/profile`;
  return APIService.get<IUserProfile>(url);
};

export const changePassword = (request: IChangePassword) => {
  const url = `api/v1/user/change-password`;
  return APIService.post<IChangePassword, string>(url, request);
};

export const getMentee = () => {
  const url = `api/v1/user/mentee`;
  return APIService.get<IMentee[]>(url);
};

export const create = (request: IMenteeRequest) => {
  const url = `api/v1/user/create`;
  return APIService.post<IMenteeRequest, IMentee>(url, request);
};

export const update = (request: IMenteeRequest) => {
  const url = `api/v1/user/update`;
  return APIService.post<IMenteeRequest, IMentee>(url, request);
};

export const getActiveList = () => {
  const url = `api/v1/user/active-list`;
  return APIService.get<IUserShort[]>(url);
};

export const getAll = () => {
  const url = `api/v1/user/all`;
  return APIService.get<IUserAllShort[]>(url);
};
