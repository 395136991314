import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IArticle, IArticlePage } from "../../api";
import { IArticleState } from "./types";

const initialState: IArticleState = {
  isLoading: false,
  isPending: false,
  error: "",
  articles: [],
  filterShop: null,
  pagination: null,
  isDetailsLoading: false,
  articleDetails: null,
};

export const articleSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    loading: (state: IArticleState, action: PayloadAction<number | null>) => {
      return {
        ...state,
        isLoading: true,
        filterShop: action.payload,
      };
    },
    success: (state: IArticleState, action: PayloadAction<IArticlePage>) => {
      return {
        ...state,
        isLoading: false,
        articles: action.payload.data,
        pagination: action.payload.pagination,
      };
    },
    error: (state: IArticleState, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    detailsLoading: (state: IArticleState) => {
      return {
        ...state,
        isDetailsLoading: true,
      };
    },
    detailsSuccess: (state: IArticleState, action: PayloadAction<IArticle>) => {
      return {
        ...state,
        isDetailsLoading: false,
        isPending: false,
        articleDetails: action.payload,
        articles: state.articles.map((item) =>
          item.article === action.payload.article ? action.payload : item
        ),
      };
    },
    pending: (state: IArticleState) => {
      return {
        ...state,
        isPending: true,
      };
    },
  },
});

export const { actions, reducer } = articleSlice;
