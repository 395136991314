import { AppThunk } from "..";
import { IMenteeRequest, UserAPI } from "../../api";
import { toastError, toastSuccess } from "../../services/toast/toast";
import { ACTION, IMenteeActionTypes } from "./types";

export const fetchMentee =
  (): AppThunk<IMenteeActionTypes> => async (dispatch) => {
    try {
      dispatch({
        type: ACTION.FETCH,
      });
      const response = await UserAPI.getMentee();
      dispatch({
        type: ACTION.SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ACTION.ERROR,
      });
    }
  };

export const createUser =
  (request: IMenteeRequest): AppThunk<IMenteeActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.OPERATION_FETCH,
      });
      const response = await UserAPI.create(request);
      dispatch({
        type: ACTION.OPERATION_SUCCESS,
        payload: response,
      });
      toastSuccess("Пользователь добавлен");
    } catch (error) {
      dispatch({
        type: ACTION.OPERATION_ERROR,
      });
      toastError(`Ошибка: ${error}`);
    }
  };

export const updateUser =
  (request: IMenteeRequest): AppThunk<IMenteeActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.OPERATION_FETCH,
      });
      const response = await UserAPI.update(request);
      dispatch({
        type: ACTION.OPERATION_SUCCESS,
        payload: response,
      });
      toastSuccess("Изменения сохранены");
    } catch (error) {
      dispatch({
        type: ACTION.OPERATION_ERROR,
      });
      toastError(`Ошибка: ${error}`);
    }
  };
