import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBonus, IInvoice } from "../../api/";
import { IStatisticsState } from "./types";

const initialState: IStatisticsState = {
  isLoading: false,
  error: "",
  invoice: [],
  bonus: null,
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    loading: (state: IStatisticsState) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    success: (state: IStatisticsState, action: PayloadAction<IInvoice[]>) => {
      return {
        ...state,
        isLoading: false,
        invoice: action.payload,
      };
    },
    successBonus: (state: IStatisticsState, action: PayloadAction<IBonus>) => {
      return {
        ...state,
        isLoading: false,
        bonus: action.payload,
      };
    },
    error: (state: IStatisticsState) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const { actions, reducer } = statisticsSlice;
