import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { fromUnixTime } from "date-fns";
import "react-datepicker/src/stylesheets/datepicker.scss";
import ru from "date-fns/locale/ru";
import setHours from "date-fns/setHours";
import { Modal } from "../modal/modal";
import { ClientStateType, IClientUpdateStatus } from "../../api";
import "./clientCallStatus.scss";

interface Props {
  isModalOpen: boolean;
  clientId: number;
  dateCallback?: number | null;
  comment?: string;
  header?: string;
  billSec?: number;
  onClose: () => void;
  onSave: (request: IClientUpdateStatus) => void;
  onAddVip: (clientId: number) => void;
}

export const ClientCallStatus: React.FC<Props> = ({
  isModalOpen,
  clientId,
  dateCallback = null,
  comment = "",
  header,
  billSec = 0,
  onClose,
  onSave,
  onAddVip,
}) => {
  const [text, setText] = useState(comment);
  const [date, setDate] = useState<any>(
    dateCallback ? fromUnixTime(dateCallback) : setHours(new Date(), 9)
  );

  useEffect(() => {
    setText(comment);
  }, [comment]);
  useEffect(() => {
    setDate(
      dateCallback ? fromUnixTime(dateCallback) : setHours(new Date(), 9)
    );
  }, [dateCallback]);

  return (
    <Modal isModalOpen={isModalOpen} onClose={onClose}>
      <h2>{header || "Разговор завершен"}</h2>
      <div className="row column-finish-modal">
        <div className="col-6 d-flex flex-column ">
          {/* <button
            className="btn btn-primary mt-2"
            onClick={() =>
              onSave({
                clientId,
                status: ClientStateType.callback,
              })
            }
          >
            Перенесён в другую сеть
          </button> */}
          <button
            className="btn btn-primary mt-2"
            onClick={() =>
              onSave({
                clientId,
                status: ClientStateType.BLACKLIST,
              })
            }
          >
            В черный список
          </button>

          <button
            className="btn btn-primary mt-2"
            onClick={() =>
              onSave({
                clientId,
                status: ClientStateType.WRONG,
              })
            }
          >
            Неверный номер
          </button>
          <button
            className="btn btn-primary mt-2"
            onClick={() =>
              onSave({
                clientId,
                status: ClientStateType.UNAVAILABLE,
              })
            }
          >
            Номер недоступен
          </button>
        </div>
        <div className="col-6  d-flex flex-column ">
          <button
            className="btn btn-outline-success mt-2 mb-2"
            disabled={billSec < 30}
            onClick={() => onAddVip(clientId)}
          >
            + В избранные (VIP)
          </button>
          <label className="form-label">Перезвонить в</label>
          <DatePicker
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="d MMMM yyyy H:mm"
            locale={ru}
            minDate={new Date()}
            minTime={setHours(new Date(), 8)}
            maxTime={setHours(new Date(), 20)}
            selected={date}
            onChange={setDate}
            //onChange={setDate}
            placeholderText="Дата и время"
            className="form-control "
          />
          <label className="form-label mb-2">Оставить комментарий</label>
          <textarea
            className="form-control"
            rows={3}
            value={text}
            onChange={(event) => setText(event.target.value)}
          ></textarea>
        </div>
      </div>
      <div className="row column-finish-modal">
        <div className="col-6 d-flex flex-column ">
          <button
            className="btn btn-primary mt-2"
            onClick={() =>
              onSave({
                clientId,
                status: ClientStateType.CALLED,
              })
            }
          >
            Обзвонен
          </button>
        </div>
        <div className="col-6 d-flex flex-column ">
          <button
            className="btn btn-success mt-2"
            onClick={() =>
              onSave({
                clientId,
                status: ClientStateType.CALLBACK,
                comment: text,
                dateCallback: date,
              })
            }
            disabled={!date}
          >
            Запомнить перезвонить
          </button>
        </div>
      </div>
    </Modal>
  );
};
