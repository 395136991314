import React, { useState } from "react";
import { IOrder } from "../../../api";
import { OrderStatusesRu } from "../../../constants/order-statuses";

interface Props {
  order: IOrder;
}

const dateNow = new Date();

export const ModalOrderView: React.FunctionComponent<Props> = ({ order }) => {
  return (
    <div className="orderModal">
      <div className="orderModal__header">
        <h2>
          Заказ {order.orderId}
          <div className="badge badge-success">
            {OrderStatusesRu[order.state]}
          </div>
        </h2>
      </div>
      <div className="orderModal__content">
        <div className="orderModal__description">
          <div className="timeline">
            <div>Дата созданиия:</div>
            <div>Дата измения:</div>
            <div>Дата отправки:</div>
            <div>Дата комлектации:</div>
            <div>Дата получения:</div>
          </div>

          <div>Статус:</div>
          <div>Имя менеджера:</div>
          <div>Группа менеджера:</div>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">Фото</th>
                <th scope="col">Товар </th>
                <th scope="col" className="text-center">
                  Цена
                </th>
                <th scope="col" className="text-center">
                  Количество
                </th>
                <th scope="col" className="text-center">
                  Стоимость
                </th>
              </tr>
            </thead>
            <tbody>
              {order.content.map((content) => (
                <tr key={content.contentId}>
                  <td></td>
                  <td scope="row">
                    {content.productName}
                    <br />
                    {content.article}
                  </td>

                  <td className="text-right">{content.price}</td>
                  <td className="text-center">{content.count} шт.</td>
                  <td className="text-right">
                    {content.price * content.count}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={5} className="text-right"></td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-between">
            <div className="orderModal__client">
              <div>
                <b>Клиент:</b> {order.clientName}
              </div>
              <div>
                <b>Тип доставки:</b> {order.typeDelivery}
              </div>
              <div>
                <b>Адрес доставки: </b>
                {order.clientAddress}
              </div>
            </div>
            <div className="orderModal__summary">
              <div>
                <b>Cкидка:</b>
                <div className="price-block">{order.sumDiscount}</div>
              </div>
              <div>
                <b>Стоимоcть доставки:</b>
                <div className="price-block"> {order.sumDelivery}</div>
              </div>
              <div>
                <b>ИТОГ:</b> <div className="price-block">{order.sumTotal}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
