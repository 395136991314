import {
  InformationPageType,
  InformationListRequest,
  InformationConfirmResponse,
} from "./information.types";
import { APIService } from "../../services/api-service";

export const getAll = (request?: InformationListRequest) => {
  const url = "api/v1/information/all";
  return APIService.get<InformationListRequest, InformationPageType>(
    url,
    request
  );
};

export const getCountNew = () => {
  const url = "api/v1/information/count-new";
  return APIService.get<number>(url);
};

export const confirm = (id: number) => {
  const url = `api/v1/information/confirm/${id}`;
  return APIService.get<InformationConfirmResponse>(url);
};
