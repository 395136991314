import React from "react";
import { IProductSet } from "../../../api";

interface Props {
  productSet: IProductSet | null;
  index: number;
  changeIndex: (index: number) => void;
}

export const ModalView: React.FC<Props> = ({
  productSet,
  index,
  changeIndex,
}) => {
  const product = productSet && productSet.content[index];
  if (!product) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="productModal">
      <h2>{product.productName}</h2>
      {/* <div className="d-flex justify-content-between">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item">
            <button
              className="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#product-desc"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Описание
            </button>
          </li>
          <li className="nav-item">
            <button
              className="nav-link "
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#product-images"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Фотогаллерея
            </button>
          </li>
        </ul>
      </div> */}
      <div className="productModal__content">
        <div className="d-flex justify-content-between">
          <div
            className="tab-content article-list-content"
            id="pills-tabContent"
          >
            <div
              className="tab-pane fade show active"
              id="product-desc"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div dangerouslySetInnerHTML={{ __html: product.description }} />
            </div>
            <div
              className="tab-pane fade"
              id="product-images"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              {/* <div className="productModal__images">
              <div className="productModal__images-menu">
                {product.images.map((image) => (
                  <div onClick={() => setActiveImage(image)}>{image.id}</div>
                ))}
              </div>
              {activeImage && (
                <img
                  src={
                    SERVER_URL +
                    "/" +
                    activeImage.folder +
                    activeImage.file_name
                  }
                  alt={activeImage.name}
                  // style={{ minHeight: "1rem" }}
                />
              )}
            </div> */}
            </div>
          </div>
          <div className="articles-list">
            {productSet.content.map((content, i) => (
              <button
                className={index === i ? "btn btn-link active" : "btn btn-link"}
                onClick={() => changeIndex(i)}
              >
                {content.article}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
