import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdminVipPage, IUserAllShort, IVipFolder } from "../../api";
import { IAdminVipState } from "./types";

const initialState: IAdminVipState = {
  folderContent: {
    isLoading: false,
    clients: [],
    pagination: null,
    sort: null,
  },
  userClients: {
    isLoading: false,
    clients: [],
    pagination: null,
    sort: null,
  },
  managers: [],
  folders: [],
};

export const adminVipSlice = createSlice({
  name: "adminVipSlice",
  initialState,
  reducers: {
    loadingFolderContent: (state: IAdminVipState) => {
      return {
        ...state,
        folderContent: {
          isLoading: true,
          clients: [],
          pagination: null,
          sort: null,
        },
      };
    },

    setUserClients: (
      state: IAdminVipState,
      action: PayloadAction<IAdminVipPage>
    ) => {
      return {
        ...state,
        userClients: {
          isLoading: false,
          clients: action.payload.data,
          pagination: action.payload.pagination,
          sort: null,
        },
      };
    },

    setFolderContent: (
      state: IAdminVipState,
      action: PayloadAction<IAdminVipPage>
    ) => {
      return {
        ...state,
        folderContent: {
          isLoading: false,
          clients: action.payload.data,
          pagination: action.payload.pagination,
          sort: null,
        },
      };
    },

    loadingUserClients: (state: IAdminVipState) => {
      return {
        ...state,
        userClients: {
          isLoading: true,
          clients: [],
          pagination: null,
          sort: null,
        },
      };
    },

    successManagerList: (
      state: IAdminVipState,
      action: PayloadAction<IUserAllShort[]>
    ) => {
      return {
        ...state,
        isLoading: false,
        managers: action.payload,
      };
    },

    addVipFolder: (
      state: IAdminVipState,
      action: PayloadAction<{ name: string }>
    ) => {
      return {
        ...state,
        // folders: [action.payload.name, ...state.folders],
      };
    },

    successVipFolder: (
      state: IAdminVipState,
      action: PayloadAction<IVipFolder[]>
    ) => {
      return {
        ...state,
        folders: action.payload,
      };
    },
  },
});

export const { actions, reducer } = adminVipSlice;
