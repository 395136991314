export enum OrderStatuses {
  DRAFT_0 = 0,
  WAIT_DATE_RESERVE_1 = 1,
  WAIT_RESERVE_2 = 2,
  WAIT_CONFIRM_3 = 3,
  WAIT_PRE_PAY_4 = 4,
  ON_CHECK_5 = 5,
  ON_PACK_6 = 6,
  SENDED_7 = 7,
  AT_POST_8 = 8,
  WAITING_PAYMENT_9 = 9,
  COMPLETED_10 = 10,
  RETURN_14 = 14,
  CANCEL_11 = 11,
}

export enum OrderStatusesRu {
  "Черновик" = 0,
  "В ожидании даты" = 1,
  "Забронирован" = 2,
  "Ждет подтверждения" = 3,
  WAIT_PRE_PAY = 4,
  "На проверке" = 5,
  "На комплектации" = 6,
  "Отправлен" = 7,
  "На почте" = 8,
  "Ожидание оплаты" = 9,
  "Исполнен" = 10,
  "Возврат" = 14,
  "Отменен" = 11,
}
