import { UserActionTypes, UserState, ACTION } from "./types";

const initialState: UserState = {
  auth: null,
  current: null,
  isLoading: false,
  isSending: false,
  isError: false,
};

export function userReducer(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case ACTION.FETCH:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case ACTION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        current: action.payload,
        isError: false,
      };

    case ACTION.USER_SET_CURRENT_USER_DATA:
      return {
        ...state,
        auth: action.payload,
      };
    case ACTION.USER_CHANGE_FETCH:
      return {
        ...state,
        isSending: true,
        isError: false,
      };
    case ACTION.USER_CHANGE_ERROR:
      return {
        ...state,
        isSending: false,
        isError: true,
      };
    default:
      return state;
  }
}
