import React from "react";
import { format } from "date-fns";
import { differenceInDays } from "date-fns";
import { FiPhoneIncoming, FiPhoneOutgoing } from "react-icons/fi";

import { CdrType } from "../../../api";

import "./cdr.scss";

interface Props {
  calls: CdrType[];
}

const showDuration = (duration: number) => {
  if (duration === 0) return "-";
  if (duration < 60) return `${duration} сек`;
  const min = Math.floor(duration / 60);
  const sec = duration % 60;
  return `${min} мин ${sec} сек`;
};

const dateNow = new Date();

export const CallListShort: React.FunctionComponent<Props> = ({ calls }) => {
  return (
    <div className="scrolled">
      <table className="table table-sm">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Дата </th>
            <th scope="col">Время </th>

            <th scope="col">Имя менеджера</th>

            <th scope="col" className="text-center">
              Разговор
            </th>
          </tr>
        </thead>
        <tbody>
          {calls.map((call) => (
            <tr key={call.id}>
              <td className="text-center">
                {call.direction ? (
                  <div className="call-outgoing">
                    <FiPhoneOutgoing />
                  </div>
                ) : (
                  <div className="call-incoming">
                    <FiPhoneIncoming />
                  </div>
                )}
              </td>
              <td>
                {differenceInDays(dateNow, new Date(call.end_stamp))} дней назад
              </td>
              <td>{format(new Date(call.end_stamp), "HH:mm ")}</td>

              <td>{call.direction ? call.userName : call.destination_name}</td>

              <td className="text-center">{showDuration(call.billsec)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
