import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { GoSearch } from "react-icons/go";
import { BsCollection } from "react-icons/bs";
import {
  IProductSet,
  IProductSetCategory,
  IProductSetListRequest,
} from "../../api";
import { PaginationType } from "../../api/common-types/pagination.types";
import { Pagination } from "../../components/common/pagination/pagination";
import { Loading } from "../../components/common/loading/Loading";
import { Modal } from "../../components";
import { ProductSet } from "./components/ProductSet";
import { ModalView } from "./components/ModalView";
import "./ProductSet.scss";

interface IPageProps extends RouteComponentProps {
  packages: IProductSet[];
  categories: IProductSetCategory[];
  pagination: PaginationType | null;
  isLoading: boolean;
  isLoadingCategories: boolean;
  fetchPackets: (request?: IProductSetListRequest) => void;
  fetchPacketCategories: () => void;
  addPacket: (packet: IProductSet) => void;
}

export const ProductSetPage: React.FC<IPageProps> = ({
  isLoading,
  isLoadingCategories,
  packages,
  categories,
  pagination,
  addPacket,
  fetchPackets,
  fetchPacketCategories,
}) => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState(search);
  const [viewProduct, setViewProduct] = useState<IProductSet | null>(null);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    fetchPackets({ search });
    fetchPacketCategories();
  }, []);

  const handleChangePage = (page: number) => {
    fetchPackets({ search, page, category });
  };

  const handleSearch = (search: string) => {
    setSearch(search);
    fetchPackets({ search, category });
  };

  const handleChangeCategory = (category: number | null) => {
    setCategory(category);
    fetchPackets({ search, category, page: 1 });
  };

  return (
    <div className="page-with-sidebar">
      <div className="row ">
        <div className="col-sidebar ">
          <div className="sidebar-fixed">
            <div className="card-body">
              <ul className="nav flex-column ">
                <li
                  className={`nav-item ${
                    category === null ? " item-active" : ""
                  }`}
                >
                  <div className="d-flex justify-content-between">
                    <button
                      className="nav-link btn btn-link"
                      onClick={() => handleChangeCategory(null)}
                    >
                      <span>
                        <BsCollection /> ВСЕ КАТЕГОРИИ
                      </span>
                    </button>
                  </div>
                </li>
                {categories.map((item) => (
                  <li
                    className={`nav-item ${
                      category === item.id ? " item-active" : ""
                    }`}
                  >
                    <div className="d-flex justify-content-between">
                      <button
                        className="nav-link btn btn-link"
                        onClick={() => handleChangeCategory(item.id)}
                      >
                        <span>
                          <BsCollection /> {item.name}
                        </span>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="wrapperPageHeader">
            <div className="input-group col-41">
              <input
                type="text"
                className="form-control"
                placeholder="Введите название или артикул"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(searchValue);
                  }
                }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => handleSearch(searchValue)}
                >
                  <i className="icon-user icon-white"></i>
                  Поиск
                </button>
              </div>
            </div>
          </div>

          {isLoading ? (
            <Loading />
          ) : packages.length === 0 ? (
            <div className="text-center mt-5">
              <h2>
                <GoSearch />{" "}
                {search.length > 0
                  ? "По вашему запросу ничего не нашлось"
                  : "Введите название"}
              </h2>
            </div>
          ) : (
            packages.map((packet) => (
              <ProductSet
                packet={packet}
                addPacket={addPacket}
                showProduct={(product, index) => {
                  setViewProduct(product);
                  setIndex(index);
                }}
              />
            ))
          )}

          {pagination && +pagination.totalPages > 1 && (
            <Pagination
              pagination={pagination}
              onChangePage={handleChangePage}
            />
          )}
        </div>
      </div>
      {viewProduct && (
        <Modal isModalOpen={!!viewProduct} onClose={() => setViewProduct(null)}>
          <ModalView
            productSet={viewProduct}
            index={index}
            changeIndex={setIndex}
          />
        </Modal>
      )}
    </div>
  );
};
