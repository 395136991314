import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import OrdersPage from "./OrdersPage";
import { RootState } from "../../store";
import {
  fetchOrders,
  setOrderParams,
  updateComment,
  getCallsToday,
  getCalls,
  unBlock,
} from "../../store/order/actions";
import { openClientFromOrder } from "../../store/phone/actions";
import { fetchOrderEvent } from "../../store/events/actions";

const mapStateToProps = (state: RootState) => ({
  ...state.events,
  ...state.order,
  // cdr: state.cdr.cdr,
  // isCdrLoading: state.cdr.isLoading,
  // students: state.mentee.users,
  hasMentorRight: !!state.user.current?.hasMentorRight,
  currentUserId: state.user.current?.id,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      fetchOrders,
      openClientFromOrder,
      fetchOrderEvent,
      setOrderParams,
      updateComment,
      getCallsToday,
      getCalls,
      unBlock,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
