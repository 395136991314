import { request as requestFunction, HttpMethod } from "../request";
import qs from "query-string";

const getData = <Response>(value: any) => value.data as Response;
const send = <Request, Response>(
  url: string,
  request?: Request
): Promise<Response> => {
  const fullUrl = request
    ? `${url}?${qs.stringify({ ...request })}`
    : url;

  return requestFunction({ url: fullUrl }).then((response) =>
    getData<Response>(response)
  );
};

export class APIService {
  public static get<Response>(url: string): Promise<Response>;
  public static get<Request, Response>(
    url: string,
    request?: Request
  ): Promise<Response>;

  public static get<Request, Response>(
    url: string,
    request?: Request
  ): Promise<Response> {
    return send<Request, Response>(url, request);
  }

  public static post<Request = void, Response = void>(
    url: string,
    request?: Request
  ): Promise<Response> {
    return requestFunction({
      url,
      method: HttpMethod.POST,
      data: request,
    }).then((response) => getData<Response>(response));
  }

  public static patch<Request = void, Response = void>(
    url: string,
    request?: Request
  ): Promise<Response> {
    return requestFunction({
      url,
      method: HttpMethod.PATCH,
      data: request,
    }).then((response) => getData<Response>(response));
  }

  public static put<Request = void, Response = void>(
    url: string,
    request?: Request
  ): Promise<Response> {
    return requestFunction({
      url,
      method: HttpMethod.PUT,
      data: request,
    }).then((response) => getData<Response>(response));
  }

  public static delete<Request = void, Response = void>(
    url: string,
    request?: Request
  ): Promise<Response> {
    return requestFunction({
      url,
      method: HttpMethod.DELETE,
      data: request,
    }).then((response) => getData<Response>(response));
  }
}
