import { IProductSetListRequest, ProductSetAPI } from "../../api";
import { toastError } from "../../services/toast/toast";
import { actions } from "./slice";
const { loading, successCategory, successProducts, error } = actions;

export const fetchPackets =
  (request?: IProductSetListRequest) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response = await ProductSetAPI.getAll(request);
      dispatch(successProducts(response));
    } catch (e) {
      dispatch(error());
      toastError(`Ошибка загрузки`);
    }
  };

export const fetchPacketCategories = () => async (dispatch: any) => {
  try {
    const response = await ProductSetAPI.getCategory();
    dispatch(successCategory(response));
  } catch (e) {
    toastError(`Ошибка загрузки`);
  }
};
