import { IEvent } from "../../api";

export enum ACTION {
  EVENT_FETCH = "EVENT_FETCH",
  EVENT_SUCCESS = "EVENT_SUCCESS",
  EVENT_ERROR = "EVENT_ERROR",

  EVENT_ORDER_FETCH = "EVENT_ORDER_FETCH",
  EVENT_ORDER_SUCCESS = "EVENT_ORDER_SUCCESS",
  EVENT_ORDER_ERROR = "EVENT_ORDER_ERROR",

  // TODO:
  EVENT_TOGGLE_VISIBILITY = "EVENT_TOGGLE_VISIBILITY",

  EVENT_FETCH_PREV_MESSAGES = "EVENT_FETCH_PREV_MESSAGES",
  EVENT_SUCCESS_PREV_MESSAGES = "EVENT_SUCCESS_PREV_MESSAGES",
  EVENT_ERROR_PREV_MESSAGES = "EVENT_ERROR_PREV_MESSAGES",

  EVENT_SEND_MESSAGE = "EVENT_SEND_MESSAGE",
  EVENT_RECEIVE_MESSAGE = "EVENT_RECEIVE_MESSAGE",

  EVENT_ACTION_SUCCESS = "EVENT_ACTION_SUCCESS",
  EVENT_ACTION_ERROR = "EVENT_ACTION_ERROR",

  GLOBAL_CHANGE_URL = "GLOBAL_CHANGE_URL",
}

interface IEventFetchAction {
  type:
    | ACTION.EVENT_FETCH
    | ACTION.EVENT_ORDER_FETCH
    | ACTION.EVENT_FETCH_PREV_MESSAGES;
}

interface IEventErrorAction {
  type: ACTION.EVENT_ERROR | ACTION.EVENT_ORDER_ERROR;
}

interface IEventToggleVisibilityAction {
  type: ACTION.EVENT_TOGGLE_VISIBILITY;
}

interface IEventSuccessAction {
  type:
    | ACTION.EVENT_SUCCESS
    | ACTION.EVENT_ORDER_SUCCESS
    | ACTION.EVENT_SUCCESS_PREV_MESSAGES;
  payload: IEvent[];
}

interface IChangeUrlAction {
  type: ACTION.GLOBAL_CHANGE_URL;
}

export type EventActionType =
  | IEventFetchAction
  | IEventErrorAction
  | IEventSuccessAction
  | IEventToggleVisibilityAction
  | IChangeUrlAction;

export interface IEventState {
  isLoading: boolean;
  isLoadingOrderEvents: boolean;
  isVisible: boolean;
  error: string;
  countNew: number;
  events: IEvent[];
  orderEvents: IEvent[];
}
