import React from "react";
import { fromUnixTime, format } from "date-fns";
import ru from "date-fns/locale/ru";
import { IEvent } from "../../../api";
import "./events.scss";

interface Props {
  isLoading: boolean;
  events: IEvent[];
}

// const dateNow = new Date();

export const EventsList: React.FC<Props> = ({ isLoading, events }) => {
  return (
    <div className="sticky-modal__body-scroll">
      <div className="card-header">История последних событий</div>
      <div className="card-body event-body">
        {/* Сегодня <a href="#">Вчера</a> <a href="#">Позавчера</a> */}
        {isLoading ? (
          "...."
        ) : (
          <table className="table table-sm">
            <thead>
              <tr>
                <th className="wi-100 text-center">Заказ</th>
                <th>Пользователь</th>

                <th>Событие</th>
                <th className="wi-150 text-center">Дата и время</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event.id}>
                  <td className="text-center">
                    <a href="#">{event.orderId}</a>
                  </td>
                  <td>{event.userName}</td>

                  <td>{event.event}</td>
                  <td className="text-center">
                    {format(fromUnixTime(+event.date), "dd MMMM HH:mm", {
                      locale: ru,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
