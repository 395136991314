import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CdrType, IIncome } from "../../api";
import { ICdrState } from "./types";

const initialState: ICdrState = {
  isLoading: false,
  isError: false,
  cdr: [],
  income: [],
  incomeCount: 0,
};

export const cdrSlice = createSlice({
  name: "cdr",
  initialState,
  reducers: {
    loading: (state: ICdrState) => {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
    success: (state: ICdrState, action: PayloadAction<CdrType[]>) => {
      return {
        ...state,
        isLoading: false,
        cdr: action.payload,
      };
    },
    error: (state: ICdrState) => {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    incomeSuccess: (state: ICdrState, action: PayloadAction<IIncome[]>) => {
      return {
        ...state,
        isLoading: false,
        income: action.payload,
        incomeCount: action.payload.length,
      };
    },
    cdrCountSuccess: (state: ICdrState, action: PayloadAction<number>) => {
      return {
        ...state,
        incomeCount: action.payload,
      };
    },
    newIncomeCall: (state: ICdrState) => {
      return {
        ...state,
        incomeCount: state.incomeCount + 1,
      };
    },
    clientUpdateStatusSuccess: (
      state: ICdrState,
      action: PayloadAction<IIncome>
    ) => {
      const newIncomes = state.income.filter(
        (client) => client.clientId !== action.payload.clientId
      );

      return {
        ...state,
        income: newIncomes,
        incomeCount: newIncomes.length,
      };
    },
  },
});

export const { actions, reducer } = cdrSlice;
