import { useField, FieldHookConfig } from "formik";

export const InputWithLabel = (
  props: FieldHookConfig<string> & {
    label: string;
  }
) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-3 row">
      <label className="col-sm-3 col-form-label">{props.label}</label>

      <div className="col-sm-9">
        <input
          {...field}
          type={props.type || "text"}
          placeholder={props.label}
          className={meta.error ? "form-control is-invalid" : "form-control"}
          disabled={props.disabled}
          autoComplete="nope"
        />
        {meta.touched && meta.error && (
          <div className="invalid-feedback">{meta.error}</div>
        )}
      </div>
    </div>
  );
};
