import React from "react";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { bindActionCreators } from "redux";

import { SideMenu } from "../components/side-menu/side-menu";
import { Header } from "../components/header/Header";
import { goToAuthorize } from "..";
import { clearOrderEditor } from "../store/order/actions";
import { toggleChatVisibility } from "../store/chat/actions";
import { togglePhoneVisibility } from "../store/phone/actions";
import { toggleEventsVisibility } from "../store/events/actions";
import { toggleScriptVisibility } from "../store/script/actions";

class MenuContainer extends React.Component<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>,
  {}
> {
  public handleLogout = () => {
    localStorage.removeItem("JWT");
    goToAuthorize();
  };

  public async componentDidMount() {}

  public handleTogglePhone = () => {
    const {
      togglePhoneVisibility,
      toggleChatVisibility,
      toggleEventsVisibility,
      toggleScriptVisibility,
      isEventsVisible,
      isChatVisible,
      isScriptVisible,
    } = this.props;
    if (isChatVisible) toggleChatVisibility();
    if (isEventsVisible) toggleEventsVisibility();
    if (isScriptVisible) toggleScriptVisibility();
    togglePhoneVisibility();
  };

  public handleToggleScript = () => {
    const {
      togglePhoneVisibility,
      toggleChatVisibility,
      toggleEventsVisibility,
      toggleScriptVisibility,
      isEventsVisible,
      isChatVisible,
      isPhoneVisible,
    } = this.props;
    if (isChatVisible) toggleChatVisibility();
    if (isEventsVisible) toggleEventsVisibility();
    if (isPhoneVisible) togglePhoneVisibility();
    toggleScriptVisibility();
  };

  public handleToggleChat = () => {
    const {
      togglePhoneVisibility,
      toggleChatVisibility,
      toggleEventsVisibility,
      toggleScriptVisibility,
      isPhoneVisible,
      isEventsVisible,
      isScriptVisible,
    } = this.props;
    if (isPhoneVisible) togglePhoneVisibility();
    if (isEventsVisible) toggleEventsVisibility();
    if (isScriptVisible) toggleScriptVisibility();
    toggleChatVisibility();
  };

  public handleToggleEvents = () => {
    const {
      togglePhoneVisibility,
      toggleChatVisibility,
      toggleEventsVisibility,
      toggleScriptVisibility,
      isPhoneVisible,
      isChatVisible,
      isScriptVisible,
    } = this.props;
    if (isPhoneVisible) togglePhoneVisibility();
    if (isChatVisible) toggleChatVisibility();
    if (isScriptVisible) toggleScriptVisibility();
    toggleEventsVisibility();
  };

  public render() {
    const {
      isPhoneVisible,
      isChatVisible,
      isEventsVisible,
      isScriptVisible,
      profile,
      editableOrder,
      clearOrderEditor,
      contentCount,
      callBackActiveCount,
      blockedStatus,
      countNewInformation,
      countIncome,
    } = this.props;

    if (!profile) return null;
    return (
      <>
        <Header
          isPhoneVisible={isPhoneVisible}
          isChatVisible={isChatVisible}
          isEventsVisible={isEventsVisible}
          isScriptVisible={isScriptVisible}
          editableOrder={editableOrder}
          contentCount={contentCount}
          clearOrderEditor={clearOrderEditor}
          togglePhoneVisibility={this.handleTogglePhone}
          toggleChatVisibility={this.handleToggleChat}
          toggleEventsVisibility={this.handleToggleEvents}
          toggleScriptVisibility={this.handleToggleScript}
          onLogout={this.handleLogout}
          profile={profile}
          callBackActiveCount={callBackActiveCount}
          blockedStatus={blockedStatus}
          countNewInformation={countNewInformation}
          countIncome={countIncome}
          // hasMentorRight={user.current?.hasMentorRight || false}
        />
        <SideMenu profile={profile} />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isPhoneVisible: state.phone.isVisible,
  isChatVisible: state.chat.isVisible,
  isEventsVisible: state.events.isVisible,
  isScriptVisible: state.script.isVisible,
  profile: state.user.current,
  callBackActiveCount: state.clients.callBackActiveCount,
  editableOrder: state.order.orderEditor,
  contentCount: state.order.orderEditor.content.reduce(
    (acc, content) => acc + content.count,
    0
  ),
  blockedStatus: state.order.blockedStatus,
  countNewInformation: state.information.countNew,
  countIncome: state.cdr.incomeCount,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      togglePhoneVisibility,
      toggleChatVisibility,
      toggleEventsVisibility,
      toggleScriptVisibility,
      clearOrderEditor,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
