import { PaginationType } from "../../api/common-types/pagination.types";
import { InformationPageType, InformationType } from "../../api";

export enum ACTION {
  INFORMATION_FETCH = "INFORMATION_FETCH",
  INFORMATION_SUCCESS = "INFORMATION_SUCCESS",
  INFORMATION_COUNT_NEW = "INFORMATION_COUNT_NEW",
  INFORMATION_ERROR = "INFORMATION_ERROR",
  INFORMATION_CONFIRM_SEND = "INFORMATION_CONFIRM_SEND",
  INFORMATION_CONFIRM_SEND_SUCCESS = "INFORMATION_CONFIRM_SEND_SUCCESS",
  INFORMATION_CONFIRM_SEND_ERROR = "INFORMATION_CONFIRM_SEND_ERROR",
  NEW_INFORMATION = "NEW_INFORMATION",
}

interface IInformationFetchAction {
  type: ACTION.INFORMATION_FETCH | ACTION.INFORMATION_CONFIRM_SEND;
}

interface IInformationErrorAction {
  type: ACTION.INFORMATION_ERROR | ACTION.INFORMATION_CONFIRM_SEND_ERROR;
}

interface IInformationSuccessAction {
  type: ACTION.INFORMATION_SUCCESS;
  payload: InformationPageType;
}

interface IInformationSuccessCountNewAction {
  type: ACTION.INFORMATION_COUNT_NEW;
  payload: number;
}

interface IInformationSuccessSendAction {
  type: ACTION.INFORMATION_CONFIRM_SEND_SUCCESS;
  payload: number;
}

interface INewInformationAction {
  type: ACTION.NEW_INFORMATION;
  payload: InformationType;
}

export type IInformationActionTypes =
  | IInformationFetchAction
  | IInformationErrorAction
  | IInformationSuccessAction
  | IInformationSuccessCountNewAction
  | IInformationSuccessSendAction
  | INewInformationAction;

export interface IInformationState {
  isLoading: boolean;
  error: string;
  information: InformationType[];
  pagination: PaginationType | null;
  countNew: number;
}
