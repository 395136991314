export enum SipStatusType {
  "sipStatus/DISCONNECTED" = "Отлючено",
  "sipStatus/CONNECTING" = "Соединение...",
  "sipStatus/CONNECTED" = "Подключено*",
  "sipStatus/REGISTERED" = "Подключено",
  "sipStatus/ERROR" = "Ошибка подключения",
}

export enum CallStatusType {
  "callStatus/IDLE" = "",
  "callStatus/STARTING" = "Набор номера ...",
  "callStatus/ACTIVE" = "Соедиение установлено",
  "callStatus/STOPPING" = "Разъединение...",
}

export enum CallDirectionType {
  "callDirection/INCOMING",
  "callDirection/OUTGOING",
}

export interface SipType {
  status: keyof typeof SipStatusType;
}

export interface CallType {
  id: string;
  status: keyof typeof CallStatusType;
  direction: keyof typeof CallDirectionType;
}

export interface SipContextType {
  sip: SipType;
  call: CallType;
  registerSip: () => void;
  unregisterSip: () => void;
  answerCall: () => void;
  startCall: (phone: string) => void;
  stopCall: () => void;
}
