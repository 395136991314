import { CdrAPI, ICdrRequest, IIncome } from "../../api";
import { toastError, toastSuccess } from "../../services/toast/toast";
import { actions } from "./slice";
const {
  loading,
  success,
  error,
  cdrCountSuccess,
  // clientUpdateStatusSuccess,
  incomeSuccess,
  newIncomeCall,
} = actions;

export const fetchCdr = (request: ICdrRequest) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response = await CdrAPI.getLast(request);
    dispatch(success(response));
  } catch (e) {
    dispatch(error());
    toastError(`Ошибка загрузки`);
  }
};

export const fetchIncome = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response = await CdrAPI.getIncome();
    dispatch(incomeSuccess(response));
  } catch (e) {
    dispatch(error());
    toastError(`Ошибка загрузки`);
  }
};

export const fetchCountIncome = () => async (dispatch: any) => {
  try {
    const response = await CdrAPI.getCountIncome();
    dispatch(cdrCountSuccess(response));
  } catch (error) {
    toastError(`Ошибка получения количества входящих`);
  }
};

export const addIncome = (data: IIncome) => async (dispatch: any) => {
  dispatch(newIncomeCall());
};

export const clearIncome = () => async (dispatch: any) => {
  try {
    await CdrAPI.clearIncome();
    toastSuccess("Операция завершена успешно");
    dispatch(fetchIncome());
  } catch (e) {
    toastError(`Ошибка при выполнении операции`);
  }
};
