import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductSetCategory, IProductSetPage } from "../../api/";
import { IPacketState } from "./types";

const initialState: IPacketState = {
  isLoading: false,
  isLoadingCategories: false,
  error: "",
  packages: [],
  categories: [],
  pagination: null,
};

export const productSetSlice = createSlice({
  name: "productSet",
  initialState,
  reducers: {
    loading: (state: IPacketState) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    successCategory: (
      state: IPacketState,
      action: PayloadAction<IProductSetCategory[]>
    ) => {
      return {
        ...state,
        categories: action.payload,
      };
    },
    successProducts: (
      state: IPacketState,
      action: PayloadAction<IProductSetPage>
    ) => {
      return {
        ...state,
        isLoading: false,
        packages: action.payload.data,
        pagination: action.payload.pagination,
      };
    },
    error: (state: IPacketState) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const { actions, reducer } = productSetSlice;
