import { PhoneState, PhoneActionTypes, ACTION } from "./types";

const initialState: PhoneState = {
  isVisible: false,
  isKeyboard: false,
  isSearching: false,
  isClientAvailable: false,
  isCalling: false,
  isSipAvailable: false,
  search: "",
  client: null,
  clientId: null,
  clientProducts: [],
  calls: [],
  errorMessage: "",
};

export function phoneReducer(
  state = initialState,
  action: PhoneActionTypes
): PhoneState {
  switch (action.type) {
    case ACTION.GLOBAL_CHANGE_URL:
      return {
        ...state,
        isVisible: false,
      };
    case ACTION.TOGGLE_VISIBLE:
      return {
        ...state,
        isVisible: !state.isVisible,
      };

    case ACTION.CHANGE_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    case ACTION.OPEN_CLIENT:
      return {
        ...state,
        isVisible: true,
        search: `&${action.payload.clientId}`,
        //  client: action.payload,
        clientId: action.payload.clientId,
        isSearching: true,
        isClientAvailable: false,
      };

    case ACTION.SEARCH_CLIENT_DATA:
      return {
        ...state,
        isVisible: true,
        isSearching: true,
        client: null,
        calls: [],
        isClientAvailable: false,
      };

    case ACTION.SET_CLIENT_DATA:
      return {
        ...state,
        isSearching: false,
        isClientAvailable: action.payload.isClientAvailable,
        errorMessage: action.payload.errorMessage,
        clientProducts: action.payload.clientProducts,
        client: action.payload.client,
        calls: action.payload.calls,
      };

    default:
      return state;
  }
}
