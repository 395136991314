import { IEvent } from "./event.types";
import { APIService } from "../../services/api-service";

export const getByDate = () => {
  const url = "api/v1/event/date";
  return APIService.get<IEvent[]>(url);
};

export const getByOrder = (orderId: number) => {
  const url = `api/v1/event/order/${orderId}`;
  return APIService.get<IEvent[]>(url);
};
