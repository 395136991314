import React from "react";
import { IOrderContentEditor, IProduct } from "../../../api";
import { SERVER_URL } from "../../../env";
import "./product.scss";

interface Props {
  product: IProduct;
  addOrderContent: (content: IOrderContentEditor) => void;
  onClick: () => void;
}

const getClass = (count: number, group: string): string => {
  if (count === 0) return "fx-article fx-article__disable";
  if (group === "5" || group === "2") return "fx-article fx-article__5";
  return "fx-article fx-article__active";
};
export const ProductsRow: React.FunctionComponent<Props> = ({
  product,
  onClick,
  addOrderContent,
}) => {
  return (
    <div className="card mb-2">
      <div className="card-body">
        <div className="fx">
          <div className="fx-im">
            <img
              src={SERVER_URL + product.image_default_path}
              alt="..."
              onClick={onClick}
              className="hover"
            />
          </div>
          <div className="fx-all">
            <h5>{product.name}</h5>
            <div className="fx-wr">
              {product.articles.map((article) => (
                <div
                  className={getClass(
                    article.count,
                    article.article.split("-")[0]
                  )}
                >
                  <div className="text-right">
                    <b>{article.price} руб.</b>
                  </div>

                  {article.article}

                  <div className="">
                    {article.count > 0 ? (
                      // `${article.count} шт`
                      <></>
                    ) : (
                      <span className="">нет в наличии</span>
                    )}
                  </div>

                  <div className="text-center">
                    {(article.count > 0 || article.preOrder) && (
                      <button
                        type="button"
                        className={
                          article.count > 0
                            ? "btn btn-link btn-sm"
                            : "btn btn-link btn-sm"
                        }
                        onClick={() =>
                          addOrderContent({
                            article: article.article,
                            price: article.price,
                            count: 1,
                            productName: product.name,
                            productId: product.id,
                            contentId: "",
                          })
                        }
                      >
                        Добавить в заказ
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="card-footer" style={{ background: "#fdfdfd" }}>
        <a href="#" onClick={onClick}>
          {product.name}
        </a>
      </div> */}
    </div>
  );
};
