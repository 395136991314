export interface UserType {
  id: number;
  group: string;
  name: string;
  sipNumber: string;
  sipPassword: string;
  hasAccessDismissed: boolean;
  hasMentorRight: boolean;
  accessLevel: boolean;
  exp: number;
  iat: number;
}

export enum LevelAccess {
  "Обучающий" = 1,
  "Базовый",
  "Стандарт",
  "Премиум",
}

export interface IUserProfile {
  id: number;
  userName: string;
  email: string;
  role: string;
  phone: string;
  address: string;
  hasAccessDismissed: boolean;
  hasMentorRight: boolean;
  accessLevel: number;
  mentorUserName: string | null;
}

export interface IChangePassword {
  old: string;
  new: string;
}

export interface IMentee {
  id: number;
  name: string;
  status: number;
  phone: string;
  sipNumber: string;
  email: string;
  lastVisit: string;
  accessLevel: number;
  address: string | null;
  birthdate: string | null;
  education: string | null;
  comment: string | null;
}

export interface IMenteeRequest {
  id?: number;
  name: string;
  phone: string;
  email: string;
  accessLevel: number;
  address: string | null;
  birthdate: string | null;
  education: string | null;
  comment: string | null;
}

export interface IUserShort {
  id: number;
  userName: string;
  officeId: number;
}

export interface IUserAllShort {
  id: number;
  userName: string;
  officeId: number;
  isActive: boolean;
}
