import React from "react";
import { IChatMessage } from "../../../api";
import { format, differenceInDays } from "date-fns";

import "./chat.scss";

interface Props {
  isLoading: boolean;
  messages: IChatMessage[] | null;
  currentUserId: number;
}

export const MessageList: React.FC<Props> = ({
  isLoading,
  messages,
  currentUserId,
}) => {
  if (isLoading) return <>Loading..</>;

  const dateNow = new Date();
  if (!messages || !messages.length) return <>НЕТ СООБЩЕНИЙ</>;

  return (
    <>
      {messages.map((message) => {
        const date = new Date(message.date);
        const dateDiff = differenceInDays(dateNow, date);
        return (
          <div
            key={message.id}
            className={
              message.user_from === currentUserId
                ? "chat-message__wrapper chat-message__outcome"
                : "chat-message__wrapper chat-message__income"
            }
          >
            <div className="chat-message__header">
              {message.user_from_name}
              <div className="chat-date">
                {dateDiff ? `${dateDiff} дней назад` : "Cегодня"}
                {` в ${format(date, "HH:mm")}`}
              </div>
              <div className="read">{message.read ? "прочитано" : ""}</div>
            </div>

            {message.message}
          </div>
        );
      })}
    </>
  );
};
