import { RouteComponentProps } from "react-router";
import {
  CdrType,
  CountStatusType,
  IBlockedStatus,
  IEvent,
  IOrder,
  IOrderListRequest,
} from "../../api";
import { PaginationType } from "../../api/common-types/pagination.types";
import {
  IOrdersSetParams,
  IOrdersUpdateComment,
} from "../../store/order/types";

export interface IOrdersPageProps extends RouteComponentProps {
  orders: IOrder[];
  pagination: PaginationType | null;
  countStatus: CountStatusType[];
  blockedStatus: IBlockedStatus;
  isLoading: boolean;
  isSending: boolean;
  isLoadingOrderEvents: boolean;
  orderEvents: IEvent[];
  lastEditedOrderId: number | null;
  isLoadingCallsToday: boolean;
  callsToday: CdrType[];
  hasMentorRight: boolean;
  currentUserId?: number;

  fetchOrders: (request: IOrderListRequest) => void;
  fetchOrderEvent: (orderId: number) => void;
  openClientFromOrder: (orderId: number) => void;
  setOrderParams: (request: IOrdersSetParams) => void;
  updateComment: (request: IOrdersUpdateComment) => void;
  getCallsToday: (orderId: number) => void;
  getCalls: (orderId: number) => void;
  unBlock: (orderId: number) => void;
}

export enum OrderOperations {
  LISTEN,
  HISTORY,
  CANCEL,
  DRAFT,
}
