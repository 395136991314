import React, { useEffect, useRef } from "react";

import { IChatUserWithMessages } from "../../../store/chat/types";
import { MessageList } from "./messageList";

interface Props {
  usersWithMessages: IChatUserWithMessages[];
  currentUser: number;
  isUsersLoading: boolean;
  isMessagesLoading: boolean;
  userIdsOnline: number[];
  message: string;
  isConnected: boolean;
  currentUserId: number;
  onSelectUser: (userId: number) => void;
  onSendMessage: (message: string) => void;
  setMessage: (message: string) => void;
}

export const Chat: React.FC<Props> = ({
  currentUser,
  isUsersLoading,
  isMessagesLoading,
  usersWithMessages,
  userIdsOnline,
  message,
  isConnected,
  currentUserId,
  onSelectUser,
  onSendMessage,
  setMessage,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };
  const messages = usersWithMessages.find(
    (user) => user.userId === currentUser
  )?.messages;

  useEffect(scrollToBottom, [messages]);

  if (isUsersLoading) return <>Loading... </>;

  return (
    <div className="chat__wrapper">
      <div className="chat__users sticky-modal__body-scroll">
        <div className="card-body">
          {usersWithMessages.map((user) => (
            <div
              key={user.userId}
              className={
                user.userId === currentUser ? "user active-user" : "user"
              }
            >
              <span className="status-wrap">
                {userIdsOnline.includes(user.userId) && (
                  <div className="status-online"></div>
                )}
              </span>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onSelectUser(user.userId);
                }}
                key={user.userId}
                className="status-user-name"
              >
                {user.userName}
              </a>
              {user.countUnread > 0 && (
                <span className="status-unread"> {user.countUnread}</span>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="chat__messages">
        <div className="card-header">
          <div className=" d-flex justify-content-md-between">
            <div className="pl-2">История сообщений</div>
            <div className="pr-2">
              {isConnected ? "Подключено" : "Подключение..."}
            </div>
          </div>
        </div>
        <div className="chat__messages-box sticky-modal__body-scroll card-body">
          <MessageList
            messages={messages || []}
            currentUserId={currentUserId}
            isLoading={isMessagesLoading}
          />
          <div ref={messagesEndRef} />
        </div>
        <div className="chat__write-box">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Ваше сообщение"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSendMessage(message);
                }
              }}
              maxLength={300}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => onSendMessage(message)}
              >
                Отправить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
