import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { Action } from "redux";
import { ACTION, PhoneActionTypes } from "./types";
import { IClient, ClientAPI } from "../../api";

export function togglePhoneVisibility(): PhoneActionTypes {
  return {
    type: ACTION.TOGGLE_VISIBLE,
  };
}

export function changeSearch(search: string): PhoneActionTypes {
  return {
    type: ACTION.CHANGE_SEARCH,
    payload: search,
  };
}

export const openClient =
  (client: IClient): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ACTION.OPEN_CLIENT,
      payload: client,
    });
    dispatch(searchClient(`&${client.clientId}`));
  };

export const openClientFromOrder =
  (orderId: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(changeSearch(`*${orderId}`));
    dispatch(searchClient(`*${orderId}`));
  };

export const openClientFromCdr =
  (cdrId: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(changeSearch(`!${cdrId}`));
    dispatch(searchClient(`!${cdrId}`));
  };

export const openClientWithSearch =
  (clientId: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(changeSearch(`&${clientId}`));
    dispatch(searchClient(`&${clientId}`));
  };

export const searchClient =
  (search: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ACTION.SEARCH_CLIENT_DATA,
    });

    const clientData = await ClientAPI.searchDataClient(search);
    dispatch({
      type: ACTION.SET_CLIENT_DATA,
      payload: clientData,
    });
  };
