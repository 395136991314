import * as React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../router/helper";
import "./login.scss";

interface Props {
  email: string;
  password: string;
  errorMessage: string;
  isSending: boolean;
  onChangeEmail: (email: string) => void;
  onChangePassword: (password: string) => void;
  onLogin: () => void;
}

export const Login: React.FunctionComponent<Props> = (props) => {
  const {
    email,
    password,
    errorMessage,
    isSending,
    onChangeEmail,
    onChangePassword,
    onLogin,
  } = props;
  return (
    <form className="form-signin" onSubmit={onLogin}>
      {errorMessage.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <div className="form-label-group">
        <input
          type="email"
          className="form-control login-form"
          placeholder="E-mail"
          value={email}
          onChange={(e) => onChangeEmail(e.target.value)}
        />
      </div>
      <div className="form-label-group">
        <input
          type="password"
          className="form-control login-form"
          placeholder="Пароль"
          value={password}
          onChange={(e) => onChangePassword(e.target.value)}
        />
      </div>
      <button
        className="btn btn-lg btn-primary btn-block mb-3"
        type="submit"
        onClick={onLogin}
        disabled={isSending}
      >
        {isSending ? "Ожидайте" : "Войти"}
      </button>
      <div className="row justify-content-between m-2">
        <Link to={Routes.registration}>Регистрация</Link>
        <Link to={Routes.resetPassword}>Забыли пароль?</Link>
      </div>
    </form>
  );
};
