import React, { Component } from "react";

const Spinner = () => <h1>Загрузка</h1>;
const ErrorComponent = () => <h1>Ошибка</h1>;

interface RequestProviderProps<ResponseData> {
  requestFunction: () => Promise<ResponseData>;
  renderSuccess: (renderData: ResponseData) => React.ReactNode;
  renderError?: (error: Error) => React.ReactNode;
}

enum RequestState {
  Waiting,
  Success,
  Error,
}

interface RequestProviderState<ResponseData> {
  requestState: RequestState;
  data: ResponseData | null;
  error: Error | null;
}

export class RequestProvider<ResponseData = void> extends Component<
  RequestProviderProps<ResponseData>,
  RequestProviderState<ResponseData>
> {
  public constructor(props: RequestProviderProps<ResponseData>) {
    super(props);
    this.state = {
      requestState: RequestState.Waiting,
      data: null,
      error: null,
    };
  }

  public async componentDidMount() {
    try {
      const data = await this.props.requestFunction();

      this.setState({
        data,
        requestState: RequestState.Success,
      });
    } catch (error) {
      this.setState({
        error: error as Error,
        requestState: RequestState.Error,
      });
    }
  }

  public render() {
    const { renderError, renderSuccess } = this.props;
    const { data, requestState, error } = this.state;

    if (requestState === RequestState.Waiting) return <Spinner />;

    if (data && requestState === RequestState.Success)
      return renderSuccess(data);

    return renderError && error ? renderError(error) : ErrorComponent();
  }
}
