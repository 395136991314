import {
  CountStatusType,
  IOrder,
  IOrderPage,
  IOrderEditor,
  IOrderContentEditor,
  ICalculateResponse,
  IProductSet,
  IBlockedStatus,
  CdrType,
} from "../../api/";
import { PaginationType } from "../../api/common-types/pagination.types";
import { IEuroPostOffice } from "../../api/europost/europost.types";

export enum ACTION {
  // all
  ORDERS_FETCH = "ORDERS_FETCH",
  ORDERS_SUCCESS = "ORDERS_SUCCESS",
  ORDERS_ERROR = "ORDERS_ERROR",

  ORDER_EDITOR_SUCCESS = "ORDER_EDITOR_SUCCESS",
  ORDER_EDITOR_ERROR = "ORDER_EDITOR_ERROR",

  //ORDER_PUT_CONTENT_IN_EDITOR = "ORDER_PUT_CONTENT_IN_EDITOR",
  ORDER_CLEAR_EDITOR = "ORDER_CLEAR_EDITOR",

  ORDER_ADD_CONTENT = "ORDER_ADD_CONTENT",
  ORDER_UPDATE_CONTENT = "ORDER_UPDATE_CONTENT",
  ORDER_DELETE_CONTENT = "ORDER_DELETE_CONTENT",

  ORDER_CALCULATE_SUCCESS = "ORDER_CALCULATE_SUCCESS",

  ORDER_SEND = "ORDER_SEND",
  ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS",
  ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS",
  ORDER_SEND_SUCCESS = "ORDER_SEND_SUCCESS",
  ORDER_SEND_ERROR = "ORDER_SEND_ERROR",

  ORDER_ADD_PACKET = "ORDER_ADD_PACKET",

  ORDER_CALLS_FETCH = "ORDER_CALLS_FETCH",
  ORDER_CALLS_SUCCESS = "ORDER_CALLS_SUCCESS",
  ORDER_CALLS_ERROR = "ORDER_CALLS_ERROR",

  ORDERS_BLOCKED_SUCCESS = "ORDERS_BLOCKED_SUCCESS",

  ORDERS_EURO_OFFICES_SUCCESS = "ORDERS_EURO_OFFICES_SUCCESS",
}

interface IOrdersContentAction {
  type:
    | ACTION.ORDER_ADD_CONTENT
    | ACTION.ORDER_UPDATE_CONTENT
    | ACTION.ORDER_DELETE_CONTENT;
  payload: IOrderContentEditor;
}

interface IOrdersFetchAction {
  type: ACTION.ORDERS_FETCH | ACTION.ORDER_SEND | ACTION.ORDER_CALLS_FETCH;
}

interface IOrdersErrorAction {
  type:
    | ACTION.ORDERS_ERROR
    | ACTION.ORDER_SEND_ERROR
    | ACTION.ORDER_CALLS_ERROR;
}

interface IOrderSuccessAction {
  type: ACTION.ORDERS_SUCCESS;
  payload: IOrderPage;
}

interface IOrderBlockedSuccessAction {
  type: ACTION.ORDERS_BLOCKED_SUCCESS;
  payload: IBlockedStatus;
}

interface IOrderEditorSuccessAction {
  type: ACTION.ORDER_EDITOR_SUCCESS;
  payload: IOrderEditor & ICalculateResponse;
}

interface IOrderClearInEditorAction {
  type: ACTION.ORDER_CLEAR_EDITOR;
}

interface IOrdersCalculateAction {
  type: ACTION.ORDER_CALCULATE_SUCCESS;
  payload: ICalculateResponse;
}

interface IOrdersAddPacketAction {
  type: ACTION.ORDER_ADD_PACKET;
  payload: IProductSet;
}

interface IOrdersCreteUpdateAction {
  type: ACTION.ORDER_CREATE_SUCCESS | ACTION.ORDER_UPDATE_SUCCESS;
  payload: number;
}

interface IOrdersSuccessAction {
  type: ACTION.ORDER_SEND_SUCCESS;
  payload: IOrder;
}

interface IOrderCallsAction {
  type: ACTION.ORDER_CALLS_SUCCESS;
  payload: CdrType[];
}

interface IEuroPostOfficesAction {
  type: ACTION.ORDERS_EURO_OFFICES_SUCCESS;
  payload: IEuroPostOffice[];
}

export type IOrderActionTypes =
  | IOrdersFetchAction
  | IOrdersErrorAction
  | IOrderEditorSuccessAction
  | IOrderSuccessAction
  | IOrdersContentAction
  | IOrdersCalculateAction
  | IOrdersAddPacketAction
  | IOrdersCreteUpdateAction
  | IOrdersSuccessAction
  | IOrderClearInEditorAction
  | IOrderCallsAction
  | IOrderBlockedSuccessAction
  | IEuroPostOfficesAction;

export interface IOrdersState {
  isLoading: boolean;
  isSending: boolean;
  error: string;
  orders: IOrder[];
  blockedStatus: IBlockedStatus;
  countStatus: CountStatusType[];
  pagination: PaginationType | null;
  orderEditor: IOrderEditor & ICalculateResponse;
  redirect: string | null;
  lastEditedOrderId: number | null;
  isLoadingCallsToday: boolean;
  callsToday: CdrType[];
  euroPostOffices: IEuroPostOffice[];
}

export enum TypeOrderChange {
  DRAFT,
  FROM_DRAFT,
  RESTART,
  CANCEL,
  CONFIRM,
  COMMENT,
  BOOK,
  UN_BOOK,
  UNLOCK,
}

export interface IOrdersSetParams {
  orderId: number;
  type: TypeOrderChange;
  params?: string;
}

export interface IOrdersUpdateComment {
  orderId: number;
  comment: string;
}
