import React from "react";
import { Modal } from "../modal/modal";

interface ModalProps {
  isModalOpen: boolean;
  header?: string;
  message?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmationModal = ({
  isModalOpen,
  header,
  message,
  onConfirm,
  onClose,
}: ModalProps) => (
  <Modal isModalOpen={isModalOpen} onClose={onClose}>
    <h2>{header || "Вы подтверждаете выполнение операции?"}</h2>
    <br />
    {message && <p>{message}</p>}
    <button className="btn btn-danger" onClick={onConfirm}>
      ДА, подтверждаю
    </button>
  </Modal>
);
