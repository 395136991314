import React, { useState } from "react";
import { IOrderContentEditor, IProduct } from "../../../api";
import { SERVER_URL } from "../../../env";
import "./product.scss";

interface Props {
  product: IProduct;
  addOrderContent: (content: IOrderContentEditor) => void;
  onPrev: () => void;
  onNext: () => void;
}

// const dateNow = new Date();

export const ModalView: React.FunctionComponent<Props> = ({
  product,
  addOrderContent,
  onPrev,
  onNext,
}) => {
  const [activeImage, setActiveImage] = useState(product.images[0] || null);
  return (
    <div className="productModal">
      <h2>{product.name}</h2>
      <div className="d-flex justify-content-between">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item">
            <button
              className="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#product-desc"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Описание
            </button>
          </li>
          <li className="nav-item">
            <button
              className="nav-link "
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#product-images"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Фотогаллерея
            </button>
          </li>

          <li className="nav-item">
            <button
              className="nav-link "
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#product-feedback"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Отзывы и комментарии
            </button>
          </li>
          <li className="nav-item">
            <button
              className="nav-link "
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#product-basket"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Наличие товара
            </button>
          </li>
        </ul>
        <div className="text-right">
          <a href="#" onClick={onPrev} className="m-3">
            Предыдущий
          </a>
          <a href="#" onClick={onNext} className="m-3">
            Следующий
          </a>
        </div>
      </div>
      <div className="productModal__content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="product-desc"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          </div>
          <div
            className="tab-pane fade"
            id="product-images"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="productModal__images">
              <div className="productModal__images-menu">
                {product.images.map((image) => (
                  <img
                    src={
                      SERVER_URL +
                      "/images/" +
                      image.folder +
                      "/" +
                      image.file_name
                    }
                    alt={image.name}
                    onClick={() => setActiveImage(image)}
                  />
                ))}
              </div>
              <div className="p-2">
                {activeImage && (
                  <img
                    src={
                      SERVER_URL +
                      "/images/" +
                      activeImage.folder +
                      "/" +
                      activeImage.file_name
                    }
                    alt={activeImage.name}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="product-feedback" role="tabpanel">
            Feedback
          </div>
          <div className="tab-pane fade" id="product-basket" role="tabpanel">
            <table className="table">
              <thead>
                <tr>
                  <th>Артикул</th>
                  <th className="text-center">Доступно количество</th>
                  <th className="wi-100 text-right">Стоимость</th>
                  <th className="wi-150 text-center">Корзина</th>
                </tr>
              </thead>
              <tbody>
                {product.articles.map((article) => (
                  <tr key={article.article}>
                    <td scope="row" className="">
                      {article.article}
                    </td>
                    <td scope="row" className="text-center">
                      {article.count > 0
                        ? `${article.count} шт`
                        : "нет в наличии"}
                    </td>
                    <td scope="row" className="text-right">
                      {article.price} руб.
                    </td>
                    <td className="text-center">
                      {(article.count > 0 || article.preOrder) && (
                        <button
                          type="button"
                          className={
                            article.count > 0
                              ? "btn btn-outline-success btn-sm"
                              : "btn btn-outline-warning btn-sm"
                          }
                          onClick={() =>
                            addOrderContent({
                              article: article.article,
                              price: article.price,
                              count: 1,
                              productName: product.name,
                              productId: product.id,
                              contentId: "",
                            })
                          }
                        >
                          + в заказ
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
