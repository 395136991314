import { ACTION, IInformationActionTypes, IInformationState } from "./types";

const initialState: IInformationState = {
  isLoading: false,
  error: "",
  information: [],
  pagination: null,
  countNew: 0,
};

export const reducer = (
  state = initialState,
  action: IInformationActionTypes
): IInformationState => {
  switch (action.type) {
    case ACTION.INFORMATION_FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION.INFORMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        information: action.payload.data,
        pagination: action.payload.pagination,
      };
    case ACTION.INFORMATION_COUNT_NEW:
      return {
        ...state,
        isLoading: false,
        countNew: action.payload,
      };
    case ACTION.INFORMATION_CONFIRM_SEND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countNew: state.countNew - 1,
        information: state.information.filter(
          (info) => info.confirm_id !== action.payload
        ),
      };
    case ACTION.NEW_INFORMATION:
      return {
        ...state,
        isLoading: false,
        countNew: state.countNew + 1,
      };
    default:
      return state;
  }
};
