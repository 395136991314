import { ClientStateType } from "../../api";
import {
  ACTION,
  IClientListState,
  IClientActionTypes,
  ClientActiveType,
} from "./types";

const initialState: IClientListState = {
  isLoading: false,
  isSending: false,
  error: "",
  isVipFetching: false,
  isVipError: false,
  isAssignFetching: false,
  clients: [],
  callBackList: [],
  callBackActiveCount: 0,
  activeType: null,
  pagination: null,
};

export const reducer = (
  state = initialState,
  action: IClientActionTypes
): IClientListState => {
  switch (action.type) {
    case ACTION.CLIENTS_FETCH:
      return {
        ...state,
        isLoading: true,
        activeType: action.payload,
        error: "",
      };
    case ACTION.CLIENTS_CREATE_FETCH:
      return {
        ...state,
        isSending: true,
      };
    case ACTION.CLIENTS_CREATE_SUCCESS:
      return {
        ...state,
        clients: [action.payload, ...state.clients],
      };
    case ACTION.CLIENTS_CREATE_ERROR:
      return {
        ...state,
        isSending: false,
      };
    case ACTION.CLIENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        clients: [],
        callBackList: [],
        pagination: null,
        error: "Ошибка загрузки",
      };
    case ACTION.CLIENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clients:
          action.clientType !== ClientActiveType.CALLBACK_LIST
            ? action.payload.data
            : state.clients,
        callBackList:
          action.clientType === ClientActiveType.CALLBACK_LIST
            ? action.payload.data
            : state.callBackList,

        pagination: action.payload.pagination || null,
      };

    case ACTION.CLIENTS_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        clients:
          state.activeType === ClientActiveType.CALL_LIST
            ? state.clients.filter(
                (client) => client.clientId !== action.payload.clientId
              )
            : state.clients,
        callBackList:
          action.payload.status === ClientStateType.CALLBACK
            ? [
                ...state.callBackList.filter(
                  (client) => client.clientId !== action.payload.clientId
                ),
                action.payload,
              ].sort((a, b) => a.dateCallback - b.dateCallback)
            : state.callBackList.filter(
                (client) => client.clientId !== action.payload.clientId
              ),
      };

    case ACTION.CLIENTS_VIP_REMOVE_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(
          (client) => client.clientId !== action.payload
        ),
      };

    case ACTION.CLIENTS_VIP_ADD_FETCH:
      return {
        ...state,
        isVipFetching: true,
        isVipError: false,
      };

    case ACTION.CLIENTS_VIP_REMOVE_FETCH:
      return {
        ...state,
        isVipFetching: true,
        isVipError: false,
      };

    case ACTION.CLIENTS_VIP_ERROR:
      return {
        ...state,
        isVipFetching: false,
        isVipError: true,
      };

    case ACTION.CLIENTS_ASSIGN_FETCH:
      return {
        ...state,
        isAssignFetching: true,
      };
    case ACTION.CLIENTS_ASSIGN_SUCCESS:
      return {
        ...state,
        isAssignFetching: false,
      };
    case ACTION.CLIENTS_ASSIGN_ERROR:
      return {
        ...state,
        isAssignFetching: false,
      };
    case ACTION.CHECK_CALLBACK_LIST:
      return {
        ...state,
        callBackActiveCount: action.payload,
      };
    default:
      return state;
  }
};
