import { AppThunk } from "..";
import { ChatAPI } from "../../api";
import { Routes } from "../../router/helper";
import { toastImportant } from "../../services/toast/toast";
import { ACTION, ChatActionType, SocketMessageType } from "./types";

export const fetchChatUsers =
  (): AppThunk<ChatActionType> => async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CHAT_FETCH_USERS,
      });
      const users = await ChatAPI.getUsers();

      dispatch({
        type: ACTION.CHAT_SUCCESS_USERS,
        payload: users,
      });
    } catch (error) {
      // dispatch({
      //   type: ACTION.CHAT_ERROR_USERS,
      // });
    }
  };

export const fetchChatMessage =
  (userId: number): AppThunk<ChatActionType> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CHAT_FETCH_MESSAGES,
      });
      const messages = await ChatAPI.getMessages(userId);

      dispatch({
        type: ACTION.CHAT_SUCCESS_MESSAGES,
        payload: { userId, messages },
      });
    } catch (error) {
      // dispatch({
      //   type: ACTION.CHAT_ERROR,
      // });
    }
  };

export function toggleChatVisibility(): ChatActionType {
  return {
    type: ACTION.CHAT_TOGGLE_VISIBILITY,
  };
}

export function changeSocketStatus(
  status: boolean,
  myId?: number
): ChatActionType {
  return {
    type: ACTION.WS_STATUS,
    payload: { status, myId: myId || 0 },
  };
}

export function receiveMessage(message: any): ChatActionType {
  if (message.type === SocketMessageType.INCOME_CALL) {
    toastImportant("Входящий звонок от клиента", Routes.cdr);
    return {
      type: ACTION.NEW_INCOME_CALL,
    };
  }

  if (message.type === SocketMessageType.INFORMATION) {
    toastImportant("Важная информация", Routes.information);
    return {
      type: ACTION.NEW_INFORMATION,
      payload: message.data,
    };
  }

  return {
    type: ACTION.WS_RECEIVE,
    payload: message,
  };
}
