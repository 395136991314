import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductCategories, IProductPage } from "../../api/";
import { IProductListState } from "./types";

const initialState: IProductListState = {
  isLoading: false,
  error: "",
  products: [],
  categories: [],
  groups: [],
  pagination: null,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    loading: (state: IProductListState) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    successCategory: (
      state: IProductListState,
      action: PayloadAction<IProductCategories>
    ) => {
      return {
        ...state,
        categories: action.payload.categories,
        groups: action.payload.groups,
      };
    },
    successProducts: (
      state: IProductListState,
      action: PayloadAction<IProductPage>
    ) => {
      return {
        ...state,
        isLoading: false,
        products: action.payload.data,
        pagination: action.payload.pagination,
      };
    },
    error: (state: IProductListState) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const { actions, reducer } = productsSlice;
