import { IClient, IClientsPage } from "../../api/";
import { PaginationType } from "../../api/common-types/pagination.types";

export enum ClientActiveType {
  CALL_LIST = "callSheet",
  CALLBACK_LIST = "callback",
  VIP_LIST = "vip",
  FROM_ORDERS_LIST = "fromOrders",
  DISMISSED_LIST = "dismissed",
}

export enum ACTION {
  CLIENTS_FETCH = "CLIENTS_FETCH",
  CLIENTS_SUCCESS = "CLIENTS_SUCCESS",
  CLIENTS_ERROR = "CLIENTS_ERROR",
  CLIENTS_UPDATE_STATUS_FETCH = "CLIENTS_UPDATE_STATUS_FETCH",
  CLIENTS_UPDATE_STATUS_SUCCESS = "CLIENTS_UPDATE_STATUS_SUCCESS",
  CLIENTS_UPDATE_STATUS_ERROR = "CLIENTS_UPDATE_STATUS_ERROR",
  CLIENTS_VIP_ADD_FETCH = "CLIENTS_VIP_ADD_FETCH",
  CLIENTS_VIP_ADD_SUCCESS = "CLIENTS_VIP_ADD_SUCCESS",
  CLIENTS_VIP_REMOVE_FETCH = "CLIENTS_VIP_REMOVE_FETCH",
  CLIENTS_VIP_REMOVE_SUCCESS = "CLIENTS_VIP_REMOVE_SUCCESS",
  CLIENTS_VIP_ERROR = "CLIENTS_VIP_ERROR",
  CLIENTS_ASSIGN_FETCH = "CLIENTS_ASSIGN_FETCH",
  CLIENTS_ASSIGN_SUCCESS = "CLIENTS_ASSIGN_SUCCESS",
  CLIENTS_ASSIGN_ERROR = "CLIENTS_ASSIGN_ERROR",
  CLIENTS_CREATE_FETCH = "CLIENTS_CREATE_FETCH",
  CLIENTS_CREATE_SUCCESS = "CLIENTS_CREATE_SUCCESS",
  CLIENTS_CREATE_ERROR = "CLIENTS_CREATE_ERROR",
  CHECK_CALLBACK_LIST = "CHECK_CALLBACK_LIST",

  CLIENTS_SHIFT_TIME_FETCH = "CLIENTS_SHIFT_TIME_FETCH",
  CLIENTS_SHIFT_TIME_SUCCESS = "CLIENTS_SHIFT_TIME_SUCCESS",
  CLIENTS_SHIFT_TIME_ERROR = "CLIENTS_SHIFT_TIME_ERROR",
}

interface IClientsFetchListAction {
  type: ACTION.CLIENTS_FETCH;
  payload: ClientActiveType;
}

interface IClientsFetchAction {
  type:
    | ACTION.CLIENTS_UPDATE_STATUS_FETCH
    | ACTION.CLIENTS_VIP_ADD_FETCH
    | ACTION.CLIENTS_VIP_REMOVE_FETCH
    | ACTION.CLIENTS_ASSIGN_FETCH
    | ACTION.CLIENTS_CREATE_FETCH
    | ACTION.CLIENTS_SHIFT_TIME_SUCCESS;
}

interface IClientsErrorAction {
  type:
    | ACTION.CLIENTS_ERROR
    | ACTION.CLIENTS_UPDATE_STATUS_ERROR
    | ACTION.CLIENTS_VIP_ERROR
    | ACTION.CLIENTS_ASSIGN_ERROR
    | ACTION.CLIENTS_CREATE_ERROR
    | ACTION.CLIENTS_SHIFT_TIME_ERROR;
}

interface IClientSuccessAction {
  type: ACTION.CLIENTS_SUCCESS;
  payload: IClientsPage;
  clientType: ClientActiveType;
}

interface IClientCreateUpdateSuccessAction {
  type: ACTION.CLIENTS_UPDATE_STATUS_SUCCESS | ACTION.CLIENTS_CREATE_SUCCESS;
  payload: IClient;
}

interface IClientAddVipSuccessAction {
  type: ACTION.CLIENTS_VIP_ADD_SUCCESS;
  payload: number;
}

interface IClientRemoveVipSuccessAction {
  type: ACTION.CLIENTS_VIP_REMOVE_SUCCESS;
  payload: number;
}

interface IClientAssignSuccessAction {
  type: ACTION.CLIENTS_ASSIGN_SUCCESS;
}

interface ICheckCallbackAction {
  type: ACTION.CHECK_CALLBACK_LIST;
  payload: number;
}

export type IClientActionTypes =
  | IClientsFetchListAction
  | IClientsFetchAction
  | IClientsErrorAction
  | IClientSuccessAction
  | IClientCreateUpdateSuccessAction
  | IClientAddVipSuccessAction
  | IClientRemoveVipSuccessAction
  | IClientAssignSuccessAction
  | ICheckCallbackAction;

export interface IClientListState {
  isLoading: boolean;
  isSending: boolean;
  error: string;
  isVipFetching: boolean;
  isVipError: boolean;
  isAssignFetching: boolean;
  clients: IClient[];
  callBackList: IClient[];
  callBackActiveCount: number;
  activeType: ClientActiveType | null;
  pagination: PaginationType | null;
}
