import * as Client from "./client/clients.api";
import * as Information from "./information/information.api";
import * as Order from "./order/order.api";
import * as Cdr from "./cdr/cdr.api";
import * as User from "./user/user.api";
import * as Product from "./product/product.api";
import * as Auth from "./auth/auth.api";
import * as Event from "./event/event.api";
import * as Chat from "./chat/chat.api";
import * as ProductSet from "./productSet/productSet.api";
import * as Script from "./script/script.api";
import * as Statistics from "./statistics/statistics.api";
import * as EuroPost from "./europost/europost.api";
import * as Article from "./article/article.api";
import * as Sms from "./sms/sms.api";
import * as AdminVip from "./admin-vip/clients.api";

export * from "./client/client.types";
export * from "./information/information.types";
export * from "./order/order.types";
export * from "./cdr/cdr.types";
export * from "./user/user.types";
export * from "./product/product.types";
export * from "./auth/auth.types";
export * from "./event/event.types";
export * from "./chat/chat.types";
export * from "./productSet/productSet.types";
export * from "./statistics/statistics.types";
export * from "./script/script.types";
export * from "./europost/europost.types";
export * from "./article/article.types";
export * from "./sms/sms.types";
export * from "./admin-vip/client.types";

export const ClientAPI = Client;
export const InformationAPI = Information;
export const OrderAPI = Order;
export const CdrAPI = Cdr;
export const UserAPI = User;
export const ProductAPI = Product;
export const AuthAPI = Auth;
export const EventAPI = Event;
export const ChatAPI = Chat;
export const ProductSetAPI = ProductSet;
export const StatisticsAPI = Statistics;
export const ScriptAPI = Script;
export const EuroPostAPI = EuroPost;
export const ArticleAPI = Article;
export const SmsAPI = Sms;
export const AdminVipAPI = AdminVip;
