import React from "react";
import PropTypes from "prop-types";
import { sipPropType, callPropType } from "react-sip";
import { FaPhoneVolume, FaPhoneSlash } from "react-icons/fa";
import differenceInSeconds from "date-fns/differenceInSeconds";

import "./phone.scss";

import { PhoneState } from "../../store/phone/types";
import { SipContextType, SipStatusType, CallStatusType } from "./sip-types";
import { sipContext } from "../../containers/phone-modal";
import { CallListShort } from "../../pages/Cdr/components";
import { showDuration } from "../../helpers/showDuration";
import { StickyModal } from "../stickyModal/stickyModal";
import { ClientCallStatus } from "../clientCallStatus/ClientCallStatus";
import { IClientUpdateStatus } from "../../api";
import { ClientProductList } from "../client-product-list/client-product-list";
import { RiRefreshLine } from "react-icons/ri";
import { TypeOf } from "yup";

interface Props {
  phone: PhoneState;
  userSipNumber: string;
  toggleVisible: () => void;
  changeSearch: (search: string) => void;
  searchClient: (search: string) => void;
  updateClientStatus: (request: IClientUpdateStatus) => void;
  addVipClient: (clientId: number) => void;
}

interface PageState {
  startCall: Date | null;
  billSec: number;
  isModalFinishDialog: boolean;
  dateRecall: Date | null;
  isCalls: boolean;
  isShowControlPanel: boolean;
}

export class Phone extends React.Component<Props, PageState> {
  context!: React.ContextType<typeof sipContext>;

  private interval: number;

  public constructor(props: Props) {
    super(props);

    this.interval = 0;
    this.state = {
      startCall: null,
      billSec: 0,
      isModalFinishDialog: false,
      dateRecall: null,
      isCalls: true,
      isShowControlPanel: true,
    };
  }

  static contextTypes = {
    sip: sipPropType,
    call: callPropType,
    registerSip: PropTypes.func,
    unregisterSip: PropTypes.func,
    answerCall: PropTypes.func,
    startCall: PropTypes.func,
    stopCall: PropTypes.func,
  };

  public call = (phone: string = "9196") => {
    this.context?.startCall(phone);
  };

  public stopCall = () => {
    this.context?.stopCall();
  };

  public getBillingTime = () => {
    if (this.state.startCall) {
      this.setState({
        billSec: differenceInSeconds(new Date(), this.state.startCall),
      });
    }
  };

  public handleCloseModalFinishDialog = () => {
    this.setState({ isModalFinishDialog: false });
  };

  public updateStartCall = (startCall: Date | null) => {
    this.setState({ startCall });
  };
  public handleChangeViewCall = () => {
    this.setState({ isCalls: !this.state.isCalls });
  };

  public render() {
    const {
      phone: {
        isVisible,
        search,
        isClientAvailable,
        client,
        clientProducts,
        calls,
        isSearching,
        errorMessage,
      },
      userSipNumber,
      toggleVisible,
      updateClientStatus,
      addVipClient,
    } = this.props;

    const { billSec, isModalFinishDialog, isCalls, isShowControlPanel } =
      this.state;

    // const visibilityState = isVisible ? "visible" : "hidden";
    const isCalling =
      this.context &&
      (CallStatusType[this.context.call.status] ===
        CallStatusType["callStatus/STARTING"] ||
        CallStatusType[this.context.call.status] ===
          CallStatusType["callStatus/ACTIVE"]);

    if (
      this.state.startCall === null &&
      this.context?.call.status === "callStatus/ACTIVE"
    ) {
      this.updateStartCall(new Date());
      this.interval = window.setInterval(() => this.getBillingTime(), 1000);
    }

    if (
      this.state.startCall &&
      this.context?.call.status !== "callStatus/ACTIVE"
    ) {
      this.updateStartCall(null);
      window.clearInterval(this.interval);
      this.interval = 0;

      if (billSec > 3) {
        this.setState({ isModalFinishDialog: true, billSec: 0 });
      }
    }

    return this.context ? (
      <>
        {client && (
          <ClientCallStatus
            isModalOpen={isModalFinishDialog}
            clientId={client.clientId}
            billSec={billSec}
            onClose={this.handleCloseModalFinishDialog}
            onSave={updateClientStatus}
            onAddVip={addVipClient}
          />
        )}
        <StickyModal onClose={toggleVisible} isOpen={isVisible}>
          <div className="card-header">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Набор номера
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Справка и обслуживание
                </button>
              </li>
            </ul>
          </div>
          <div className="card-body phone-content">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Номер телефона, заказа или клиента"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={search}
                    onChange={(e) => this.props.changeSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.props.searchClient(search);
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => this.props.searchClient(search)}
                    >
                      Поиск клиента
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between ml-1 mr-1">
                  <h3>{client && client.fullName}</h3>
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    onClick={this.handleChangeViewCall}
                  >
                    <RiRefreshLine className="mr-1" />
                    {isCalls ? "Товары" : "Звонки"}
                  </button>
                </div>

                {isCalls && calls.length > 0 && <CallListShort calls={calls} />}
                {!isCalls && clientProducts.length > 0 && (
                  <ClientProductList clientProducts={clientProducts} />
                )}

                {isSearching && <div>Поиск данных клиента...</div>}
                {!isClientAvailable && errorMessage.length > 0 && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
              </div>

              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                Ваш SIP номер: {userSipNumber}
                <div className="alert alert-primary mt-3" role="alert">
                  - номер телефона через 8029 (пример: 80291234567) <br />
                  - номер заказа через * (пример: *105107) <br />- номер клиента
                  через & (пример: &123000)
                </div>
                <div className="d-flex justify-content-md-center m-3">
                  <button
                    type="button"
                    className="btn btn-outline-success  "
                    onClick={() => this.call("9196")}
                  >
                    Позвонить на эхотест
                  </button>
                </div>
                <div className="d-flex justify-content-md-center m-3">
                  <button
                    type="button"
                    className="btn btn-outline-success  "
                    onClick={() => this.call("9195")}
                  >
                    Позвонить на эхотест c задержкой
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-md-center">
            <button
              type="button"
              className="btn btn-outline-success  btn-phone-control"
              onClick={() => this.call(search)}
              hidden={!!(!isClientAvailable || isCalling)}
            >
              <FaPhoneVolume /> Позвонить
            </button>

            <button
              type="button"
              className="btn btn-outline-danger btn-phone-control"
              onClick={this.stopCall}
              hidden={!isCalling}
            >
              <FaPhoneSlash /> Разъединить
            </button>
          </div>
          <div className="d-flex justify-content-md-between">
            <div className="phone-status">
              {SipStatusType[this.context.sip.status]}
            </div>
            <div className="phone-status">
              {CallStatusType[this.context.call.status]}
              {this.context.call.status === "callStatus/ACTIVE" &&
                ` ${showDuration(billSec, false)}`}
            </div>
          </div>
        </StickyModal>
        {isShowControlPanel && isCalling && (
          <div className="control-panel">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                toggleVisible();
              }}
            >
              {(client && client.fullName) || "нет имени"}
            </a>
            <div className="text-muted pl-3 pr-3">
              {showDuration(billSec, false)}
            </div>
            <button
              type="button"
              className="btn btn-danger btn-control-panel"
              onClick={this.stopCall}
            >
              <FaPhoneSlash />
            </button>
          </div>
        )}
      </>
    ) : null;
  }
}
