import { CdrType, IIncome, ICdrRequest } from "./cdr.types";
import { APIService } from "../../services/api-service";

export const getLast = (request: ICdrRequest) => {
  const url = "api/v1/cdr/last";
  return APIService.get<ICdrRequest, CdrType[]>(url, request);
};

export const getTodayFromOrder = (orderId: number) => {
  const url = `api/v1/cdr/today-from-order/${orderId}`;
  return APIService.get<CdrType[]>(url);
};

export const getCalls = (orderId: number) => {
  const url = `api/v1/cdr/from-order/${orderId}`;
  return APIService.get<CdrType[]>(url);
};

export const getIncome = () => {
  const url = "api/v1/cdr/income/";
  return APIService.get<IIncome[]>(url);
};

export const clearIncome = () => {
  const url = "api/v1/cdr/income/";
  return APIService.delete(url);
};

export const getCountIncome = () => {
  const url = "api/v1/cdr/count-income/";
  return APIService.get<number>(url);
};
