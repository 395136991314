import React from "react";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { SipProvider } from "react-sip";
import { Phone } from "../components/phone/phone";
import { RootState } from "../store";
import {
  togglePhoneVisibility,
  changeSearch,
  searchClient,
} from "../store/phone/actions";
import { updateClientStatus } from "../store/clients/actions";
import { addVipClient } from "../store/clients/actions";
import { SERVER_SIP_HOST, SERVER_SIP_PORT } from "../env";
import { SipContextType } from "../components/phone/sip-types";

export const sipContext = React.createContext<SipContextType | null>(null);
export const SipContextProvider = sipContext.Provider;

export const SipContextConsumer = sipContext.Consumer;

class PhoneModal extends React.Component<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>,
  {}
> {
  public render() {
    const {
      phone,
      user,
      togglePhoneVisibility,
      changeSearch,
      searchClient,
      updateClientStatus,
      addVipClient,
    } = this.props;

    if (!user) return null;
    return (
      <SipProvider
        host={SERVER_SIP_HOST}
        port={SERVER_SIP_PORT} // 5066 = 7443
        pathname="/ws" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
        user={`${user.sipNumber}`}
        password={user.sipPassword} // usually required (e.g. from ENV or props)
        autoRegister={true} // true by default, see jssip.UA option register
        autoAnswer={false} // automatically answer incoming calls; false by default
        iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
        sessionTimersExpires={120} // value for Session-Expires header; 120 by default
        // extraHeaders={{
        //   // optional sip headers to send
        //   register: ["X-Foo: foo", "X-Bar: bar"],
        //   invite: ["X-Foo: foo2", "X-Bar: bar2"],
        // }}
        //iceServers={[]}
        debug={true} // whether to output events to console; false by default
        //use_preloaded_route={true}
      >
        <Phone
          phone={phone}
          userSipNumber={`${user?.sipNumber}`}
          toggleVisible={togglePhoneVisibility}
          changeSearch={changeSearch}
          searchClient={searchClient}
          updateClientStatus={updateClientStatus}
          addVipClient={addVipClient}
        />
      </SipProvider>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  phone: state.phone,
  user: state.user.auth,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      togglePhoneVisibility,
      changeSearch,
      searchClient,
      updateClientStatus,
      addVipClient,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PhoneModal);
