import { IChatMessage, IChatUser } from "./chat.types";
import { APIService } from "../../services/api-service";

export const getMessages = (userId: number) => {
  const url = `api/v1/chat/messages/${userId}`;
  return APIService.get<IChatMessage[]>(url);
};

export const getUsers = () => {
  const url = "api/v1/chat/users";
  return APIService.get<IChatUser[]>(url);
};

export const markAsReadFromUser = (userId: number) => {
  const url = `api/v1/chat/mark-read/${userId}`;
  return APIService.get<string>(url);
};
