import * as React from "react";
import { PaginationType } from "../../../api/common-types/pagination.types";
import { range } from "lodash";

interface Props {
  pagination: PaginationType;
  onChangePage: (page: number) => void;
}

export const Pagination: React.FunctionComponent<Props> = (props) => {
  const { pagination, onChangePage } = props;
  const currentPage = +pagination.page;
  const totalPages = +pagination.totalPages;

  let left = currentPage - 4;
  let right = currentPage + 4;
  if (left < 1) {
    left = 1;
  }
  if (right > totalPages) {
    right = totalPages;
  }
  const pages = range(left, right + 1, 1);

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 && "disabled"}`}>
          <a
            className="page-link"
            href="#"
            onClick={() => onChangePage(currentPage - 1)}
          >
            Назад
          </a>
        </li>
        {left !== 1 && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => onChangePage(1)}>
              1
            </a>
          </li>
        )}
        {left > 2 && (
          <li className="page-item disabled">
            <a className="page-link" href="#">
              ...
            </a>
          </li>
        )}
        {pages.map((page) => (
          <li
            className={`page-item ${page === currentPage && "active"}`}
            key={page}
          >
            <a
              className="page-link"
              href="#"
              onClick={() => onChangePage(page)}
            >
              {page}
            </a>
          </li>
        ))}
        {right < totalPages - 1 && (
          <li className="page-item disabled">
            <a className="page-link" href="#">
              ...
            </a>
          </li>
        )}
        {right !== totalPages && (
          <li className="page-item">
            <a
              className="page-link"
              href="#"
              onClick={() => onChangePage(totalPages)}
            >
              {totalPages}
            </a>
          </li>
        )}
        <li className={`page-item ${currentPage === totalPages && "disabled"}`}>
          <a
            className="page-link"
            href="#"
            onClick={() => onChangePage(currentPage + 1)}
          >
            Вперед
          </a>
        </li>
      </ul>
    </nav>
  );
};
