import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import authHeader from "../../services/request/auth-header";
import { IEvent } from "../../api";
import { AppDispatch, RootState } from "../../store";

import { StickyModal } from "../../components";
import { fetchEvent } from "../../store/events/actions";
import {
  toggleChatVisibility,
  fetchChatMessage,
  fetchChatUsers,
  changeSocketStatus,
  receiveMessage,
} from "../../store/chat/actions";
import {
  IChatUserWithMessages,
  SocketMessageType,
} from "../../store/chat/types";
import { Chat } from "./components";
import { deleteCookie, setCookie } from "../../services/cookie/сookie";
import { SERVER_WS, SERVER_URL } from "../../env";

interface IPageProps {
  isUsersLoading: boolean;
  isMessagesLoading: boolean;
  usersWithMessages: IChatUserWithMessages[];
  isVisible: boolean;
  isConnected: boolean;
  userIdsOnline: number[];
  currentUserId: number;
  changeSocketStatus: (status: boolean, myId?: number) => void;
  fetchChatUsers: () => void;
  fetchChatMessage: (userId: number) => void;
  toggleChatVisibility: () => void;
  receiveMessage: (message: any) => void;
}

const ChatPage: React.FC<IPageProps> = ({
  isUsersLoading,
  isMessagesLoading,
  isVisible,
  isConnected,
  usersWithMessages,
  userIdsOnline,
  currentUserId,
  changeSocketStatus,
  fetchChatUsers,
  fetchChatMessage,
  toggleChatVisibility,
  receiveMessage,
}) => {
  const [currentUser, setCurrentUser] = useState(0);
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  const [message, setMessage] = useState("");
  const [audioReceive, setAudioReceive] = useState(
    new Audio(`${SERVER_URL}/audio/message.wav`)
  );

  //const messageBoxRef = React.createRef()

  // audio files
  // https://www.findsounds.com/ISAPI/search.dll?keywords=bell&keywords=bell

  useEffect(() => {
    connectWS(SERVER_WS);
    fetchChatUsers();
    fetchChatMessage(currentUser);
  }, []);

  useEffect(() => {
    if (isVisible && currentUser) {
      const current = usersWithMessages.find(
        (user) => user.userId === currentUser
      );
      if (current && current.countUnread > 0) {
        handleReadMessage();
      }
    }
  }, [currentUser]);

  const makeSound = () => {
    if (audioReceive) {
      audioReceive.play();
    } else {
      setAudioReceive(new Audio(`${SERVER_URL}/audio/message.wav`));
    }
  };

  const connectWS = useCallback((url: string) => {
    var authToken = authHeader();
    if (authToken["x-access-token"]) {
      setCookie("X-Authorization", authToken["x-access-token"]);

      let socket = new WebSocket(url);

      socket.onopen = (e) => {
        console.log("Chat connected!");
        changeSocketStatus(true, currentUserId);
        deleteCookie("X-Authorization");
        setWebSocket(socket);
      };

      socket.onclose = (e) => {
        changeSocketStatus(false);
        setWebSocket(null);
        //socket = null;
        deleteCookie("X-Authorization");
        setTimeout(() => connectWS(SERVER_WS), 5000);
      };

      socket.onerror = (e) => {
        changeSocketStatus(false);
        setWebSocket(null);
        //socket = null;
        deleteCookie("X-Authorization");
      };

      socket.onmessage = (message) => {
        try {
          const data = JSON.parse(message.data);
          receiveMessage(data);
          if (data.type === SocketMessageType.message) {
            makeSound();
          }
        } catch {
          console.log("Error parse");
        }
      };
    }
  }, []);

  const handleSendMessage = (message: string) => {
    if (webSocket && message.length) {
      webSocket.send(
        JSON.stringify({ message, userId: currentUser, type: "message" })
      );
      setMessage("");
    }
  };

  const handleReadMessage = () => {
    if (webSocket && currentUser) {
      webSocket.send(JSON.stringify({ userId: currentUser, type: "read" }));
    }
  };

  const handleSelectUser = (userId: number) => {
    setCurrentUser(userId);
    fetchChatMessage(userId);
  };

  return (
    <StickyModal
      isOpen={isVisible}
      onClose={toggleChatVisibility}
      width={"75%"}
    >
      <Chat
        usersWithMessages={usersWithMessages}
        userIdsOnline={userIdsOnline}
        currentUser={currentUser}
        isUsersLoading={isUsersLoading}
        isMessagesLoading={isMessagesLoading}
        message={message}
        isConnected={isConnected}
        currentUserId={currentUserId}
        onSelectUser={handleSelectUser}
        onSendMessage={handleSendMessage}
        setMessage={setMessage}
      />
    </StickyModal>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.chat,
  currentUserId: state.user.current?.id || 0,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      fetchChatUsers,
      fetchChatMessage,
      toggleChatVisibility,
      receiveMessage,
      changeSocketStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChatPage);
