import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { CdrType, IOrder, IOrderContent } from "../../../api";
import { ImBlocked } from "react-icons/im";
import { BiCheckCircle } from "react-icons/bi";
import {
  FiAlertTriangle,
  FiPhoneIncoming,
  FiPhoneOutgoing,
} from "react-icons/fi";

interface Props {
  order: IOrder;
  isLoadingCallsToday: boolean;
  callsToday: CdrType[];
  isSending: boolean;
  getCallsToday: (orderId: number) => void;
  onConfirm: () => void;
}

const TIME_LIMIT = 10;

const getCallStatus = (calls: CdrType[]) => {
  if (calls.length === 0) return false;
  const hasCall = calls.find((call) => call.billsec > TIME_LIMIT);
  return !!hasCall;
};

interface IGroupedContent {
  article: string;
  count: number;
  countAvailable: number;
  productName: string;
}

const findGrouped = (content: IOrderContent[]): IGroupedContent[] => {
  return content.reduce(
    (
      acc: IGroupedContent[],
      { article, count, productName, countAvailable }: IOrderContent
    ) => {
      const prev = acc.find((c) => c.article === article);
      if (prev) {
        prev.count += count;
      } else acc.push({ article, count, productName, countAvailable });

      return acc;
    },
    []
  );
};

export const ModalRemoveFromDraft: React.FunctionComponent<Props> = ({
  order,
  isLoadingCallsToday,
  callsToday,
  isSending,
  getCallsToday,
  onConfirm,
}) => {
  const [hasCall, setHasCall] = useState(false);
  const [hasProduct, setHasProduct] = useState(false);
  const [grouped, setGrouped] = useState<IGroupedContent[]>([]);

  useEffect(() => {
    getCallsToday(order.orderId);
    const gr = findGrouped(order.content);
    setGrouped(gr);
    setHasProduct(
      !gr.find((product) => product.count > product.countAvailable)
    );
  }, []);

  useEffect(() => {
    setHasCall(getCallStatus(callsToday));
  }, [callsToday]);

  return (
    <div className="">
      <h2>Вывести из черновика</h2>
      <p>
        Для вывовода из черновика необходимо: <br />- состоявшегоcя разговора на
        сегодняшнее число (более {TIME_LIMIT} секунд)
        <br />- наличие товара для бронирования
      </p>
      <div>
        <div className="d-flex justify-content-between mb-2">
          <div className="col">
            <h5>Наличие звонков за сегодня</h5>
          </div>
          <div className="col col-md-auto f-size-l">
            {!isLoadingCallsToday &&
              (hasCall ? (
                <BiCheckCircle className="text-success" />
              ) : (
                <ImBlocked className="text-danger" />
              ))}
          </div>
        </div>
        <div>
          {isLoadingCallsToday ? (
            "Поиск..."
          ) : (
            <table className="table table-sm">
              {callsToday.map((call) => (
                <tr>
                  <td>
                    {call.direction ? (
                      <div className="call-outgoing">
                        <FiPhoneOutgoing />
                        исходящий
                      </div>
                    ) : (
                      <div className="call-incoming">
                        <FiPhoneIncoming /> входящий
                      </div>
                    )}
                  </td>
                  <td>{format(new Date(call.start_stamp), "HH:mm")}</td>
                  <td>{call.billsec} сек.</td>
                </tr>
              ))}
            </table>
          )}
        </div>
        <div className="d-flex justify-content-between mb-2">
          <div className="col">
            <h5>Наличие товара</h5>
          </div>
          <div className="col col-md-auto f-size-l">
            {hasProduct ? (
              <BiCheckCircle className="text-success" />
            ) : (
              <ImBlocked className="text-danger" />
            )}
          </div>
        </div>

        <div className="mb-2">
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">Товар</th>
                <th scope="col" className="text-center">
                  Требуется
                </th>
                <th scope="col" className="text-center">
                  Доступно
                </th>
                <th scope="col" className="text-center">
                  Возможность
                </th>
              </tr>
            </thead>
            {grouped.map((poz, index) => (
              <tr key={index}>
                <td>
                  {poz.productName} {poz.article}
                </td>
                <td className="text-center">{poz.count}</td>
                <td className="text-center">{poz.countAvailable}</td>
                <td className="text-center">
                  {poz.count <= poz.countAvailable ? (
                    "да"
                  ) : (
                    <FiAlertTriangle className="text-danger" />
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <button
        className="btn btn-primary mt-3"
        onClick={onConfirm}
        disabled={!(hasCall && hasProduct && !isSending)}
      >
        {isSending ? "Ожидайте..." : "Забронировать и отправить"}
      </button>
    </div>
  );
};
