export enum HttpStatus {
    PROCESSING = 102,
    OK = 200,
    ACCEPTED = 202,
    NON_AUTHORITATIVE = 203,
    NO_CONTENT = 204,
    PARTIAL_CONTENT = 206,
    MOVED = 301,
    NOT_MODIFIED = 304,
    TEMPORARY_REDIRECT = 307,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    NOT_ALLOWED = 405,
    NOT_ACCEPTABLE = 406,
    TIMEOUT = 408,
    EXPECTATION_FAILED = 417,
    UNPROCESSABLE_ENTITY = 422,
    LOCKED = 423,
    FAILED_DEPENDENCY = 424,
    TOO_MANY_REQUESTS = 429,
    RETRY_WITH = 449,
    ILLEGAL = 451,
    INTERNAL_ERROR = 500,
    UNAVAILABLE = 503,
    INSUFFICIENT_STORAGE = 507
}
