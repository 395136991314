import {
  IClient,
  IClientCard,
  IClientEditor,
  IClientSearch,
  IClientsListRequest,
  IClientsPage,
  IClientUpdateStatus,
  ICommonResponse,
} from "./client.types";
import { APIService } from "../../services/api-service";

export const getClients = (request: IClientsListRequest) => {
  const url = "api/v1/client/client-list";
  return APIService.post<IClientsListRequest, IClientsPage>(url, request);
};

export const searchDataClient = (search: string) => {
  const url = "api/v1/client/search/";
  return APIService.post<IClientSearch, IClientCard>(url, { search });
};

export const updateStatus = (request: IClientUpdateStatus) => {
  const url = "api/v1/client/update-status/";
  return APIService.post<IClientUpdateStatus, IClient>(url, request);
};

export const addVip = (clientId: number) => {
  const url = `api/v1/client/add-vip/${clientId}`;
  return APIService.get<ICommonResponse>(url);
};

export const removeVip = (clientId: number) => {
  const url = `api/v1/client/remove-vip/${clientId}`;
  return APIService.get<ICommonResponse>(url);
};

export const assign = () => {
  const url = "api/v1/client/assign/";
  return APIService.get<ICommonResponse>(url);
};

export const createNew = (request: IClientEditor) => {
  const url = "api/v1/client/create/";
  return APIService.post<IClientEditor, IClient>(url, request);
};

export const shiftTime = (shiftId: number) => {
  const data = { shiftId };
  const url = "api/v1/client/shift-time/";
  return APIService.post<{ shiftId: number }, IClient>(url, data);
};
