import React, { useEffect } from "react";
import { CdrType, IEvent, IOrder } from "../../../api";
import { Loading } from "../../../components/common/loading/Loading";
import { CallList } from "../../Cdr/components";

interface Props {
  order: IOrder;
  data: CdrType[];
  isLoading: boolean;
  getCalls: (orderId: number) => void;
}

export const OrderListen: React.FunctionComponent<Props> = ({
  order,
  data,
  isLoading,
  getCalls,
}) => {
  useEffect(() => {
    getCalls(order.orderId);
  }, []);

  return (
    <div className="orderModal">
      <div className="orderModal__header">
        <h2>Поиск последних звонков по номеру из заказа {order.orderId}</h2>
      </div>
      <div className="orderModal__content">
        {isLoading ? <Loading /> : <CallList calls={data} />}
      </div>
    </div>
  );
};
