import {
  ACTION,
  IMenteeActionTypes,
  IMenteeState,
  OperationStatus,
} from "./types";

const initialState: IMenteeState = {
  isLoading: false,
  isFetching: false,
  error: "",
  users: [],
  operationStatus: null,
};

export const reducer = (
  state = initialState,
  action: IMenteeActionTypes
): IMenteeState => {
  switch (action.type) {
    case ACTION.FETCH:
      return {
        ...state,
        isLoading: true,
        operationStatus: null,
      };
    case ACTION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case ACTION.OPERATION_FETCH:
      return {
        ...state,
        isFetching: true,
        operationStatus: null,
      };
    case ACTION.OPERATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        operationStatus: OperationStatus.SUCCESS,
      };
    case ACTION.OPERATION_ERROR:
      return {
        ...state,
        isFetching: false,
        operationStatus: OperationStatus.ERROR,
      };

    default:
      return state;
  }
};
