import React, { useLayoutEffect } from "react";
import { IEvent, IOrder } from "../../../api";
import { Loading } from "../../../components/common/loading/Loading";
import { OrderStatusesRu } from "../../../constants/order-statuses";
import { differenceInDays, format, fromUnixTime, startOfDay } from "date-fns";
import { ru } from "date-fns/locale";
import { FaTimes, FaPencilAlt, FaPlus } from "react-icons/fa";
import { BiLockAlt } from "react-icons/bi";

interface Props {
  order: IOrder;
  events: IEvent[];
  isLoading: boolean;
  fetchOrderEvent: (orderId: number) => void;
}

export const OrderHistory: React.FunctionComponent<Props> = ({
  order,
  events,
  isLoading,
  fetchOrderEvent,
}) => {
  useLayoutEffect(() => {
    fetchOrderEvent(order.orderId);
  }, [fetchOrderEvent, order.orderId]);

  const dateNow = new Date();

  const showDay = (time: any) => {
    const date = fromUnixTime(time);
    const days = differenceInDays(dateNow, startOfDay(date));

    return (
      <>
        {format(date, "dd MMMM HH:mm", {
          locale: ru,
        })}
        <small>{days === 0 ? "Сегодня" : `${days} дней назад`}</small>
      </>
    );
  };

  const getCircle = (event: string) => {
    if (event.includes("Создан"))
      return (
        <span className="circle">
          <FaPlus />
        </span>
      );

    if (event.includes("изменен"))
      return (
        <span className="circle">
          <FaPencilAlt />
        </span>
      );

    if (event.includes("Бронирование"))
      return (
        <span className="circle">
          <BiLockAlt />
        </span>
      );

    if (
      event.includes("Отмена") ||
      event.includes("Автоотмена") ||
      event.includes("отменен")
    )
      return (
        <span className="circle">
          <FaTimes />
        </span>
      );

    return <span className="circle" />;
  };

  return (
    <div className="orderModal">
      <div className="orderModal__header">
        <h2>
          История заказа {order.orderId}
          <div className="badge badge-success">
            {OrderStatusesRu[order.state]}
          </div>
        </h2>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="sticky-modal__body-scroll">
          {events.map((event) => (
            <div key={event.id} className="timeline-item">
              {getCircle(event.event)}

              <div className="timeline-date">{showDay(event.date)}</div>
              <div className="timeline-event">
                {event.userId !== 2 && (
                  <div className="text-muted">
                    <b>{event.userName}</b>
                  </div>
                )}
                {event.event}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
