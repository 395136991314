import React, { useEffect, useState, useRef } from "react";
import { IScriptAnswer, IScriptStep, IScriptStepContent } from "../../../api";
import "./script.scss";

interface Props {
  allSteps: IScriptStep[];
  allContent: IScriptStepContent[];
  allAnswers: IScriptAnswer[];
}

interface IScriptCurrent {
  step: IScriptStep | null;
  content?: IScriptStepContent[];
  answers?: IScriptAnswer[];
}

interface IScriptLog {
  content?: IScriptStepContent[];
  answers?: IScriptAnswer[];
}

export const ScriptHistory: React.FunctionComponent<Props> = ({
  allSteps,
  allContent,
  allAnswers,
}) => {
  //const startId = 1;
  const [log, setLog] = useState<IScriptLog[]>([]);
  const [currentStep, setCurrentStep] = useState<IScriptCurrent | null>(null);

  const getStep = (stepId: number) => {
    const step = allSteps.find((item) => item.stepId === stepId);
    if (step) {
      const content = allContent.filter((item) => item.stepId === step.stepId);
      const answers = allAnswers.filter((item) => item.stepId === step.stepId);
      return { step, content, answers };
    }
    return null;
  };

  useEffect(() => {
    scrollToBottom();
  }, [log]);

  const clearScript = () => {
    const first = getStep(1);
    setCurrentStep(first);
    setLog([]);
  };

  const addStep = (stepId: number, selectedAnswer?: number) => {
    const nextStep = getStep(stepId);

    setLog([
      ...log,
      {
        content: currentStep?.content,
        answers: currentStep?.answers?.filter(
          (item) => item.answerId === selectedAnswer
        ),
      },
    ]);
    setCurrentStep(nextStep);
  };
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    const first = getStep(1);
    setCurrentStep(first);
    setLog([]);
  }, []);

  return (
    <div className="script__wrapper">
      <div className="script-dialog">
        {log.map((item, i) => (
          <div className=" m-1" key={i}>
            {/* {item.step.name} */}
            <div className="script-saved-content ">
              {item.content?.map((content, j) => (
                <div key={j}>{content.text}</div>
              ))}
            </div>
            <div className="script-saved-answer">
              {item.answers?.map((answer) => (
                <button className="btn btn-secondary m-1">
                  {answer.message}
                </button>
              ))}
            </div>
          </div>
        ))}

        {currentStep && (
          <>
            <div className=" m-1">
              {/* {item.step.name} */}
              <div className="card script-body">
                {currentStep.content?.map((content, i) => (
                  <div key={i}>{content.text}</div>
                ))}
              </div>
              <div className="mb-2">
                {currentStep.answers?.map((answer, i) => (
                  <button
                    className="btn btn-primary m-1"
                    key={i}
                    onClick={() => {
                      addStep(answer.toStepId, answer.answerId);
                    }}
                  >
                    {answer.message}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="script-steps">
        <button className="btn" onClick={clearScript}>
          Начало (очистить)
        </button>
        <hr />
        <div className="script-step-menu">
          {allSteps.map((item, i) => (
            <button
              key={i}
              className="btn btn-link"
              onClick={() => {
                addStep(item.stepId);
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
