import {
  Action,
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { configureStore } from "@reduxjs/toolkit";

import { systemReducer } from "./system/reducer";
import { reducer as chatReducer } from "./chat/reducer";
import { phoneReducer } from "./phone/reducer";
import { userReducer } from "./user/reducer";
import { reducer as orderReducer } from "./order/reducer";
import { reducer as eventsReducer } from "./events/reducer";
import { reducer as clientsReducer } from "./clients/reducer";
import { reducer as informationReducer } from "./information/reducer";
import { reducer as scriptReducer } from "./script/reducer";
import { reducer as menteeReducer } from "./mentee/reducer";

// new
import { reducer as productSetReducer } from "./productSet/slice";
import { reducer as productReducer } from "./product/slice";
import { reducer as statisticsReducer } from "./statistics/slice";
import { reducer as articleReducer } from "./article/slice";
import { reducer as cdrReducer } from "./cdr/slice";
import { reducer as smsReducer } from "./sms/slice";
import { reducer as adminVipReducer } from "./adminVip/slice";

export const store = configureStore({
  reducer: {
    // system: systemReducer,
    chat: chatReducer,
    phone: phoneReducer,
    user: userReducer,
    product: productReducer,
    productSet: productSetReducer,
    order: orderReducer,
    events: eventsReducer,
    statistics: statisticsReducer,
    clients: clientsReducer,
    cdr: cdrReducer,
    information: informationReducer,
    script: scriptReducer,
    mentee: menteeReducer,
    article: articleReducer,
    sms: smsReducer,
    adminVip: adminVipReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<Actions extends Action> = ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Actions
>;
