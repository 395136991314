import { IMentee } from "../../api";

export enum ACTION {
  FETCH = "MENTEE_FETCH",
  SUCCESS = "MENTEE_SUCCESS",
  ERROR = "MENTEE_ERROR",

  OPERATION_FETCH = "MENTEE_OPERATION_FETCH",
  OPERATION_SUCCESS = "MENTEE_OPERATION_SUCCESS",
  OPERATION_ERROR = "MENTEE_OPERATION_ERROR",
}

export enum OperationStatus {
  SUCCESS = 1,
  ERROR,
}

interface IFetchAction {
  type: ACTION.FETCH | ACTION.OPERATION_FETCH;
}

interface IErrorAction {
  type: ACTION.ERROR | ACTION.OPERATION_ERROR;
}

interface ISuccessAction {
  type: ACTION.SUCCESS;
  payload: IMentee[];
}

interface ISuccessOperationAction {
  type: ACTION.OPERATION_SUCCESS;
  payload: IMentee;
}

export type IMenteeActionTypes =
  | IFetchAction
  | IErrorAction
  | ISuccessAction
  | ISuccessOperationAction;

export interface IMenteeState {
  isLoading: boolean;
  isFetching: boolean;
  error: string;
  users: IMentee[];
  operationStatus: OperationStatus | null;
}
