import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  IOrderContentEditor,
  IProduct,
  IProductCategory,
  IProductCategoryGroup,
  IProductListRequest,
} from "../../api";
import { PaginationType } from "../../api/common-types/pagination.types";

import { AppDispatch, RootState } from "../../store";
import {
  fetchProducts,
  fetchProductCategories,
} from "../../store/product/actions";
import { ProductsList } from "./components";
import { addOrderContent } from "../../store/order/actions";

//const PRODUCT_ONLY_AVAILABLE = "productOnlyAvailable";

interface IPageProps extends RouteComponentProps {
  products: IProduct[];
  categories: IProductCategory[];
  groups: IProductCategoryGroup[];
  pagination: PaginationType | null;
  isLoading: boolean;
  fetchProducts: (request?: IProductListRequest) => void;
  fetchProductCategories: () => void;
  addOrderContent: (content: IOrderContentEditor) => void;
}

const ProductsPage: React.FC<IPageProps> = ({
  isLoading,
  products,
  pagination,
  groups,
  categories,
  fetchProducts,
  fetchProductCategories,
  addOrderContent,
}) => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState<number | null>(null);

  //  const parsedUrl = queryString.parse(this.props.location.search);
  // const page = +parsedUrl["page"] || this.state.page;
  // const statusParse = parsedUrl.status
  //   ? `${parsedUrl.status}`
  //   : this.state.status;
  // const search = parsedUrl.search ? `${parsedUrl.search}` : this.state.search;

  useEffect(() => {
    fetchProducts({ search });
    fetchProductCategories();
  }, []);

  // const fetchData = async () => {
  //   this.setState({ loading: true });
  //   const parsedUrl = queryString.parse(this.props.location.search);
  //   const page = +parsedUrl["page"] || this.state.page;
  //   const statusParse = parsedUrl.status
  //     ? `${parsedUrl.status}`
  //     : this.state.status;
  //   const search = parsedUrl.search ? `${parsedUrl.search}` : this.state.search;

  //   // const status: InformationStatusType =
  //   //   statusParse === InformationStatusType.NEW ||
  //   //   statusParse === InformationStatusType.CONFIRMED
  //   //     ? statusParse
  //   //     : this.state.status;

  //   const { data, pagination } = await ProductAPI.search({
  //     page,
  //     search,
  //   });

  //   this.setState({
  //     data,
  //     search,
  //     pagination,
  //     loading: false,
  //   });
  // };

  const handleSetIsAvailableProduct = (isOnlyAvailable: boolean) => {
    // this.setState({ isOnlyAvailable });
    // SessionStorageService.setItem(PRODUCT_ONLY_AVAILABLE, isOnlyAvailable);
  };

  const handleChangePage = (page: number) => {
    fetchProducts({ search, page, category });
  };

  const handleSearch = (search: string) => {
    setSearch(search);
    fetchProducts({ search });
  };

  const handleChangeCategory = (category: number | null) => {
    setCategory(category);
    fetchProducts({ search, category, page: 1 });
  };

  return (
    <ProductsList
      isLoading={isLoading}
      products={products}
      search={search}
      pagination={pagination}
      groups={groups}
      categories={categories}
      activeCategory={category}
      onChangeCategory={handleChangeCategory}
      onChangePage={handleChangePage}
      onSearch={handleSearch}
      addOrderContent={addOrderContent}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.product,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    { fetchProducts, addOrderContent, fetchProductCategories },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
