import React from "react";
import { FaLayerGroup, FaChartPie, FaSms } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import {
  VscJersey,
  VscCallIncoming,
  VscUnmute,
  VscMailRead,
  VscRepoForked,
} from "react-icons/vsc";
import { FiShoppingCart, FiUsers } from "react-icons/fi";
import { Routes } from "../../router/helper";
import { IUserProfile } from "../../api";
import "./side-menu.scss";
import { RiVipFill } from "react-icons/ri";

interface Props {
  profile: IUserProfile;
}

export const SideMenu: React.FC<Props> = ({ profile }) => {
  const location = useLocation();

  const ManagerMenu: React.FC = () => (
    <>
      <NavLink
        to={Routes.orders}
        className={location.pathname.match(/^\/order/) ? "active" : ""}
      >
        <FiShoppingCart />
      </NavLink>
      <NavLink
        to={Routes.clients}
        className="nav-link"
        activeClassName="active"
      >
        <FiUsers />
      </NavLink>
      <NavLink
        to={Routes.product}
        className="nav-link"
        activeClassName="active"
      >
        <VscJersey />
      </NavLink>
      <NavLink
        to={Routes.productSet}
        className="nav-link"
        activeClassName="active"
      >
        <FaLayerGroup />
      </NavLink>
      <NavLink to={Routes.income} className="nav-link" activeClassName="active">
        <VscCallIncoming />
      </NavLink>
      <NavLink to={Routes.cdr} className="nav-link" activeClassName="active">
        <VscUnmute />
      </NavLink>
      <NavLink
        to={Routes.information}
        className="nav-link"
        activeClassName="active"
      >
        <VscMailRead />
      </NavLink>
      <NavLink
        to={Routes.statistics}
        className={location.pathname.match(/^\/statistics/) ? "active" : ""}
      >
        <FaChartPie />
      </NavLink>
    </>
  );

  const AdminMenu: React.FC = () => (
    <>
      <NavLink
        to={Routes.orders}
        className={location.pathname.match(/^\/order/) ? "active" : ""}
      >
        <FiShoppingCart />
      </NavLink>
      <NavLink
        to={Routes.articles}
        className="nav-link"
        activeClassName="active"
      >
        <VscRepoForked />
      </NavLink>
      <NavLink
        to={Routes.product}
        className="nav-link"
        activeClassName="active"
      >
        <VscJersey />
      </NavLink>
      <NavLink
        to={Routes.productSet}
        className="nav-link"
        activeClassName="active"
      >
        <FaLayerGroup />
      </NavLink>
      <NavLink
        to={Routes.sms}
        className={location.pathname.match(/^\/sms/) ? "active" : ""}
      >
        <FaSms />
      </NavLink>
      <NavLink
        to={Routes.adminVip}
        className="nav-link"
        activeClassName="active"
      >
        <RiVipFill />
      </NavLink>
    </>
  );

  return (
    <div className="side-menu">
      {profile.role === "manager-out" && <ManagerMenu />}
      {profile.role === "supermanager" && <AdminMenu />}
    </div>
  );
};

// <ManagerOutMenu
//   blockedStatus={blockedStatus}
//   callBackActiveCount={callBackActiveCount}
//   countIncome={countIncome}
//   countNewInformation={countNewInformation}
//   hasMentorRight={profile.hasMentorRight}
// />
