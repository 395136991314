import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppDispatch, RootState } from "../../store";
import {
  fetchPacketCategories,
  fetchPackets,
} from "../../store/productSet/actions";
import { addPacket } from "../../store/order/actions";
import { ProductSetPage } from "./ProductSetPage";

const mapStateToProps = (state: RootState) => ({
  ...state.productSet,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    { fetchPackets, fetchPacketCategories, addPacket },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductSetPage);
