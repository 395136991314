import { IEventState, EventActionType, ACTION } from "./types";

const initialState: IEventState = {
  isLoading: false,
  isLoadingOrderEvents: false,
  isVisible: false,
  error: "",
  countNew: 0,
  events: [],
  orderEvents: [],
};

export const reducer = (
  state = initialState,
  action: EventActionType
): IEventState => {
  switch (action.type) {
    case ACTION.EVENT_TOGGLE_VISIBILITY:
      return {
        ...state,
        isVisible: !state.isVisible,
      };
    case ACTION.EVENT_FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION.EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        events: action.payload,
      };
    case ACTION.EVENT_ORDER_FETCH:
      return {
        ...state,
        isLoadingOrderEvents: true,
      };
    case ACTION.EVENT_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingOrderEvents: false,
        orderEvents: action.payload,
      };
    case ACTION.EVENT_ORDER_ERROR:
      return {
        ...state,
        isLoadingOrderEvents: false,
        orderEvents: [],
      };
    case ACTION.GLOBAL_CHANGE_URL:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
};
