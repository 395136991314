import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import DatePicker, { registerLocale } from "react-datepicker";

import { VscInbox } from "react-icons/vsc";
import { BsBookmarkCheck, BsLightningFill } from "react-icons/bs";
import { RiDraftLine, RiTimeLine } from "react-icons/ri";
import ru from "date-fns/locale/ru";
import { RiMoneyEuroCircleLine, RiUserSearchLine } from "react-icons/ri";
import { ImEye, ImCancelCircle, ImWarning } from "react-icons/im";
import { HiOutlineViewList } from "react-icons/hi";
import { FaPlus } from "react-icons/fa";
import {
  RiMailSendLine,
  RiCheckboxCircleLine,
  RiArrowGoBackFill,
} from "react-icons/ri";

import { Order } from "./components/Order";
import { Pagination } from "../../components/common/pagination/pagination";
import { OrderStatuses, OrderStatusesRu } from "../../constants/order-statuses";
import { Routes } from "../../router/helper";
import { ModalOrderView } from "./components/ModalOrderView";
import { IOrder } from "../../api";
import { ConfirmationModal, Modal } from "../../components";
import { OrderHistory } from "./components/OrderHistory";
import { TypeOrderChange } from "../../store/order/types";
import { ModalEditComment } from "./components/ModalEditComment";
import { ModalRemoveFromDraft } from "./components/ModalRemoveFromDraft";
import { ModalConfirm } from "./components/ModalConfirm";
import { OrderBlockedType } from "../../constants/order-blocked-type";
import { ModalUnBlock } from "./components/ModalUnBlock";
import { IOrdersPageProps, OrderOperations } from "./OrdersTypes";
import { Loading } from "../../components/common/loading/Loading";
import { OrderListen } from "./components/OrderListen";

registerLocale("ru", ru);

const OrdersPage: React.FC<IOrdersPageProps> = ({
  orders,
  countStatus,
  blockedStatus,
  pagination,
  isLoading,
  isSending,
  isLoadingOrderEvents,
  orderEvents,
  lastEditedOrderId,
  isLoadingCallsToday,
  callsToday,
  hasMentorRight,
  currentUserId,
  fetchOrders,
  fetchOrderEvent,
  openClientFromOrder,
  setOrderParams,
  updateComment,
  getCallsToday,
  getCalls,
  unBlock,
}) => {
  const [status, setStatus] = useState<OrderStatuses | null>(null);
  const [blocked, setBlocked] = useState<OrderBlockedType | null>(null);
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTill, setDateTill] = useState<Date | null>(null);

  const [draftOffOrder, setDraftOffOrder] = useState<IOrder | null>(null);
  const [editOrderComment, setEditOrderComment] = useState<IOrder | null>(null);
  const [confirmOrder, setConfirmOrder] = useState<IOrder | null>(null);
  const [unBlockOrder, setUnBlockOrder] = useState<IOrder | null>(null);
  const [viewOrder, setViewOrder] = useState<IOrder | null>(null);
  const [mentee, setMentee] = useState<number | undefined>(undefined);

  const [activeOrder, setActiveOrder] = useState<IOrder | null>(null);
  const [activeOperation, setActiveOperation] =
    useState<OrderOperations | null>(null);

  const handleOpenModal = (order: IOrder, operation: OrderOperations) => {
    setActiveOrder(order);
    setActiveOperation(operation);
  };

  useEffect(() => {
    setActiveOrder(null);

    setDraftOffOrder(null);
    setConfirmOrder(null);
    setEditOrderComment(null);
    setUnBlockOrder(null);
    // window.scrollTo(0, 0);
  }, [orders]);

  useEffect(() => {
    const parsedUrl = queryString.parse(window.location.search);
    const pageStr = parsedUrl["page"];
    const page = pageStr ? +pageStr : 1;
    const statusStr = parsedUrl["status"];

    const status = statusStr ? +statusStr : null;
    setStatus(status);
    fetchOrders({ search, page, status });
  }, []);

  const handleMentee = (mentee: number | undefined) => {
    setMentee(mentee);
    fetchOrders({ search, page: 1, status, mentee });
  };

  const handleChangeDateFrom = (date: Date | null) => {
    setDateFrom(date);
  };

  const handleChangeDateTill = (date: Date | null) => {
    setDateTill(date);
  };

  const handleSearch = () => {
    fetchOrders({
      page: pagination ? +pagination.page : 1,
      search,
      status,
      dateFrom,
      dateTill,
      mentee,
    });
  };

  const handleChangePage = (page: number) => {
    fetchOrders({
      page,
      search,
      status,
      dateFrom,
      dateTill,
      blocked,
      mentee,
    });
    let url = `/orders?page=${page}`;
    if (status) url = url + `&status=${status}`;
    if (search) url = url + `&search=${search}`;
    if (blocked) url = url + `&blocked=${blocked}`;
    if (mentee) url = url + `&mentee=${mentee}`;
    window.history.pushState(null, "", url);
  };

  const handleChangeStatus = (
    // e: React.FormEvent<HTMLFormElement>,
    status: OrderStatuses | null,
    blocked: OrderBlockedType | null
  ) => {
    // e.preventDefault();
    setStatus(status);
    setBlocked(blocked);
    fetchOrders({
      page: 1,
      search,
      status,
      dateFrom,
      dateTill,
      blocked,
      mentee,
    });
    const page = pagination ? +pagination.page : 1;
    let url = `/orders?page=${page}`;
    if (status) url = url + `&status=${status}`;
    if (search) url = url + `&search=${search}`;
    if (blocked) url = url + `&blocked=${blocked}`;
    if (mentee !== undefined) url = url + `&mentee=${mentee}`;
    window.history.pushState(null, "", url);
  };

  const showMenuStatus = (
    statusItem: OrderStatuses,
    icon: JSX.Element,
    color?: string
  ) => {
    const count = countStatus
      ? countStatus.find((item) => item.status === statusItem)
      : 0;
    const cx = status === statusItem ? "nav-item item-active" : "nav-item";
    const cxCount = color ? "nav-count-status-alert" : "nav-count-status ";
    return (
      <li className={cx}>
        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-link btn-nav"
            onClick={() => {
              handleChangeStatus(statusItem, null);
            }}
          >
            <span>
              {icon} {OrderStatusesRu[statusItem]}
            </span>
          </button>

          {count && <div className={cxCount}>{count.count}</div>}
        </div>
      </li>
    );
  };

  const showMenuBlocked = (
    blockedItem: OrderBlockedType,
    blockName: string,
    icon: JSX.Element,
    count: number
  ) => {
    const cx = blockedItem === blocked ? "nav-item item-active" : "nav-item";
    const cxCount =
      blockedItem === OrderBlockedType.ALERT
        ? "nav-count-status-alert"
        : "nav-count-status-warning";

    return (
      <li className={cx}>
        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-link btn-nav"
            onClick={() => {
              handleChangeStatus(null, blockedItem);
            }}
          >
            <span>
              {icon} {blockName}
            </span>
          </button>

          {count > 0 && <div className={cxCount}>{count}</div>}
        </div>
      </li>
    );
  };

  const totalCount = countStatus
    ? countStatus.reduce((sum, status) => status.count + sum, 0)
    : 0;

  return (
    <>
      <div className="page-with-sidebar">
        <div className="row">
          <div className="col-sidebar">
            <div className="sidebar-fixed">
              <div className="card-body">
                <ul className="nav flex-column">
                  <li
                    className={`nav-item ${
                      status === null && blocked === null ? " item-active" : ""
                    }`}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        className="btn btn-link btn-nav"
                        onClick={() => {
                          handleChangeStatus(null, null);
                        }}
                      >
                        <span>
                          <HiOutlineViewList /> ВСЕ ЗАКАЗЫ
                        </span>
                      </button>
                      <div className="nav-count-status">{totalCount}</div>
                    </div>
                  </li>

                  {showMenuBlocked(
                    OrderBlockedType.ALERT,
                    "Требует звонок",
                    <BsLightningFill className="text-danger" />,
                    blockedStatus.countAlert
                  )}
                  {showMenuBlocked(
                    OrderBlockedType.WARNING,
                    "Требует действие",
                    <ImWarning className="text-warning" />,
                    blockedStatus.countWarning
                  )}

                  {showMenuStatus(OrderStatuses.DRAFT_0, <RiDraftLine />)}
                  <div className="dropdown-divider"></div>
                  {showMenuStatus(
                    OrderStatuses.WAIT_DATE_RESERVE_1,
                    <RiTimeLine />
                  )}
                  {/* {showMenuStatus(OrderStatuses.WAIT_RESERVE_2, <RiTimeLine />)} */}

                  {showMenuStatus(
                    OrderStatuses.WAIT_CONFIRM_3,
                    <BsBookmarkCheck />,
                    "alert"
                  )}
                  {showMenuStatus(OrderStatuses.ON_CHECK_5, <ImEye />)}
                  {showMenuStatus(OrderStatuses.ON_PACK_6, <VscInbox />)}
                  {showMenuStatus(OrderStatuses.SENDED_7, <RiMailSendLine />)}
                  {showMenuStatus(
                    OrderStatuses.AT_POST_8,
                    <RiUserSearchLine />
                  )}

                  {showMenuStatus(
                    OrderStatuses.WAITING_PAYMENT_9,
                    <RiMoneyEuroCircleLine />
                  )}
                  <div className="dropdown-divider"></div>
                  {showMenuStatus(
                    OrderStatuses.COMPLETED_10,
                    <RiCheckboxCircleLine />
                  )}
                  {showMenuStatus(
                    OrderStatuses.RETURN_14,
                    <RiArrowGoBackFill />
                  )}
                  {showMenuStatus(OrderStatuses.CANCEL_11, <ImCancelCircle />)}
                </ul>
              </div>
            </div>
          </div>
          <div className="col orders__list">
            <div className=" subheader-with-sidebar row">
              <div className="col">
                <Link to={Routes.orderCreate} className="nav-link">
                  <FaPlus /> Новый заказ
                </Link>
              </div>
              <div className="col-2">
                <DatePicker
                  dateFormat="dd MMMM yyyy"
                  locale={ru}
                  selected={dateFrom}
                  onChange={handleChangeDateFrom}
                  placeholderText="Создан с"
                  className="form-control"
                />
              </div>
              <div className="col-2">
                <DatePicker
                  dateFormat="dd MMMM yyyy"
                  locale={ru}
                  selected={dateTill}
                  onChange={handleChangeDateTill}
                  placeholderText="Создан по"
                  className="form-control "
                />
              </div>
              <div className="col-4">
                <div className="input-group">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Номер или данные"
                    aria-label="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />

                  <button
                    className="btn btn-outline-success my-2 my-sm-0"
                    type="button"
                    onClick={handleSearch}
                  >
                    Поиск
                  </button>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            {hasMentorRight && (
              <div className="card mt-2 bg-light">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      {/* Количество учеников: {students.length} */}
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                          checked={!mentee}
                          onClick={() => {
                            handleMentee(undefined);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Мои заказы
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                          checked={mentee !== undefined}
                          onClick={() => {
                            handleMentee(0);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          Заказы моих учеников
                        </label>
                      </div>
                    </div>
                    {mentee !== undefined && (
                      <div className="col-md- ">
                        <select
                          className="form-select"
                          name="typeDelivery"
                          value={"0"}
                          onChange={() => {}}
                          required
                        >
                          <option value={"0"}>Все менеджеры</option>
                          <option value={"2"}>Белпочта</option>
                        </select>
                      </div>
                    )}
                    <div className=""></div>
                  </div>
                </div>
              </div>
            )}

            {isLoading ? (
              <Loading />
            ) : orders.length > 0 ? (
              orders.map((order) => (
                <Order
                  order={order}
                  key={order.orderId}
                  lastEditedOrderId={lastEditedOrderId}
                  currentUserId={currentUserId}
                  onOpenClient={openClientFromOrder}
                  onOpenOrder={setViewOrder}
                  onEditComment={setEditOrderComment}
                  onOpenDraftOff={setDraftOffOrder}
                  onOpenConfirm={setConfirmOrder}
                  onUnBlock={setUnBlockOrder}
                  handleOpenModal={handleOpenModal}
                />
              ))
            ) : (
              <h3 className="page-message-empty">Заказы не найдены</h3>
            )}
          </div>
        </div>

        <br />
        {!isLoading && pagination && +pagination.totalPages > 1 && (
          <Pagination pagination={pagination} onChangePage={handleChangePage} />
        )}
        <Modal isModalOpen={!!viewOrder} onClose={() => setViewOrder(null)}>
          {viewOrder && <ModalOrderView order={viewOrder} />}
        </Modal>
      </div>

      {activeOrder && (
        <>
          {activeOperation === OrderOperations.HISTORY && (
            <Modal isModalOpen={true} onClose={() => setActiveOrder(null)}>
              <OrderHistory
                order={activeOrder}
                isLoading={isLoadingOrderEvents}
                events={orderEvents}
                fetchOrderEvent={fetchOrderEvent}
              />
            </Modal>
          )}
          {activeOperation === OrderOperations.LISTEN && (
            <Modal isModalOpen={true} onClose={() => setActiveOrder(null)}>
              <OrderListen
                order={activeOrder}
                isLoading={isLoadingCallsToday}
                data={callsToday}
                getCalls={getCalls}
              />
            </Modal>
          )}
          {activeOperation === OrderOperations.CANCEL && (
            <ConfirmationModal
              header="Отменить заказ?"
              message="После выполнения этой оперции заказ нельзя изменить"
              onConfirm={() => {
                setOrderParams({
                  orderId: activeOrder.orderId,
                  type: TypeOrderChange.CANCEL,
                });
              }}
              onClose={() => {
                setActiveOrder(null);
              }}
              isModalOpen={true}
            />
          )}
          {activeOperation === OrderOperations.DRAFT && (
            <ConfirmationModal
              header="Перевести в черновик?"
              onConfirm={() => {
                setOrderParams({
                  orderId: activeOrder.orderId,
                  type: TypeOrderChange.DRAFT,
                });
              }}
              onClose={() => {
                setActiveOrder(null);
              }}
              isModalOpen={true}
            />
          )}
        </>
      )}

      {editOrderComment && (
        <Modal
          isModalOpen={!!editOrderComment}
          onClose={() => setEditOrderComment(null)}
        >
          <ModalEditComment
            order={editOrderComment}
            isSending={isSending}
            updateComment={updateComment}
          />
        </Modal>
      )}
      {draftOffOrder && (
        <Modal
          isModalOpen={!!draftOffOrder}
          onClose={() => setDraftOffOrder(null)}
        >
          <ModalRemoveFromDraft
            order={draftOffOrder}
            isLoadingCallsToday={isLoadingCallsToday}
            callsToday={callsToday}
            isSending={isSending}
            getCallsToday={getCallsToday}
            onConfirm={() => {
              setOrderParams({
                orderId: draftOffOrder.orderId,
                type: TypeOrderChange.FROM_DRAFT,
              });
            }}
          />
        </Modal>
      )}
      {confirmOrder && (
        <Modal
          isModalOpen={!!confirmOrder}
          onClose={() => setConfirmOrder(null)}
        >
          <ModalConfirm
            order={confirmOrder}
            isLoadingCallsToday={isLoadingCallsToday}
            callsToday={callsToday}
            getCallsToday={getCallsToday}
            isSending={isSending}
            onConfirm={() => {
              setOrderParams({
                orderId: confirmOrder.orderId,
                type: TypeOrderChange.CONFIRM,
              });
            }}
          />
        </Modal>
      )}
      {unBlockOrder && (
        <Modal
          isModalOpen={!!unBlockOrder}
          onClose={() => setUnBlockOrder(null)}
        >
          <ModalUnBlock
            order={unBlockOrder}
            isLoadingCallsToday={isLoadingCallsToday}
            callsToday={callsToday}
            getCallsToday={getCallsToday}
            isSending={isSending}
            onConfirm={() => unBlock(unBlockOrder.orderId)}
          />
        </Modal>
      )}
    </>
  );
};

export default OrdersPage;
