import { ProductAPI, IProductListRequest } from "../../api";
import { actions } from "./slice";
import { toastError } from "../../services/toast/toast";
const { loading, successCategory, successProducts, error } = actions;

export const fetchProducts =
  (request?: IProductListRequest) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response = await ProductAPI.getProducts(request);
      dispatch(successProducts(response));
    } catch (e) {
      dispatch(error());
      toastError(`Ошибка загрузки`);
    }
  };

export const fetchProductCategories = () => async (dispatch: any) => {
  try {
    const response = await ProductAPI.getCategories();
    dispatch(successCategory(response));
  } catch (e) {
    toastError(`Ошибка загрузки`);
  }
};
