import { IOrder } from "../order/order.types";
import { CdrType } from "../cdr/cdr.types";
import { ClientActiveType } from "../../store/clients/types";
import { PaginationType } from "../common-types/pagination.types";
import { OrderStatuses } from "../../constants";

export enum ClientStateType {
  NO_CALLED = "",
  BAN = "ban",
  BLACKLIST = "blacklist",
  BLOCK = "block",
  CALLBACK = "callback",
  NO_ANSWER = "noanswer",
  UNAVAILABLE = "unavailable",
  WRONG = "wrong",
  CALLED = "called", // special
}

export interface IClient {
  clientId: number;
  network: string;
  fullName: string;
  isPremium: boolean;
  city: string;
  lastDateCall: number;
  comment: string;
  status: ClientStateType;
  dateCallback: number;
  countNoAnswer: number;
  isUsedByAnother?: boolean;
}

export interface IClientDetails {
  clientId: number;
  fullName: string;
  isPremium: boolean;
  firstName: string;
  lastName: string;
  patronymic: string;
  zipCode: string;
  area: string;
  region: string;
  city: string;
  street: string;
  house: string;
  building: string;
  apartment: string;
  comment: string;
  status: string;
}

export interface IClientEditor {
  phone: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  zipCode: string;
  area: string;
  region: string;
  city: string;
  street: string;
  house: string;
  building: string;
  apartment: string;
}

export interface IClientProduct {
  status: OrderStatuses;
  dateConfirm: string;
  article: string;
  count: number;
  countConfirm: number;
  productName: string;
  orderId?: number;
}

export interface IClientCard {
  isClientAvailable: boolean;
  calls: CdrType[];
  clientProducts: IClientProduct[];
  errorMessage: string;
  client: IClientDetails;
}

export interface IClientSearch {
  search: string;
}

export interface IClientUpdateStatus {
  clientId: number;
  status: ClientStateType;
  comment?: string;
  dateCallback?: Date;
}

export interface ICommonResponse {
  result: string;
}

export interface IClientsListRequest {
  type: ClientActiveType;
  page?: number;
  search?: string;
}

export interface IClientsPage {
  pagination: PaginationType;
  data: IClient[];
}
