import { format } from "date-fns";
import { AppThunk } from "..";
import {
  OrderAPI,
  IOrderContentEditor,
  IOrderListRequest,
  IProductSet,
  IOrderEditor,
  CdrAPI,
  EuroPostAPI,
} from "../../api";
import { toastError, toastSuccess } from "../../services/toast/toast";
import {
  ACTION,
  IOrderActionTypes,
  TypeOrderChange,
  IOrdersSetParams,
  IOrdersUpdateComment,
} from "./types";
import { FORM_ORDER_EDITOR_KEY } from "../../constants/storage";

export const fetchOrders =
  (request: IOrderListRequest): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDERS_FETCH,
      });

      const formatRequest = {
        ...request,
        dateFrom: request.dateFrom
          ? format(request.dateFrom as Date, "yyyy-MM-dd")
          : undefined,
        dateTill: request.dateTill
          ? format(request.dateTill as Date, "yyyy-MM-dd")
          : undefined,
      };

      const [response, countStatus, countBlocked] = await Promise.all([
        OrderAPI.getAll(formatRequest),
        OrderAPI.getCountStatus({ mentee: request.mentee }),
        OrderAPI.getCountBlocked({ mentee: request.mentee }),
      ]);

      dispatch({
        type: ACTION.ORDERS_SUCCESS,
        payload: { ...response, countStatus, countBlocked },
      });
    } catch (error) {
      dispatch({
        type: ACTION.ORDERS_ERROR,
      });
    }
  };

export const fetchBlockedOrders =
  (request?: IOrderListRequest): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      const countBlocked = await OrderAPI.getCountBlocked({
        mentee: request?.mentee,
      });
      dispatch({
        type: ACTION.ORDERS_BLOCKED_SUCCESS,
        payload: countBlocked,
      });
    } catch (error) {
      dispatch({
        type: ACTION.ORDERS_ERROR,
      });
    }
  };

export const fetchEditableOrder =
  (orderId: number): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDERS_FETCH,
      });
      const response = await OrderAPI.getEditableOrder(orderId);

      dispatch({
        type: ACTION.ORDER_EDITOR_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ACTION.ORDERS_ERROR,
      });
    }
  };

export const fetchOrderCalculate =
  (
    content: IOrderContentEditor[],
    typeDelivery: string
  ): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      // dispatch({
      //   type: ACTION.ORDERS_FETCH,
      // });

      const response = await OrderAPI.calculate({ content, typeDelivery });

      dispatch({
        type: ACTION.ORDER_CALCULATE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      // dispatch({
      //   type: ACTION.ORDERS_ERROR,
      // });
    }
  };

export const clearOrderEditor = () => (dispatch: any) => {
  dispatch({
    type: ACTION.ORDER_CLEAR_EDITOR,
  });
  sessionStorage.removeItem(FORM_ORDER_EDITOR_KEY);
};

export const addOrderContent =
  (content: IOrderContentEditor) => (dispatch: any) => {
    toastSuccess("Товар добавлен в текущий заказ");
    dispatch({
      type: ACTION.ORDER_ADD_CONTENT,
      payload: content,
    });
  };

export const updateOrderContent =
  (content: IOrderContentEditor) => (dispatch: any) => {
    dispatch({
      type: ACTION.ORDER_UPDATE_CONTENT,
      payload: content,
    });
  };

export const deleteOrderContent =
  (content: IOrderContentEditor) => (dispatch: any) => {
    dispatch({
      type: ACTION.ORDER_DELETE_CONTENT,
      payload: content,
    });
  };

export const addPacket = (packet: IProductSet) => (dispatch: any) => {
  toastSuccess("Пакет добавлен в текущий заказ");
  dispatch({
    type: ACTION.ORDER_ADD_PACKET,
    payload: packet,
  });
};

export const createOrder =
  (order: IOrderEditor): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDER_SEND,
      });

      const response = await OrderAPI.create(order);
      toastSuccess("Заказ сохранен");
      dispatch(clearOrderEditor());
      dispatch({
        type: ACTION.ORDER_CREATE_SUCCESS,
        payload: response.orderId,
      });
    } catch (error) {
      toastError(`Ошибка: ${error}`);
      dispatch({
        type: ACTION.ORDER_SEND_ERROR,
      });
    }
  };

export const updateOrder =
  (order: IOrderEditor): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDER_SEND,
      });

      const response = await OrderAPI.update(order);
      toastSuccess("Заказ изменен");
      dispatch(clearOrderEditor());
      dispatch({
        type: ACTION.ORDER_UPDATE_SUCCESS,
        payload: response.orderId,
      });
    } catch (error) {
      toastError(`Ошибка: ${error}`);
      dispatch({
        type: ACTION.ORDER_SEND_ERROR,
      });
    }
  };

export const setOrderParams =
  (request: IOrdersSetParams): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDER_SEND,
      });
      let order = null;

      switch (request.type) {
        case TypeOrderChange.CANCEL:
          order = await OrderAPI.setCancel(request.orderId);
          break;
        case TypeOrderChange.DRAFT:
          order = await OrderAPI.setDraft(request.orderId);
          break;
        case TypeOrderChange.FROM_DRAFT:
          order = await OrderAPI.setFromDraft(request.orderId);
          break;
        case TypeOrderChange.CONFIRM:
          order = await OrderAPI.setConfirm(request.orderId);
          break;
      }

      if (order) {
        toastSuccess("Сохранено");
        dispatch(fetchBlockedOrders());
        dispatch({
          type: ACTION.ORDER_SEND_SUCCESS,
          payload: order,
        });
      }
    } catch (error) {
      toastError("Ошибка сохранения");
      dispatch({
        type: ACTION.ORDER_SEND_ERROR,
      });
    }
  };

export const updateComment =
  (request: IOrdersUpdateComment): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDER_SEND,
      });
      const order = await OrderAPI.updateComment(request);
      toastSuccess("Комментарий сохранен");
      dispatch({
        type: ACTION.ORDER_SEND_SUCCESS,
        payload: order,
      });
    } catch (error) {
      toastError("Ошибка сохранения");
      dispatch({
        type: ACTION.ORDER_SEND_ERROR,
      });
    }
  };

export const getCallsToday =
  (orderId: number): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDER_CALLS_FETCH,
      });
      const cdr = await CdrAPI.getTodayFromOrder(orderId);
      dispatch({
        type: ACTION.ORDER_CALLS_SUCCESS,
        payload: cdr,
      });
    } catch (error) {
      dispatch({
        type: ACTION.ORDER_CALLS_ERROR,
      });
    }
  };

export const getCalls =
  (orderId: number): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDER_CALLS_FETCH,
      });
      const cdr = await CdrAPI.getCalls(orderId);
      dispatch({
        type: ACTION.ORDER_CALLS_SUCCESS,
        payload: cdr,
      });
    } catch (error) {
      dispatch({
        type: ACTION.ORDER_CALLS_ERROR,
      });
    }
  };

export const unBlock =
  (orderId: number): AppThunk<IOrderActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.ORDER_SEND,
      });
      const order = await OrderAPI.unBlock(orderId);
      toastSuccess("Блокировка снята");
      dispatch({
        type: ACTION.ORDER_SEND_SUCCESS,
        payload: order,
      });
      dispatch(fetchBlockedOrders());
    } catch (error) {
      toastError("Ошибка сохранения");
      dispatch({
        type: ACTION.ORDER_SEND_ERROR,
      });
    }
  };

export const getEuroOffices =
  (): AppThunk<IOrderActionTypes> => async (dispatch) => {
    try {
      const offices = await EuroPostAPI.getOffices();

      const updatedOffices = offices
        .map((item) => ({
          ...item,
          Info1: `${item.Info1} ( ${item.WarehouseName} )`,
          WarehouseName: item.WarehouseName.replace(
            /Отделение № ?\d+ ?,? ? (гг.|а.г.|г.)? ?/i,
            ""
          ),
        }))
        .sort((a, b) => a.WarehouseName.localeCompare(b.WarehouseName));
      dispatch({
        type: ACTION.ORDERS_EURO_OFFICES_SUCCESS,
        payload: updatedOffices,
      });
    } catch (error) {
      toastError("Ошибка получения списка офисов");
    }
  };
