import { AppThunk } from "..";
import { goToAuthorize } from "../..";
import { IChangePassword, UserAPI, UserType } from "../../api";
import { UserActionTypes, ACTION } from "./types";

export function setCurrentUser(currentUser: UserType): UserActionTypes {
  return {
    type: ACTION.USER_SET_CURRENT_USER_DATA,
    payload: currentUser,
  };
}

export const fetchProfile =
  (): AppThunk<UserActionTypes> => async (dispatch) => {
    try {
      dispatch({
        type: ACTION.FETCH,
      });
      const response = await UserAPI.getProfile();
      dispatch({
        type: ACTION.SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ACTION.ERROR,
      });
    }
  };

export const fetchChangePassword =
  (request: IChangePassword): AppThunk<UserActionTypes> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION.USER_CHANGE_FETCH,
      });
      await UserAPI.changePassword(request);
      localStorage.removeItem("JWT");
      goToAuthorize();
    } catch (error) {
      dispatch({
        type: ACTION.USER_CHANGE_ERROR,
      });
    }
  };
