import React, { useState } from "react";
import { generatePath, Link, NavLink } from "react-router-dom";
import { BiUser } from "react-icons/bi";
import {
  VscCircleFilled,
  VscNewFile,
  VscRunAll,
  VscSaveAs,
} from "react-icons/vsc";
import { generateOrderEditPath, Routes } from "../../router/helper";
import { IBlockedStatus, IOrderEditor, IUserProfile } from "../../api";
import { ManagerOutMenu } from "./components/ManagerOutMenu";
import { AdminMenu } from "./components/AdminMenu";
import "./header.scss";
import { FaComments, FaPhone } from "react-icons/fa";
import { Modal } from "../modal/modal";

import { useHistory, useLocation } from "react-router-dom";

interface Props {
  profile: IUserProfile;
  callBackActiveCount: number;
  blockedStatus: IBlockedStatus;
  countNewInformation: number;
  countIncome: number;
  onLogout: () => void;
  ///
  editableOrder: IOrderEditor;
  isPhoneVisible: boolean;
  isChatVisible: boolean;
  isEventsVisible: boolean;
  isScriptVisible: boolean;
  contentCount: number;
  togglePhoneVisibility: () => void;
  toggleChatVisibility: () => void;
  toggleEventsVisibility: () => void;
  toggleScriptVisibility: () => void;
  clearOrderEditor: () => void;
}

export const Header: React.FunctionComponent<Props> = ({
  profile,
  callBackActiveCount,
  blockedStatus,
  countNewInformation,
  countIncome,
  onLogout,
  //
  editableOrder,
  isPhoneVisible,
  isChatVisible,
  isEventsVisible,
  isScriptVisible,
  contentCount,
  togglePhoneVisibility,
  toggleChatVisibility,
  toggleEventsVisibility,
  toggleScriptVisibility,
  clearOrderEditor,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1">LightCRM</span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <div className="me-auto">
              <button
                // className={
                //   isScriptVisible
                //     ? "side-button side-button-active"
                //     : "side-button"
                // }
                className="btn btn-link"
                onClick={toggleScriptVisibility}
              >
                <VscRunAll />
                Скрипт
              </button>
              <button
                // className="side-button"
                className="btn btn-link"
                onClick={() => {
                  editableOrder.orderId
                    ? setIsModalOpen(true)
                    : history.push(generatePath(Routes.orderCreate));
                }}
              >
                {!editableOrder.orderId && contentCount > 0 && (
                  <div className="badge badge-sidebar">{contentCount} </div>
                )}
                <VscNewFile />
                Новый заказ
              </button>
              {editableOrder.orderId && (
                <button
                  className="side-button side-button-active-2"
                  onClick={() =>
                    history.push(
                      generateOrderEditPath(`${editableOrder.orderId}`)
                    )
                  }
                >
                  <div className="badge badge-sidebar">{contentCount}</div>
                  <VscSaveAs />
                  {editableOrder.orderId}
                </button>
              )}
              <ul className="navbar-nav ">
                {/* <li className="nav-item">
              <NavLink
                to={Routes.orders}
                className="nav-link"
                activeClassName="active"
              >
                Заказы
                {blockedStatus.countAlert > 0 ? (
                  <span className="marker-alert">
                    <VscCircleFilled />
                  </span>
                ) : (
                  blockedStatus.countWarning > 0 && (
                    <span className="marker-warning">
                      <VscCircleFilled />
                    </span>
                  )
                )}
              </NavLink>
            </li>
            {profile.role === "manager-out" && (
              <ManagerOutMenu
                blockedStatus={blockedStatus}
                callBackActiveCount={callBackActiveCount}
                countIncome={countIncome}
                countNewInformation={countNewInformation}
                hasMentorRight={profile.hasMentorRight}
              />
            )}
            {profile.role === "supermanager" && <AdminMenu />} */}
              </ul>
            </div>
            <div>
              <button
                // className={
                //   isPhoneVisible
                //     ? "side-button side-button-active"
                //     : "side-button"
                // }
                className="btn btn-link"
                onClick={togglePhoneVisibility}
              >
                <FaPhone />
                Телефон
              </button>

              <button
                // className={
                //   isChatVisible
                //     ? "side-button side-button-active"
                //     : "side-button"
                // }
                className="btn btn-link"
                onClick={toggleChatVisibility}
              >
                <FaComments />
                Чат
              </button>
              {/* <button
          className={
            isEventsVisible ? "side-button side-button-active" : "side-button"
          }
          onClick={toggleEventsVisibility}
        >
          <VscOutput />
          История событий
        </button> */}
            </div>
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BiUser /> {profile.userName}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link to={Routes.account} className="dropdown-item">
                    Учетная запись
                  </Link>
                  <Link to={Routes.settings} className="dropdown-item">
                    Настройки
                  </Link>
                  <Link to={Routes.help} className="dropdown-item">
                    Помощь
                  </Link>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#" onClick={onLogout}>
                    Выход
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Modal onClose={() => setIsModalOpen(false)} isModalOpen={isModalOpen}>
        <h3 className="mt-5">Выйти из редактирования заказа?</h3>
        <div className="text-right mt-5">
          <button
            className="btn btn-danger m-2"
            onClick={() => {
              clearOrderEditor();
              setIsModalOpen(false);
              history.push(generatePath(Routes.orderCreate));
            }}
          >
            ДА, БЕЗ СОХРАНЕНИЯ
          </button>
          {editableOrder.orderId && (
            <button
              className="btn btn-primary m-2"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              ОТМЕНА
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};
