export const SERVER_URL =
  process.env.NODE_ENV === "production"
    ? "https://tmshop.by"
    : "http://localhost:4000";

export const SERVER_WS =
  process.env.NODE_ENV === "production"
    ? "wss://tmshop.by/ws"
    : "ws://localhost:4000/ws";

export const IS_USE_CDR = process.env.NODE_ENV === "production" ? true : false;

export const SERVER_SIP_HOST = "tmshop.by";
export const SERVER_SIP_PORT = 5443;
