import * as React from "react";
import RiseLoader from "react-spinners/RiseLoader";

import "./loading.scss";
// const override = css`
//   display: block;
//   margin: 0 auto;
//   self-align: center;
// `;

export const Loading = () => {
  return (
    <div className="loading">
      <RiseLoader size={20} margin={2} color={"#36d7b7"} />
    </div>
  );
};
