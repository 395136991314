import {
  ISendSmsRequest,
  ISmsClientBase,
  ISmsOutcomePage,
  ISmsOutcomeRequest,
} from "./sms.types";
import { APIService } from "../../services/api-service";
import {
  IMailing,
  IMailingCreateRequest,
  IMailingUpdateRequest,
  ISmsCalculateRequest,
  ISmsCalculateResponse,
  ISmsHistory,
  ISmsIncome,
  ISmsIncomePage,
  ISmsIncomeRequest,
  ISmsLine,
  ISmsStatistics,
} from "..";

export const getClientBases = () => {
  const url = "api/v1/sms/bases";
  return APIService.get<ISmsClientBase[]>(url);
};

export const getList = () => {
  const url = "api/v1/sms/list";
  return APIService.get<IMailing[]>(url);
};

export const calculate = (request: ISmsCalculateRequest) => {
  const url = "api/v1/sms/calculate";
  return APIService.post<ISmsCalculateRequest, ISmsCalculateResponse>(
    url,
    request
  );
};

export const create = (request: IMailingCreateRequest) => {
  const url = "api/v1/sms/mailing";
  return APIService.post<IMailingCreateRequest, { id: number }>(url, request);
};

export const update = (request: IMailingUpdateRequest) => {
  const url = "api/v1/sms/mailing";
  return APIService.put<IMailingUpdateRequest, IMailing>(url, request);
};

export const remove = (id: number) => {
  const url = `api/v1/sms/mailing/${id}`;
  return APIService.delete<number>(url, id);
};

export const start = (id: number) => {
  const url = `api/v1/sms/start/${id}`;
  return APIService.post<number>(url, id);
};

export const stop = (id: number) => {
  const url = `api/v1/sms/stop/${id}`;
  return APIService.post<number>(url, id);
};

export const reset = (id: number) => {
  const url = `api/v1/sms/reset/${id}`;
  return APIService.post<number>(url, id);
};

export const statistics = () => {
  const url = `api/v1/sms/statistics`;
  return APIService.get<number, ISmsStatistics[]>(url);
};

export const lines = () => {
  const url = "api/v1/sms/lines";
  return APIService.get<number, ISmsLine[]>(url);
};

export const income = (request?: ISmsIncomeRequest) => {
  const url = "api/v1/sms/income";
  return APIService.get<ISmsIncomeRequest, ISmsIncomePage>(url, request);
};

export const outcome = (request?: ISmsOutcomeRequest) => {
  const url = "api/v1/sms/outcome";
  return APIService.get<ISmsOutcomeRequest, ISmsOutcomePage>(url, request);
};

export const history = () => {
  const url = "api/v1/sms/history";
  return APIService.get<ISmsHistory[]>(url);
};

export const send = (request: ISendSmsRequest) => {
  const url = "api/v1/sms/send";
  return APIService.post<ISendSmsRequest>(url, request);
};

export const searchInMailing = (phone: string) => {
  const url = "api/v1/sms/search";
  return APIService.post<{ phone: string }, string[]>(url, { phone });
};

export const removeFromMailing = (phone: string) => {
  const url = `api/v1/sms/remove-client/${phone}`;
  return APIService.delete<{ phone: string }>(url, { phone });
};
