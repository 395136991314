import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { addDays, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import ru from "date-fns/locale/ru";

import "./editor.scss";

import {
  ICalculateResponse,
  IClientDetails,
  IEuroPostOffice,
  IOrderContentEditor,
  IOrderEditor,
} from "../../../api";
import { VscAdd } from "react-icons/vsc";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { Routes } from "../../../router/helper";
import { DeliveryType } from "../../../constants";
import { toastSuccess } from "../../../services/toast/toast";
import { InputWithLabel } from "../../../components/InputWithLabel/InputWithLabel";

interface Props {
  orderEditor: IOrderEditor & ICalculateResponse;
  client: IClientDetails | null;
  isSending: boolean;
  euroPostOffices: IEuroPostOffice[];
  updateOrderContent: (content: IOrderContentEditor) => void;
  deleteOrderContent: (content: IOrderContentEditor) => void;
  cacheData: (data: any, url?: string) => void;
  getCachedData: () => IOrderEditor;
  createOrder: (order: IOrderEditor) => void;
  updateOrder: (order: IOrderEditor) => void;
  clearOrderEditor: () => void;
}

const PHONE_FROM_CLIENT_ID = "00000000000";
const PHONE_FROM_SAVED_ORDER = "99999999999";

const calculateShiftDaysDelivery = (
  typeDelivery: DeliveryType,
  currentDate: Date
): number => {
  switch (typeDelivery) {
    case DeliveryType.post:
      return 7;
    case DeliveryType.eu_d:
      return 7;
    case DeliveryType.eu_s:
      return 2;
    case DeliveryType.c_rb:
      return 13;
    case DeliveryType.c_mi:
      return 1;
    case DeliveryType.self:
      return format(currentDate, "HH:mm") > "13.30" ? 1 : 0;
    default:
      return 0;
  }
};

export const OrderForm: React.FC<Props> = ({
  orderEditor,
  client,
  isSending,
  euroPostOffices,
  updateOrderContent,
  deleteOrderContent,
  cacheData,
  getCachedData,
  createOrder,
  updateOrder,
  clearOrderEditor,
}) => {
  const [formData, setFormData] = useState({
    ...orderEditor,
    ...getCachedData(),
  });

  cacheData(null);

  useEffect(() => {
    //setFormData({ ...orderEditor, ...getCachedData() });
    //cacheData(null);
    setPhoneReady(
      formData.phone === PHONE_FROM_CLIENT_ID ||
        formData.phone === PHONE_FROM_SAVED_ORDER
    );
  }, [orderEditor]);

  const [phoneReady, setPhoneReady] = useState(false);

  useEffect(() => {
    if (
      formData.phone === PHONE_FROM_CLIENT_ID ||
      formData.phone === PHONE_FROM_SAVED_ORDER
    ) {
      setPhoneReady(true);
    }
  }, []);

  const handleCancel = () => {
    window.history.back();
    clearOrderEditor();
  };

  const loadClientFromPhone = () => {
    if (client) {
      const {
        firstName,
        lastName,
        patronymic,
        zipCode,
        city,
        street,
        house,
        building,
        apartment,
        clientId,
      } = client;

      setFormData({
        ...formData,
        firstName,
        lastName,
        patronymic,
        zipCode,
        city,
        street,
        house,
        building,
        apartment,
        phone: PHONE_FROM_CLIENT_ID,
        clientId,
      });

      setPhoneReady(true);
      toastSuccess("Контактые данные загужены");
    }
  };

  const currentDate = new Date();

  const Schema = Yup.object().shape({
    typeDelivery: Yup.string(),
    dateDelivery: Yup.date()
      .required("Обязательное поле")
      .max(addDays(currentDate, 30), "Дата более 30 дней вперед")
      .when("typeDelivery", (type: DeliveryType) => {
        return Yup.date()
          .required("Обязательное поле")
          .max(addDays(currentDate, 30), "Дата более 30 дней вперед")
          .min(
            addDays(
              currentDate,
              calculateShiftDaysDelivery(type, currentDate) - 1
            ),
            "Невозможно доставить на данную дату"
          );
      }),
    phone: Yup.string()
      .matches(/^\d{11}$/, "Номер должен состоять из 11 цифр (80291234567)")
      .required("Введите номер телефона или загрузите из телефона"),

    firstName: Yup.string()
      .min(3, "Минимальная длина должны быть более 3 символов")
      .max(50, "Поле должно быть менее 50 символов")
      .required("Обязательное поле"),
    lastName: Yup.string()
      .max(50, "Поле должно быть менее 50 символов")
      .when("typeDelivery", {
        is: (type: DeliveryType) =>
          [DeliveryType.post, DeliveryType.eu_d, DeliveryType.eu_s].includes(
            type
          ),
        then: Yup.string().required("Обязательное поле"),
        otherwise: Yup.string(),
      }),
    patronymic: Yup.string()
      .max(50, "Поле должно быть менее 50 символов")
      .when("typeDelivery", {
        is: (type: DeliveryType) =>
          [DeliveryType.post, DeliveryType.eu_d, DeliveryType.eu_s].includes(
            type
          ),
        then: Yup.string().required("Обязательное поле"),
        otherwise: Yup.string().nullable(),
      }),
    zipCode: Yup.string().when("typeDelivery", {
      is: (type: DeliveryType) => [DeliveryType.post].includes(type),
      then: Yup.string()
        .matches(/^\d{6}$/, "Индекс должен состоять из 6 цифр")
        .required("Обязательное поле"),
      otherwise: Yup.string().nullable(),
    }),
    area: Yup.string()
      .max(50, "Поле должно быть менее 50 символов")
      .when("typeDelivery", {
        is: (type: DeliveryType) => [DeliveryType.post].includes(type),
        then: Yup.string().required("Обязательное поле"),
        otherwise: Yup.string().nullable(),
      }),
    region: Yup.string()
      .max(20, "Поле должно быть менее 20 символов")
      .when("typeDelivery", {
        is: (type: DeliveryType) => [DeliveryType.post].includes(type),
        then: Yup.string().required("Обязательное поле"),
        otherwise: Yup.string().nullable(),
      }),
    city: Yup.string()
      .max(50, "Поле должно быть менее 50 символов")
      .when("typeDelivery", {
        is: (type: DeliveryType) =>
          [DeliveryType.post, DeliveryType.c_rb].includes(type),
        then: Yup.string().required("Обязательное поле"),
        otherwise: Yup.string(),
      }),
    street: Yup.string()
      .max(50, "Поле должно быть менее 50 символов")
      .when("typeDelivery", {
        is: (type: DeliveryType) =>
          [DeliveryType.post, DeliveryType.c_rb, DeliveryType.c_mi].includes(
            type
          ),
        then: Yup.string().required("Обязательное поле"),
        otherwise: Yup.string().nullable(),
      }),
    house: Yup.string()
      .max(20, "Поле должно быть менее 20 символов")
      .when("typeDelivery", {
        is: (type: DeliveryType) =>
          [DeliveryType.post, DeliveryType.c_rb, DeliveryType.c_mi].includes(
            type
          ),
        then: Yup.string().required("Обязательное поле"),
        otherwise: Yup.string().nullable(),
      }),
    building: Yup.string()
      .nullable()
      .max(20, "Поле должно быть менее 20 символов"),
    apartment: Yup.string()
      .nullable()
      .max(10, "Поле должно быть менее 10 символов"),
    euroPostId: Yup.string().when("typeDelivery", {
      is: (type: DeliveryType) => [DeliveryType.eu_s].includes(type),
      then: Yup.string().required("Обязательное поле"),
      otherwise: Yup.string().nullable(),
    }),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={Schema}
      onSubmit={(values) => {
        const { content, sumDiscount, sumDelivery, sumTotal, phone } =
          orderEditor;
        const valueWithContent = {
          ...values,
          content,
          sumDiscount,
          sumDelivery,
          sumTotal,
          dateDelivery: format(new Date(values.dateDelivery), "yyyy-MM-dd"),
        };
        if (orderEditor.orderId) {
          updateOrder(valueWithContent);
        } else {
          createOrder(valueWithContent);
        }
      }}
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <div className="p-3">
          <h1>
            {orderEditor.orderId
              ? `Редактирование заказа №${orderEditor.orderId}`
              : "Новый заказ"}
          </h1>

          <div className="card1 mt-4">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">п.п </th>
                  <th scope="col">Товар </th>
                  <th scope="col">Артикул</th>
                  <th scope="col" className="text-center">
                    Цена, шт.
                  </th>
                  <th scope="col" className="text-center">
                    Количество
                  </th>
                  <th scope="col" className="text-center">
                    Стоимость
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {orderEditor.content.map((content, poz) => (
                  <tr key={content.contentId}>
                    <td className="text-center">{poz + 1}</td>
                    <td>
                      <a href="#">{content.productName}</a>
                    </td>
                    <td>{content.article}</td>

                    <td className="text-right">
                      {content.productSetId ? (
                        <div className="text-muted">пакет</div>
                      ) : (
                        content.price
                      )}
                    </td>
                    <td className="text-center wi-150">
                      {!content.productSetId && (
                        <button
                          type="button"
                          className="btn btn-link changeCount"
                          onClick={(e) => {
                            if (content.count > 1) {
                              updateOrderContent({
                                ...content,
                                count: content.count - 1,
                              });
                            }
                          }}
                        >
                          <MdRemoveCircle />
                        </button>
                      )}
                      <span> {content.count} шт.</span>
                      {!content.productSetId && (
                        <button
                          type="button"
                          className="btn btn-link changeCount"
                          onClick={() =>
                            updateOrderContent({
                              ...content,
                              count: content.count + 1,
                            })
                          }
                        >
                          <MdAddCircle />
                        </button>
                      )}
                    </td>
                    <td className="text-right">
                      {content.price
                        ? `${(content.price * content.count).toFixed(2)}  руб.`
                        : ""}
                    </td>
                    <td className="text-center">
                      {(!content.productSetId ||
                        (content.productSetId && +content.price > 0)) && (
                        <a href="#" onClick={() => deleteOrderContent(content)}>
                          Удалить
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td className="text-left">
                    <button
                      className="btn btn-outline-primary m-1"
                      onClick={() => cacheData(values, Routes.product)}
                    >
                      <VscAdd /> товар
                    </button>
                    <button
                      className="btn btn-outline-primary m-1"
                      onClick={() => cacheData(values, Routes.productSet)}
                    >
                      <VscAdd /> пакет
                    </button>
                  </td>
                  <td colSpan={3} className="text-right">
                    {orderEditor.content.length > 0 && "Скидка"}
                  </td>
                  <td className="text-right">
                    {orderEditor.content.length > 0 &&
                      `${orderEditor.sumDiscount} руб.`}
                  </td>
                  <td></td>
                </tr>
                {orderEditor.sumDelivery > 0 && (
                  <tr className="row-summary">
                    <td></td>
                    <td></td>
                    <td colSpan={3} className="text-right">
                      Доставка
                    </td>
                    <td className="text-right">
                      {orderEditor.sumDelivery} руб.
                    </td>
                    <td></td>
                  </tr>
                )}
                {orderEditor.content.length > 0 && (
                  <tr className="row-summary">
                    <td colSpan={5} className="text-right">
                      <b>Итого</b>
                    </td>
                    <td className="text-right">{orderEditor.sumTotal} руб.</td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <hr />
          <br />
          <Form className="form" autoComplete="off">
            <div className="row">
              <div className="col-md-5 mb-3 pr-4">
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Телефон</label>
                  <div className="col-sm-6">
                    {phoneReady ? (
                      "***********"
                    ) : (
                      <input
                        name="phone"
                        type="text"
                        placeholder="Телефон"
                        disabled={phoneReady}
                        value={values.phone}
                        onChange={(e) => setFieldValue("phone", e.target.value)}
                        // onChange={handleChange}
                        className={
                          errors.phone
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        // autoComplete={"autoComplete"}
                      />
                    )}
                    {errors.phone && (
                      <div
                        className="was-validated invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.phone}
                      </div>
                    )}

                    {phoneReady && (
                      <button
                        className="btn btn-link mt-1"
                        onClick={() => {
                          setFieldValue("phone", "");
                          setPhoneReady(false);
                        }}
                      >
                        ввести свой номер
                      </button>
                    )}
                  </div>

                  <div className="col-md-4 mb-6">
                    <div className="text-right">
                      {client && (
                        <a
                          href="#"
                          onClick={() => {
                            loadClientFromPhone();
                            setFieldValue("phone", PHONE_FROM_CLIENT_ID);
                          }}
                        >
                          Из телефона
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <InputWithLabel name="lastName" type="text" label="Фамилия" />
                <InputWithLabel name="firstName" type="text" label="Имя" />
                <InputWithLabel
                  name="patronymic"
                  type="text"
                  label="Отчество"
                />
                <div className="form-group">
                  <label className="col-form-label">Комментарий к заказу</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    name="comment"
                    onChange={handleChange}
                    value={values.comment}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-7 mb-3">
                <div className="row">
                  <div className="col-md-6 mb-6">
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Доставка
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-select"
                          name="typeDelivery"
                          value={values.typeDelivery}
                          onChange={handleChange}
                          required
                        >
                          <option value={DeliveryType.post}>Белпочта</option>
                          {/* <option value={DeliveryType.eu_d}>
                            Европочта - До двери
                          </option> */}
                          <option value={DeliveryType.eu_s}>
                            Европочта - ОПС
                          </option>
                          <option value={DeliveryType.c_rb}>Курьер РБ</option>
                          <option value={DeliveryType.c_mi}>
                            Курьер Минск
                          </option>
                          <option value={DeliveryType.self}>Самовывоз</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-6">
                    <div className=" row">
                      <label className="col-sm-3 col-form-label">Дата</label>

                      <div className="col-sm-9">
                        <DatePicker
                          dateFormat="dd MMMM yyyy"
                          locale={ru}
                          minDate={addDays(
                            currentDate,
                            calculateShiftDaysDelivery(
                              values.typeDelivery,
                              currentDate
                            )
                          )}
                          selected={
                            values.dateDelivery
                              ? new Date(values.dateDelivery)
                              : null
                          }
                          placeholderText={"Дата доставки"}
                          onChange={(date) =>
                            setFieldValue("dateDelivery", date)
                          }
                          className={
                            errors.dateDelivery
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        {errors.dateDelivery && (
                          <div
                            className="was-validated invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.dateDelivery}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {values.typeDelivery === DeliveryType.post && (
                  <>
                    <div className="row">
                      <div className="col-md-6 mb-6">
                        <InputWithLabel
                          name="zipCode"
                          type="text"
                          label="Индекс"
                          maxLength={6}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-6">
                        <InputWithLabel
                          name="area"
                          type="text"
                          label="Область"
                        />
                      </div>
                      <div className="col-md-6 mb-6">
                        <InputWithLabel
                          name="region"
                          type="text"
                          label="Район"
                        />
                      </div>
                    </div>
                  </>
                )}
                {values.typeDelivery === DeliveryType.self && (
                  <div>
                    <p>
                      Самовывоз осуществляется по адресу: г. Минск, ул.
                      Лещинского 12.
                    </p>
                    Время работы самовывоза: будние дни 16.00 - 18.00
                  </div>
                )}
                {[
                  DeliveryType.post,
                  DeliveryType.c_mi,
                  DeliveryType.c_rb,
                ].includes(values.typeDelivery) && (
                  <>
                    <div className="row">
                      {values.typeDelivery !== DeliveryType.c_mi && (
                        <div className="col-md-6 mb-6">
                          <InputWithLabel
                            name="city"
                            type="text"
                            label="Город"
                          />
                        </div>
                      )}
                      <div className="col-md-6 mb-6">
                        <InputWithLabel
                          name="street"
                          type="text"
                          label="Улица"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-6">
                        <InputWithLabel name="house" type="text" label="Дом" />
                      </div>
                      <div className="col-md-6 mb-6">
                        <InputWithLabel
                          name="building"
                          type="text"
                          label="Корпус"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-6">
                        <InputWithLabel
                          name="apartment"
                          type="text"
                          label="Квартира"
                        />
                      </div>
                    </div>
                  </>
                )}
                {values.typeDelivery === DeliveryType.eu_s && (
                  <>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Пункт самовывоза
                      </label>
                      <div className="col-sm-10">
                        <select
                          name="euroPostId"
                          value={values.euroPostId || ""}
                          onChange={handleChange}
                          className={
                            errors.euroPostId
                              ? "form-select is-invalid"
                              : "form-select"
                          }
                        >
                          <option value={""}>Выберите пункт самовывоза</option>
                          {euroPostOffices.map((item) => (
                            <option
                              value={item.WarehouseId}
                              key={item.WarehouseId}
                            >
                              {item.WarehouseName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-10">
                        {values.euroPostId &&
                          euroPostOffices.find(
                            (item) => item.WarehouseId == values.euroPostId
                          )?.Info1}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr />
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              {orderEditor.orderId ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-1"
                    disabled={isSending}
                    onClick={(e) => {
                      e.preventDefault();
                      handleCancel();
                    }}
                  >
                    Отмена
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    // disabled={!!!orderEditor.content.length}
                    disabled={isSending}
                  >
                    Обновить заказ
                  </button>
                </>
              ) : (
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isSending}
                >
                  Создать заказ
                </button>
              )}
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
